
import axios from "axios";


export const Axios = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true
});

Axios.interceptors.request.use(function (request) {
  const token = localStorage.getItem("token");

  if (token) {
    request.headers.token = token;
    request.withCredentials = true;
    // document.cookie = `token=${token}; path=/`;
  }

  return request;
});

export const getCurrentUser = async () => {
  try {
    const { data } = await Axios.get("auth/get-user");
    return data?.data;
  } catch (error) {
    return null;
  }
};

