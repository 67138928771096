import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { getCurrentDate } from "app/utils/constants/constants";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";

const AddSession = ({ openView, setOpenView, sessionId, roomId }) => {
  const showAlert = ToastAlerts();
  const today = new Date().toISOString().split("T")[0];
  const [speakerList, setSpeakerList] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [room, setRoom] = useState();

  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchAllSpeakers = async () => {
      try {
        const response = await Axios.get("/speakers/dropdown-speaker");
        setSpeakerList(response?.data?.data);
      } catch (error) {
        console.log(
          "err fetching speakers => ",
          error?.response?.data?.message
        );
      }
    };
    fetchAllSpeakers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionId) {
        try {
          const response = await Axios.get(
            `/session/get-session-details?id=${sessionId}`
          );

          setRoom(response.data.data);
        } catch (error) {
          console.error("Error fetching room data:", error);
        }
      }
    };
    fetchData();
  }, [sessionId]);
  useEffect(() => {
    if (room?.session_image) {
      setImagePreview(
        `${process.env.REACT_APP_BE_IMG_URL}${room.session_image}`
      );
    }
  }, [room]);

  var initialValues = {
    session_name: room?.session_name ? room?.session_name : "",
    session_description: room?.session_description
      ? room?.session_description
      : "",
    category: room?.category ? room?.category : "",
    time: room?.time ? room?.time : "",
    date: room?.date
      ? new Date(room?.date).toISOString().split("T")[0]
      : getCurrentDate(),
    speaker: room?.speaker ? room?.speaker : [],
    session_image: room?.session_image ? room?.session_image : null,
    speaker_name: [],
  };

  const validationSchema = yup.object({
    session_image: yup
      .string("Add Session Image")
      .required("Session image is required")
      .nullable(),
    session_name: yup
      .string("Enter Session Name")
      .required("Session Name is required"),
    session_description: yup
      .string("Enter Session Description")
      .required("Session Description is required"),
    time: yup.string("Enter Session Time").required("Session Time is required"),
    date: yup.date().required("Session Date is required"),
    category: yup
      .string("Select Category")
      .required("catgeory is required")
      .nullable(),
    speaker: yup.array().min(1).required(),
  });

  const handleRoomSubmit = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("session_name", data?.session_name);
      formData.append("session_description", data?.session_description);
      formData.append("session_image", data?.session_image);
      formData.append("category", data?.category);
      formData.append("time", data?.time);
      formData.append("speaker", JSON.stringify(data?.speaker));
      formData.append("date", data?.date);
      formData.append("room_id", roomId);

      if (sessionId) {
        await Axios.patch(`/session/edit-session?id=${sessionId}`, formData);
        showAlert("success", "Room updated successfully.");
      } else {
        const updateData = {
          ...data,
          room_id: roomId,
        };
        await Axios.post("/session/add-session", formData);
        showAlert("success", "Session created successfully.");
      }

      setOpenView(false);
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const categories = [
    { name: "Conference", val: "conference" },
    { name: "Hackathon", val: "hackathon" },
    { name: "Startup", val: "startup" },
    { name: "Fireside", val: "fireside" },
  ];
  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("session_image", file);
    if (file && !sessionId) {
      setSelectedFileName(file?.name);
    }
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        {!room ? "Add Session" : "Edit Session"}
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          key={JSON.stringify(initialValues)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            validationSchema
              .validate(data, { abortEarly: false })
              .then(() => {
                handleRoomSubmit(data, setSubmitting);
              })
              .catch((validationErrors) => {
                console.error("Validation Errors:", validationErrors);
              });
          }}
        >
          {({ setFieldValue, isSubmitting, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Grid container rowSpacing={3} columnSpacing={3} mt={1}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="session_image"
                    name="session_image"
                    label="Upload Session Image*"
                    InputProps={{
                      endAdornment: (
                        <Button
                          size="small"
                          // variant="outlined"
                          component="label"
                        >
                          <FileUploadOutlined />
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(event) => {
                              // const file = event.target.files[0];
                              // setFieldValue("session_image", file);
                              handleImageChange(event, setFieldValue);
                            }}
                          />
                        </Button>
                      ),
                      readOnly: true,
                    }}
                    value={sessionId ? "" : selectedFileName}
                    // value={
                    //   typeof values?.session_image === "string"
                    //     ? values?.session_image
                    //     : values.session_image?.name || ""
                    // }
                    error={
                      touched.session_image && Boolean(errors.session_image)
                    }
                    helperText={touched.session_image && errors.session_image}
                  />
                  {imagePreview && (
                    <div className="flex flex-col items-start mt-5">
                      <p> Uploaded Image</p>
                      <img
                        // src={`localhost:3001/${state?.floor_plan_image}`}
                        src={imagePreview}
                        alt="Session Preview"
                        style={{
                          marginTop: "10px",
                          maxWidth: "8rem",
                          height: "auto",
                        }}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <JumboTextField
                    fullWidth
                    required
                    id="session_name"
                    name="session_name"
                    label="Session Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <JumboTextField
                    required
                    fullWidth
                    id="session_description"
                    name="session_description"
                    label="Session Description"
                    multiline
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={errors.speaker && touched.speaker}
                  >
                    <Autocomplete
                      fullWidth
                      size="small"
                      multiple
                      disablePortal
                      // value={values?.speaker_name || []}
                      value={
                        speakerList?.filter((speaker) =>
                          values?.speaker?.includes(speaker._id)
                        ) || [] // Matching selected speakers by _id
                      }
                      getOptionLabel={(option) => {
                        if (
                          option instanceof Object &&
                          !Array.isArray(option)
                        ) {
                          return option?.speaker_name;
                        } else {
                          return option;
                        }
                      }}
                      options={speakerList}
                      name="speaker"
                      onChange={(event, val) => {
                        // setFieldValue("speaker", val?._id);
                        setFieldValue(
                          "speaker_name",
                          val.map((opt) => opt?.speaker_name)
                        );
                        setFieldValue(
                          "speaker",
                          val.map((option) => option?._id)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={errors.speaker && touched.speaker}
                          {...params}
                          label="Speaker"
                        />
                      )}
                    />
                    {errors && errors.speaker && touched.speaker && (
                      <FormHelperText>{errors.speaker}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={errors.category && touched.category}
                  >
                    <Autocomplete
                      fullWidth
                      size="small"
                      disablePortal
                      value={values?.category}
                      getOptionLabel={(option) => {
                        if (
                          option instanceof Object &&
                          !Array.isArray(option)
                        ) {
                          return option?.name;
                        } else {
                          return option;
                        }
                      }}
                      options={categories}
                      name="category"
                      onChange={(event, val) => {
                        setFieldValue("category", val?.name);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={errors.category && touched.category}
                          {...params}
                          label="Category"
                        />
                      )}
                    />
                    {errors && errors.category && touched.category && (
                      <FormHelperText>{errors.category}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <JumboTextField
                    required
                    fullWidth
                    id="date"
                    name="date"
                    type="date"
                    label="Date"
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <JumboTextField
                    required
                    fullWidth
                    id="time"
                    name="time"
                    label="Time"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ justifyContent: "flex-end" }}
                columnGap={3}
                mt={5}
              >
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item textAlign="left">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenView(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddSession;
