import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddGiveAwayItems() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const showAlert = ToastAlerts();
  const initialValues = {
    item_name: state?.item_name ? state?.item_name : "",
    event_name: state?.event_name ? state?.event_name : "",
    total_quantity: state?.total_quantity ? state?.total_quantity : "",
    status: state?.status ?? true,
  };
  const validationSchema = yup.object({
    item_name: yup
      .string("Item Name is required")
      .required("Item name is required"),
    event_name: yup
      .string("Enter Event Name ")
      .required("Event Name is required"),
    total_quantity: yup
      .string("Enter total_quantity")
      .required("total quantity is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/giveaway/edit") {
        response = await Axios.post(`/giveaway/edit/${state?._id}`, values);
      } else {
        response = await Axios.post("/giveaway/add", values);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/giveaway");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/giveaway/add"
          ? "Add Delegate Kit"
          : "Edit Delegate Kit"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="item_name"
                      name="item_name"
                      label="Item Name*"
                      value={values?.item_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="event_name"
                      name="event_name"
                      label="Event Name*"
                      value={values?.event_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="total_quantity"
                      name="total_quantity"
                      label="Total Quantity*"
                      value={values?.total_quantity}
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={6} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {pathname === "/giveaway/add" ? "Save" : "Update"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/giveaway");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddGiveAwayItems;
