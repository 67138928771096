import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { LoadingButton } from "@mui/lab";
import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,

    Switch,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { getCurrentDate } from "app/utils/constants/constants";
import { Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddStall() {
    const navigate = useNavigate();
    const { state, pathname } = useLocation();

    const showAlert = ToastAlerts();
    const initialValues = {
        stall_no: state?.stall_no ? state?.stall_no : "",
        remarks: state?.remarks ? state?.remarks : "",
        stall_name: state?.stall_name ? state?.stall_name : "",

        status: state?.status ?? true,
    };
    const validationSchema = yup.object({
        stall_no: yup.string("Stall Number is required").required("Required"),
        stall_name: yup.string("Stall Name is required").required("Required"),
        remarks: yup
            .string("Enter live event Link")
    });

    const handleSubmit = async (values, setSubmitting) => {
        setSubmitting(true);

        try {
            // const response = await Axios.post("/banner/add-banner", formData)
            let response;
            if (pathname === "/stall/edit") {
                response = await Axios.post(`/stall/edit/${state?._id}`, values);
            } else {
                response = await Axios.post("/stall/add", values);
            }
            if (response.data?.success) {
                showAlert("success", response?.data?.message);
                setSubmitting(false);
                navigate("/stall");
            }
        } catch (error) {
            showAlert("error", error?.response?.data?.message);
            setSubmitting(false);
        }
    };
    return (
        <React.Fragment>
            <Typography variant="h1" mb={3}>
                {pathname === "/stall/add" ? "Add Stall" : "Edit Stall"}
            </Typography>
            <Card>
                <CardContent>
                    <Formik
                        validateOnChange={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            validationSchema
                                .validate(data, { abortEarly: false })
                                .then(() => {
                                    handleSubmit(data, setSubmitting);
                                })
                                .catch((validationErrors) => {
                                    console.error("Validation Errors:", validationErrors);
                                });
                        }}
                    >
                        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
                            <Form noValidate autoComplete="off">
                                <Grid container rowSpacing={3} columnSpacing={3}>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            fullWidth
                                            id="stall_no"
                                            name="stall_no"
                                            label="Stall Number*"
                                            value={values?.stall_no}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            fullWidth
                                            id="stall_name"
                                            name="stall_name"
                                            label="Stall Name*"
                                            value={values?.stall_name}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            fullWidth
                                            id="remarks"
                                            name="remarks"
                                            label="Remarks"
                                            value={values?.remarks}
                                        />
                                    </Grid>



                                    <Grid item xs={12} alignContent="center">
                                        <FormControlLabel
                                            style={{ padding: "0px", margin: "0px", height: "100%" }}
                                            control={
                                                <Switch
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            "status",
                                                            values?.status ? false : true
                                                        );
                                                    }}
                                                    defaultChecked={values?.status ? true : false}
                                                    color="primary"
                                                />
                                            }
                                            label="Status"
                                            name="status"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container columnSpacing={3} mt={5}>
                                    <Grid item xs={6} textAlign="right">
                                        <LoadingButton
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                            loading={isSubmitting}
                                        >
                                            {pathname === "/stall/add" ? "Save" : "Update"}
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={6} textAlign="left">
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "Are you sure you want to cancel?",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                }).then((result) => {
                                                    if (result?.isConfirmed) {
                                                        navigate("/stall");
                                                    }
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

export default AddStall;
