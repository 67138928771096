import { LIST_STALL_SUCCESS } from "app/redux/actions/Stall/constants";

const initialState = {
    stallList: [],
    loading: false,
    error: null,
    successMessage: null,
    totalPage: null,
};

const reducerFuction = (state = initialState, action) => {
    switch (action.type) {
        case LIST_STALL_SUCCESS:
            return {
                ...state,
                stallList: action.payload?.data?.stallsList || [],
                loading: false,
                error: null,
                successMessage: action.payload?.message,
                totalPage: action.payload?.data?.totalPage,
            };

        default:
            return state;
    }
};

export default reducerFuction;
