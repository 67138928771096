import Page from "@jumbo/shared/Page";
import AddGuest from "app/pages/Guest/AddGuest";
import EditGuest from "app/pages/Guest/EditGuest";

import GuestList from "app/pages/Guest/GuestList";
import { GuestMiddleware } from "../Middleware/eventManagement/event.management.middleware";

const routesName = "/guest";
export const guestRoute = [
  {
    middleware: [
      {
        element: GuestMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: `${routesName}`,
        element: <Page component={GuestList} layout={"vertical-default"} />,
        // permission: "view",
      },
      {
        path: `${routesName}/add`,
        element: <Page component={AddGuest} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/edit/:id`,
        element: <Page component={EditGuest} layout={"vertical-default"} />,
        // permission: "edit",
      },
    ],
  },
];

// export const guestRoute = routes;
