import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

import { Axios } from "app/services/config";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

// const data = [
//   { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
//   { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
//   { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
//   { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
//   { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
//   { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
//   { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
// ];
const CityWiseChart = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async function () {
      try {
        const { data } = await Axios.get("/metrics/city");
        let res = data?.data;
        setData(
          res?.map((e) => ({
            city: e?.city,
            count: e?.count,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <JumboCardQuick
      title={"Total Registration - City Wise"}
      wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
    >
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="city" />
          <YAxis dataKey="count" />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            cursor={false}
          />
          <Legend />
          {/* <Line type="monotone" dataKey="profession" stroke={"#1e88e5"} activeDot={{ r: 8 }} /> */}
          <Line type="monotone" dataKey="count" stroke={"#e91e63"} />
        </LineChart>
      </ResponsiveContainer>
    </JumboCardQuick>
  );
};

export default CityWiseChart;
