import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useSelector } from "react-redux";
import ToastAlerts from "app/components/Toast";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";
import { fetchRoadShowList } from "app/redux/actions/Roadshow";
import { fetchOtherLinksList } from "app/redux/actions/OtherLinks";
import { Axios } from "app/services/config";
export default function ListAllLinks() {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [query, setQuery] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { linksList, totalPage, error, loading } = useSelector(
    (state) => state.otherLinksReducer
  );
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  const columns = [
    {
      field: "privacy_policy_link",
      headerName: "Privacy Policy",
      sortable: true,
    },
    {
      field: "terms_and_conditions_link",
      headerName: "Terms and Conditions",
      sortable: true,
      // isImage: true
    },
    {
      field: "instagram_link",
      headerName: "Instagram",
      sortable: true,
    },
    {
      field: "linkedin_link",
      headerName: "LinkedIn",
      sortable: true,
    },
    {
      field: "youtube_link",
      headerName: "Youtube",
      sortable: true,
    },
    {
      field: "twitter_link",
      headerName: "Twitter",
      sortable: true,
    },
    {
      field: "facebook_link",
      headerName: "Facebook",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      sortable: true,
      isDate: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      sortable: true,
      isDate: true,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      render: (value, elm) =>
        value ? (
          <Button size="small" variant="outlined" color="success">
            Active
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error">
            Inactive
          </Button>
        ),
      onClick: async (elm) => {
        try {
          let status = elm.status;
          const newData = { ...elm, status: !status };
          const result = await Swal.fire({
            title: `Change  status to ${status ? "inactive" : "active"} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (result.isConfirmed) {
            await Axios.post(`/others/edit/${elm._id}`, newData);
            showAlert("success", "Status updated successfully.");
            navigate("/other-links");
            dispatch(fetchOtherLinksList(query));
          }
        } catch (error) {
          console.error("Error updating guest:", error);
          showAlert("error", "Failed to Update Status.");
        }
      },
    },
  ];
  const actions = [
    ...(permissions?.links?.edit
      ? [
          {
            label: "Edit",
            color: "secondary",
            onClick: (row) => navigate(`/other-links/edit`, { state: row }),
            icon: <ModeEditOutlinedIcon />,
          },
        ]
      : []),
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    // GetAttendedCount();
    dispatch(fetchOtherLinksList(query));
  }, [query]);

  return (
    <>
      {(loading || loader || isLoading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Typography variant="h1">All Links </Typography>

          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "35px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Div>

            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              {/* <Button
                                size="small"
                                disabled={guestList?.length === 0}
                                variant="contained"
                                sx={{ p: 1, pl: 4, pr: 4 }}
                                onClick={async () => {
                                    GuestExportCsv();
                                }}
                            >
                                export csv
                            </Button> */}
              {/* <Button
                size="small"
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={() => navigate("/other-links/add")}
              >
                Add Links
              </Button> */}
            </Div>
          </Div>
        </Div>

        <Div>
          <CustomTable
            data={linksList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPage}
          />
        </Div>
        {/* {openView && guestDetails && (
                    <ViewGuest
                        openView={openView}
                        setOpenView={setOpenView}
                        data={guestDetails}
                    />
                )} */}
      </Div>
    </>
  );
}
