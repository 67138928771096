import { LIST_AGENDA_SUCCESS } from "app/redux/actions/Agenda/constants";

const initialState = {
  agendaList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPage: null,
};

const reducerFuction = (state = initialState, action) => {
  switch (action.type) {
    case LIST_AGENDA_SUCCESS:
      return {
        ...state,
        agendaList: action.payload?.data?.agendaList || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPage: action.payload?.data?.totalPage,
      };

    default:
      return state;
  }
};

export default reducerFuction;
