import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { CheckBox, FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis/apis";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddSurvey() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [roomList, setRoomList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);

  const showAlert = ToastAlerts();
  const initialValues = {
    survey_name: state?.survey_name ? state?.survey_name : "",
    event_name: state?.event_name ? state?.event_name : "",
    room_name: state?.room_name ? state?.room_name : "",
    room_id: state?.room_id ? state?.room_id : "",
    session_name: state?.session_name ? state?.session_name : "",
    session_id: state?.session_id ? state?.session_id : "",
    questions: state?.questions ? state?.questions : [],
    question_name: state?.question_name ? state?.question_name : [],
    status: state?.status ?? true,
    is_global: state?.is_global || false,
    type: state?.type || "",
  };
  const validationSchema = yup.object({
    survey_name: yup
      .string("Survey Name is required")
      .required("Survey name is required"),
    event_name: yup
      .string("Enter  event_name")
      .required("event name is required"),
    // room_id: yup.string("Select Room").required("Room is required"),
    room_id: yup.string("Select Room").when("is_global", {
      is: false,
      then: yup.string().required("Room is required"),
      otherwise: yup.string("Select Room"),
    }),
    is_global: yup.boolean().required("Action required"),
    // session_id: yup.string("Select Session").required("Session  is required"),
    session_id: yup.string("Select Session").when("is_global", {
      is: false,
      then: yup.string().required("Session is required"),
      otherwise: yup.string(),
    }),
    type: yup.string("Select Screen Type").when("is_global", {
      is: true,
      then: yup.string().required("Survey Screen is required").nullable(),
      otherwise: yup.string(),
    }),
    questions: yup
      .array()
      .of(yup.string().required("Option cannot be empty"))
      .min(1, "At least one question is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/survey/edit") {
        response = await Axios.post(
          `/feedback/edit-survey/${state?._id}`,
          values
        );
      } else {
        response = await Axios.post("/feedback/add-survey", values);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/survey");
      } else {
        showAlert("error", response?.data?.message);
        setSubmitting(false);
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fecthData = async () => {
      try {
        const [roomRes, questionsRes] = await Promise.all([
          await Axios.get(`${AllApis.dropdownList.room_list}`),
          await Axios.get(`${AllApis.dropdownList.all_questions}`),
        ]);
        setRoomList(roomRes?.data?.data);
        setQuestionsList(questionsRes?.data?.data);
      } catch (error) {
        console.log(
          "err fetching room and questions => ",
          error?.response?.data?.message
        );
      }
    };
    fecthData();
  }, []);
  useEffect(() => {
    if (initialValues?.room_id) {
      fetchAllSessions(initialValues?.room_id);
    }
  }, [initialValues.room_id]);

  const fetchAllSessions = async (id) => {
    try {
      const res = await Axios.get(`/session/list-sessions-dropdown/${id}`);
      setSessionList(res?.data?.data);
    } catch (error) {
      console.log(
        "err fetching all sessions => ",
        error?.response?.data?.message
      );
    }
  };
  const handleGlobalSwitch = (setFieldValue) => {
    setFieldValue("room_name", "");
    setFieldValue("room_id", "");
    setFieldValue("session_name", "");
    setFieldValue("session_id", "");
    setFieldValue("type", "");
  };
  const surveyScreens = ["Mobile Feedback", "Global Survey"];
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/survey/add" ? "Add Survey" : "Edit Survey"}
      </Typography>
      {/* <Card> */}
      {/* <CardContent> */}
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleSubmit(data, setSubmitting);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={values?.is_global}
                      onChange={(e) => {
                        setFieldValue("is_global", e.target.checked);
                        handleGlobalSwitch(setFieldValue);
                      }}
                      color="primary"
                    />
                  }
                  label="Is Global Survey"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="survey_name"
                  name="survey_name"
                  label="Survey Name*"
                  value={values?.survey_name}
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="event_name"
                  name="event_name"
                  label="Event Name*"
                  value={values?.event_name}
                />
              </Grid>
              {!values?.is_global && (
                <>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.room_name && touched.room_name}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        value={
                          roomList?.find(
                            (room) => room?._id === values?.room_id
                          ) || ""
                        }
                        getOptionLabel={(option) => {
                          if (
                            option instanceof Object &&
                            !Array.isArray(option)
                          ) {
                            return option?.room_no;
                          } else {
                            return option;
                          }
                        }}
                        options={roomList}
                        name="room_id"
                        onChange={(event, val) => {
                          // setFieldValue("speaker", val?._id);
                          setFieldValue("room_name", val?.room_no);
                          setFieldValue("room_id", val?._id);
                          fetchAllSessions(val?._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.room_name && touched.room_name}
                            {...params}
                            label="Room"
                          />
                        )}
                      />
                      {errors && errors.room_name && touched.room_name && (
                        <FormHelperText>{errors.room_name}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.session_name && touched.session_name}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        value={
                          sessionList?.find(
                            (item) => item?._id === values?.session_id
                          ) || ""
                        }
                        getOptionLabel={(option) => {
                          if (
                            option instanceof Object &&
                            !Array.isArray(option)
                          ) {
                            return option?.session_name;
                          } else {
                            return option;
                          }
                        }}
                        options={sessionList}
                        name="session_id"
                        onChange={(event, val) => {
                          // setFieldValue("speaker", val?._id);
                          setFieldValue("session_name", val?.session_name);
                          setFieldValue("session_id", val?._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.session_name && touched.session_name}
                            {...params}
                            label="Session"
                          />
                        )}
                      />
                      {errors &&
                        errors.session_name &&
                        touched.session_name && (
                          <FormHelperText>{errors.session_name}</FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </>
              )}
              {values?.is_global && (
                <Grid item xs={6}>
                  <FormControl fullWidth error={errors.type && touched.type}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      disablePortal
                      value={
                        surveyScreens?.find((i) => i === values?.type) || ""
                      }
                      getOptionLabel={(option) => {
                        if (
                          option instanceof Object &&
                          !Array.isArray(option)
                        ) {
                          return option;
                        } else {
                          return option;
                        }
                      }}
                      options={surveyScreens}
                      name="type"
                      onChange={(event, val) => {
                        // setFieldValue("speaker", val?._id);
                        setFieldValue("type", val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={errors.type && touched.type}
                          {...params}
                          label="Survey Screen"
                        />
                      )}
                    />
                    {errors && errors.type && touched.type && (
                      <FormHelperText>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={errors.question_name && touched.question_name}
                >
                  <Autocomplete
                    fullWidth
                    size="small"
                    multiple
                    disablePortal
                    // value={values?.speaker_name || []}
                    value={
                      questionsList?.filter((q) =>
                        values?.questions?.includes(q?._id)
                      ) || [] // Matching selected speakers by _id
                    }
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.question;
                      } else {
                        return option;
                      }
                    }}
                    options={questionsList}
                    name="questions"
                    onChange={(event, val) => {
                      setFieldValue(
                        "questions",
                        val?.map((i) => i?._id)
                      );
                      setFieldValue(
                        "question_name",
                        val?.map((i) => i?.question)
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={errors.question_name && touched.question_name}
                        {...params}
                        label="Questions"
                      />
                    )}
                  />
                  {errors && errors.question_name && touched.question_name && (
                    <FormHelperText>{errors.question_name}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} alignContent="center">
                <FormControlLabel
                  style={{ padding: "0px", margin: "0px", height: "100%" }}
                  control={
                    <Switch
                      onChange={(e) => {
                        setFieldValue("status", values?.status ? false : true);
                      }}
                      defaultChecked={values?.status ? true : false}
                      color="primary"
                    />
                  }
                  label="Status"
                  name="status"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt={5}>
              <Grid item xs={6} textAlign="right">
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  {pathname === "/survey/add" ? "Save" : "Update"}
                </LoadingButton>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result?.isConfirmed) {
                        navigate("/survey");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {/* </CardContent>
      </Card> */}
    </React.Fragment>
  );
}

export default AddSurvey;
