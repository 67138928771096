import {
  LOGS_ADDED,
  LOGS_DELETE,
  LOGS_EDIT,
  LOGS_LIST,
  LOGS_ERROR,
  GLOBAL_LOGS_LIST,

  LOGS_REQUEST
} from "../../actions/Logs/logsConstant";

const initialState = {
  logsList: [],
  globalMemberList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPages: null
};

const reducerFunc = (state = initialState, action) => {
  switch (action.type) {
    case LOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGS_LIST:
      return {
        ...state,
        logsList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };
    case GLOBAL_LOGS_LIST:
      return {
        ...state,
        globalMemberList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
      };

    case LOGS_ADDED:
    case LOGS_EDIT:
    case LOGS_DELETE:
      return {
        ...state,
        loading: false,
        successMessage: action.payload?.message,
        error: null
      };

    case LOGS_ERROR:
      return {
        ...state,
        loading: false,
        successMessage: null,
        error: action.payload
      };

    default:
      return state;
  }
};

export default reducerFunc;