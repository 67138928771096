import Page from "@jumbo/shared/Page";
import AddAboutUs from "app/pages/EventManagement/AboutUs/Add";
import ListAboutUs from "app/pages/EventManagement/AboutUs/List";
import AddAgenda from "app/pages/EventManagement/Agenda/Add";
import ListAgenda from "app/pages/EventManagement/Agenda/List";
import AddFloorPlan from "app/pages/EventManagement/FloorPlan/Add";
import ListFloorPlan from "app/pages/EventManagement/FloorPlan/List";
import AddHomeBanner from "app/pages/EventManagement/HomeBanner/Add";
import ListHomeBanner from "app/pages/EventManagement/HomeBanner/List";
import AddLiveEvents from "app/pages/EventManagement/LiveEvent/Add";
import ListAllLiveEvents from "app/pages/EventManagement/LiveEvent/List";
import AddPartners from "app/pages/EventManagement/Partners/Add";
import ListPartners from "app/pages/EventManagement/Partners/List";
import AddRoadhows from "app/pages/EventManagement/RoadShows/Add";
// import AddRoadshows from "app/pages/EventManagement/RoadShows/Add";
import ListRoadShows from "app/pages/EventManagement/RoadShows/List";
import AddSpeakers from "app/pages/EventManagement/Speakers/Add";
import ListSpeakers from "app/pages/EventManagement/Speakers/List";
import AddSponsors from "app/pages/EventManagement/Sponsors/Add";
import ListSponsors from "app/pages/EventManagement/Sponsors/List";
import {
  AboutUsMiddleware,
  AgendaMiddleware,
  BannerMiddleware,
  FloorPlanMiddleware,
  LiveEventMiddleware,
  PartnerMiddleware,
  RoadshowMiddleware,
  SpeakersMiddleware,
  SponsorMiddleware,
  StallMiddleware,
} from "../Middleware/eventManagement/event.management.middleware";
import ViewProgamme from "app/pages/EventManagement/Agenda/List/ViewProgramee";
import ListStalls from "app/pages/EventManagement/Stall/List";
import AddStall from "app/pages/EventManagement/Stall/Add";

export const homeBannerRoutes = [
  {
    middleware: [{ element: BannerMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/banner/add",
        element: <Page component={AddHomeBanner} layout={"vertical-default"} />,
      },
      {
        path: "/banner/edit",
        element: <Page component={AddHomeBanner} layout={"vertical-default"} />,
      },
      {
        path: "/banner",
        element: (
          <Page component={ListHomeBanner} layout={"vertical-default"} />
        ),
      },
    ],
  },
];

export const sponsorRoutes = [
  {
    middleware: [{ element: SponsorMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/sponsors",
        element: <Page component={ListSponsors} layout={"vertical-default"} />,
      },
      {
        path: "/sponsors/add",
        element: <Page component={AddSponsors} layout={"vertical-default"} />,
      },
      {
        path: "/sponsors/edit",
        element: <Page component={AddSponsors} layout={"vertical-default"} />,
      },
    ],
  },
];

export const partnerRoutes = [
  {
    middleware: [{ element: PartnerMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/partners",
        element: <Page component={ListPartners} layout={"vertical-default"} />,
      },
      {
        path: "/partners/add",
        element: <Page component={AddPartners} layout={"vertical-default"} />,
      },
      {
        path: "/partners/edit",
        element: <Page component={AddPartners} layout={"vertical-default"} />,
      },
    ],
  },
];

export const speakerRoutes = [
  {
    middleware: [{ element: SpeakersMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/speakers",
        element: <Page component={ListSpeakers} layout={"vertical-default"} />,
      },
      {
        path: "/speakers/add",
        element: <Page component={AddSpeakers} layout={"vertical-default"} />,
      },
      {
        path: "/speakers/edit",
        element: <Page component={AddSpeakers} layout={"vertical-default"} />,
      },
    ],
  },
];

export const roadshowRoutes = [
  {
    middleware: [{ element: RoadshowMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/roadshow",
        element: <Page component={ListRoadShows} layout={"vertical-default"} />,
      },
      {
        path: "/roadshow/add",
        element: <Page component={AddRoadhows} layout={"vertical-default"} />,
      },
      {
        path: "/roadshow/edit",
        element: <Page component={AddRoadhows} layout={"vertical-default"} />,
      },
    ],
  },
];

export const floorPlanRoutes = [
  {
    middleware: [{ element: FloorPlanMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/floor-plan",
        element: <Page component={ListFloorPlan} layout={"vertical-default"} />,
      },
      {
        path: "/floor-plan/add",
        element: <Page component={AddFloorPlan} layout={"vertical-default"} />,
      },
      {
        path: "/floor-plan/edit",
        element: <Page component={AddFloorPlan} layout={"vertical-default"} />,
      },
    ],
  },
];
export const agendaRoutes = [
  {
    middleware: [{ element: AgendaMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/agenda",
        element: <Page component={ListAgenda} layout={"vertical-default"} />,
      },
      {
        path: "/agenda/add",
        element: <Page component={AddAgenda} layout={"vertical-default"} />,
      },
      {
        path: "/agenda/edit",
        element: <Page component={AddAgenda} layout={"vertical-default"} />,
      },
      {
        path: "/agenda/view-agenda",
        element: <Page component={ViewProgamme} layout={"vertical-default"} />,
      },
    ],
  },
];

export const aboutUsRoutes = [
  {
    middleware: [{ element: AboutUsMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/about-us",
        element: <Page component={ListAboutUs} layout={"vertical-default"} />,
      },
      {
        path: "/about-us/add",
        element: <Page component={AddAboutUs} layout={"vertical-default"} />,
      },
      {
        path: "/about-us/edit",
        element: <Page component={AddAboutUs} layout={"vertical-default"} />,
      },
    ],
  },
];
export const liveEventRoutes = [
  {
    middleware: [{ element: LiveEventMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/live-event",
        element: (
          <Page component={ListAllLiveEvents} layout={"vertical-default"} />
        ),
      },
      {
        path: "/live-event/add",
        element: <Page component={AddLiveEvents} layout={"vertical-default"} />,
      },
      {
        path: "/live-event/edit",
        element: <Page component={AddLiveEvents} layout={"vertical-default"} />,
      },
    ],
  },
];
export const stallRoutes = [
  {
    middleware: [{ element: StallMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: "/stall",
        element: (
          <Page component={ListStalls} layout={"vertical-default"} />
        ),
      },
      {
        path: "/stall/add",
        element: <Page component={AddStall} layout={"vertical-default"} />,
      },
      {
        path: "/stall/edit",
        element: <Page component={AddStall} layout={"vertical-default"} />,
      },
    ],
  },
];
export const eventManagementRoutes = [
  //Home-banner routes
  // {
  //     routes: [
  //         {
  //             path: "/banner/add",
  //             element: <Page component={AddHomeBanner} layout={"vertical-default"} />,
  //         },
  //         {
  //             path: "/banner/edit",
  //             element: <Page component={""} layout={"vertical-default"} />,
  //         },
  //         {
  //             path: "/banner",
  //             element: <Page component={ListHomeBanner} layout={"vertical-default"} />,
  //         },
  //     ],
  // },

  //sponsors routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [],
  },

  //partners routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [
      {
        path: "/partners",
        element: <Page component={ListPartners} layout={"vertical-default"} />,
      },
      {
        path: "/partners/add",
        element: <Page component={AddPartners} layout={"vertical-default"} />,
      },
      {
        path: "/partners/edit",
        element: <Page component={""} layout={"vertical-default"} />,
      },
    ],
  },

  //speakers routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [
      {
        path: "/speakers",
        element: <Page component={ListSpeakers} layout={"vertical-default"} />,
      },
      {
        path: "/speakers/add",
        element: <Page component={AddSpeakers} layout={"vertical-default"} />,
      },
      {
        path: "/speakers/edit",
        element: <Page component={""} layout={"vertical-default"} />,
      },
    ],
  },

  //roadshow routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [
      {
        path: "/roadshow",
        element: <Page component={ListRoadShows} layout={"vertical-default"} />,
      },
      {
        path: "/roadshow/add",
        element: <Page component={AddRoadhows} layout={"vertical-default"} />,
      },
      {
        path: "/roadshow/edit",
        element: <Page component={""} layout={"vertical-default"} />,
      },
    ],
  },

  //floor-plan routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [
      {
        path: "/floor-plan",
        element: <Page component={ListFloorPlan} layout={"vertical-default"} />,
      },
      {
        path: "/floor-plan/add",
        element: <Page component={AddFloorPlan} layout={"vertical-default"} />,
      },
      {
        path: "/floor-plan/edit",
        element: <Page component={""} layout={"vertical-default"} />,
      },
    ],
  },

  //about-us routes
  {
    middleware: [
      // {
      //     element: AccessMiddleware,
      //     fallbackPath: ["/dashboard", "item_category_master", "view"],
      // },
    ],
    routes: [
      {
        path: "/about-us",
        element: <Page component={ListAboutUs} layout={"vertical-default"} />,
      },
      {
        path: "/about-us/add",
        element: <Page component={AddAboutUs} layout={"vertical-default"} />,
      },
      {
        path: "/about-us/edit",
        element: <Page component={""} layout={"vertical-default"} />,
      },
    ],
  },
];
