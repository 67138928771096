import { Axios } from "app/services/config";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  TablePagination,
  Typography,
  Autocomplete,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import { MoreHoriz, Search } from "@mui/icons-material";
import Documents1 from "app/components/Documents1";
import ToastAlerts from "app/components/Toast";
import { useNavigate } from "react-router-dom";
function List() {
  const [events, setEvents] = useState([]);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");
  const [query, setQuery] = useState("");
  const [gifts, setGifts] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [selectedEvents, setSelectedEvents] = useState({});
  const [sortOrder, setSortOrder] = useState("asec");
  const [sortField, setSortField] = useState("");
  const showAlerts = ToastAlerts();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    currentId: null,
  });

  const handleClose = () => {
    setMenuState({ anchorEl: null, currentId: null });
  };

  useEffect(() => {
    const fetchAllEvents = async () => {
      try {
        const [eventRes, GiftRes] = await Promise.all([
          await Axios.get("room/events"),
          await Axios.get("gift/all-gifts", {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              query: debouncedQuery,
              sortOrder: sortOrder,
              sortField: sortField,
            },
          }),
        ]);

        setEvents(eventRes.data?.data?.allRooms);
        setGifts(GiftRes.data?.data?.allGifts);
        setCount(GiftRes.data?.data?.totalCount);
      } catch (error) {
        console.log("err fetching events => ", error.message);
      }
    };
    fetchAllEvents();
  }, [debouncedQuery, page, sortOrder, sortField]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const handleEventMapping = async (giftId) => {
    const allData = {
      giftId,
      eventId: selectedEvents[giftId] || [],
    };

    try {
      const res = await Axios.post("gift/add-events", allData);
      if (res.data.statusCode === 200) {
        showAlerts("success", res.data.message);
      } else {
        showAlerts("error", res.data.message);
      }
    } catch (error) {
      showAlerts("error", error.message);
    }
  };

  const handleEventChange = (giftId, newSelectedEvents) => {
    const eventIds = newSelectedEvents.map((event) => event._id);
    setSelectedEvents((prev) => ({
      ...prev,
      [giftId]: eventIds,
    }));
  };
  const handleMenuClick = (event, id) => {
    setMenuState({ anchorEl: event.currentTarget, currentId: id });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleSortRequest = (field) => {
    sortField === field
      ? setSortOrder(sortOrder === "asec" ? "desc" : "asec")
      : setSortOrder("asc");
    setSortField(field);
  };

  return (
    <>
      <p className="mb-5 mt-6">
        <Typography variant="h1">All Gifts </Typography>
      </p>
      <div className="flex flex-col items-center gap-8">
        <div className="self-start">
          <Documents1 field={"Total Gift Count"} data={count} />
        </div>
        <div className="flex md:flex-row flex-col items-center  w-full  gap-5">
          <TextField
            size="small"
            label="Search Gift"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>Sr. No</TableCell>
                  <TableCell style={{ width: "30%" }}>
                    <TableSortLabel
                      active={sortField === "giftName"}
                      direction={sortOrder === "asec" ? "asec" : "desc"}
                      onClick={() => handleSortRequest("giftName")}
                    >
                      Gift Name{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: "40%" }}>Event Name</TableCell>
                  <TableCell style={{ width: "20%" }}>Add Events</TableCell>
                  <TableCell style={{ width: "30%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gifts?.length > 0 ? (
                  gifts.map((log, index) => (
                    <>
                      <TableRow key={log._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{log.giftName}</TableCell>
                        <TableCell>
                          <Autocomplete
                            multiple
                            size="small"
                            options={events}
                            getOptionLabel={(option) => option.room_no}
                            value={
                              events.filter((event) =>
                                selectedEvents[log._id]?.includes(event._id)
                              ) || []
                            }
                            onChange={(event, newValue) => {
                              handleEventChange(log._id, newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Events"
                                placeholder="Events"
                                sx={{ minWidth: 250 }}
                              />
                            )}
                            className="w-[30rem]"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleEventMapping(log._id)}
                            variant="contained"
                            size="small"
                            sx={{ padding: 1 }}
                          >
                            Add
                          </Button>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => handleMenuClick(e, log._id)}
                          >
                            <MoreHoriz />
                          </IconButton>
                          <Menu
                            open={Boolean(menuState.anchorEl)}
                            anchorEl={menuState.anchorEl}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                navigate(
                                  `/gift-event-mapping/${menuState.currentId}`
                                );
                              }}
                            >
                              View Mapped Events
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data Found...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {count > 0 && (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              // onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={""}
            />
          )}
        </Paper>
      </div>
    </>
  );
}

export default List;
