import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { Axios } from "app/services/config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ToastAlerts from "../Toast";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

function QuestionsModal({ open, handleClose, eventId }) {
  const [allQuestions, setAllQuestions] = useState([]);
  const [questionId, setQuestionId] = useState([]);
  const [questionByEvent, setQuestionByEvent] = useState([]);
  const navigate = useNavigate();

  const showAlerts = ToastAlerts();

  useEffect(() => {
    if (open && eventId) {
      const fetchData = async () => {
        // try {
        //     const res = await Axios.get('feedback/fetch-all-questions');
        //     console.log("all questions => ", res.data)
        //     setAllQuestions(res.data.data)
        // } catch (error) {
        //     console.log("err fetching all questions => ", error.message)
        // }
        const [allQuestionsRes, questionsbyEventRes] = await Promise.all([
          Axios.get("feedback/fetch-all-questions"),
          Axios.get(`feedback/questions-by-event/${eventId}`),
        ]);
        setAllQuestions(allQuestionsRes.data?.data);
        // Extract IDs of mapped questions from the nested structure
        const mappedQuestionsIds =
          questionsbyEventRes.data.data.allQuestions.map(
            (q) => q?.allQuestions?._id
          );
        setQuestionId(mappedQuestionsIds);

        setQuestionId(mappedQuestionsIds);
      };
      fetchData();
    }
  }, [eventId, open]);

  const handleSubmit = async () => {
    try {
      const newData = {
        eventId: eventId,
        questionId: questionId,
      };

      const res = await Axios.post("feedback/map-question", newData);
      showAlerts("success", res.data.message);
      if (res.data.statusCode === 200) {
        handleClose();
      }
    } catch (error) {
      showAlerts("error", error.message);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lg:w-2/3 w-[30rem]">
          <div className="flex flex-row items-center justify-between pb-3">
            <span className="font-semibold text-lg">Select Questions</span>
            <span
              onClick={handleClose}
              className="cursor-pointer font-bold hover:scale-105 transition-all float-right -mt-4"
            >
              <Close />
            </span>
          </div>

          <div className="flex flex-col items-center gap-8">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}> Sr.No</TableCell>
                      <TableCell style={{ width: "10%" }}> Select</TableCell>
                      <TableCell style={{ width: "40%" }}> Question</TableCell>
                      <TableCell style={{ width: "20%" }}> Types</TableCell>
                      <TableCell style={{ width: "20%" }}> Opt1</TableCell>
                      <TableCell style={{ width: "20%" }}> Opt2</TableCell>
                      <TableCell style={{ width: "20%" }}> Opt3</TableCell>
                      <TableCell style={{ width: "20%" }}> Opt4</TableCell>
                    </TableRow>
                  </TableHead>

                  {allQuestions.length > 0 ? (
                    <TableBody>
                      {allQuestions.map((row, index) => (
                        <TableRow key={row._id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              value={row._id}
                              // onChange={(e) => setQuestionId([...questionId, e.target.value])}

                              checked={questionId.includes(row._id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setQuestionId([
                                    ...questionId,
                                    e.target.value,
                                  ]);
                                } else {
                                  setQuestionId(
                                    questionId.filter(
                                      (id) => id !== e.target.value
                                    )
                                  );
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.question}</TableCell>
                          <TableCell>{row.typeOf}</TableCell>
                          {row.options.map((opt) => (
                            <TableCell key={opt._id}> {opt}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <div className="text-center">No Data Found....</div>
                  )}
                </Table>
              </TableContainer>
            </Paper>

            <Button
              variant="contained"
              size="small"
              sx={{ padding: 1 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default QuestionsModal;
