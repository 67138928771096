import Page from "@jumbo/shared/Page";
import ListRoom from "app/pages/Room/ListRoom";
import AddRoom from "app/pages/Room/AddRoom";
import ViewRoom from "app/pages/Room/ViewRoom";
import AddSession from "app/pages/Session/AddSession";
import SessionGuestList from "app/pages/Room/Session/SessionGuestList";

import { RoomMiddleware } from "../Middleware/eventManagement/event.management.middleware";

const routesName = "/rooms";

export const roomsRoute = [
  {
    middleware: [{ element: RoomMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: `${routesName}`,
        element: <Page component={ListRoom} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/add`,
        element: <Page component={AddRoom} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/view/:id`,
        element: <Page component={ViewRoom} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/session/:id`,
        element: <Page component={AddSession} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/view/:id/:id`,
        element: (
          <Page component={SessionGuestList} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
