import { LIST_BANNERS_SUCCESS } from "app/redux/actions/Banner/constants";
import { ALL_FEEDBACKS } from "app/redux/actions/Feedback/feedbackConstants";

const initialState = {
  bannerList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPage: null,
};

const reducerFuction = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BANNERS_SUCCESS:
      return {
        ...state,
        bannerList: action.payload?.data?.bannerList || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPage: action.payload?.data?.totalPage,
      };

    default:
      return state;
  }
};

export default reducerFuction;
