import React from "react";
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EmailCampaignChart from "../EmailCampaign/EmailCampaignChart";

const NewVisitorsThisMonth = () => {
  return (
    <JumboCardQuick
      title={
        <Typography fontWeight={"500"} variant={"h3"} color={"common.white"}>
          Total Registration - Month Wise
        </Typography>
      }
      //   subheader={
      //     <Typography variant={"h6"} color={"common.white"} mb={0}>
      //       {t("widgets.subheader.newVisitors")}
      //     </Typography>
      //   }
      action={<TrendingUpIcon fontSize={"small"} />}
      reverse
      sx={{ color: "common.white", borderTop: "4px solid #F39711" }}
      bgColor={["#f9cc8a", "#f39711"]}
      wrapperSx={{ pb: 0 }}
    >
      <EmailCampaignChart />
    </JumboCardQuick>
  );
};

export default NewVisitorsThisMonth;
