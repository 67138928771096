
import PlateLogs from "app/pages/Plates/PlatesLogs/PlateLogs";


const { default: Page } = require("@jumbo/shared/Page")



const routesName = "/plates"

const routes = [
    {
        path: `${routesName}`,
        element: <Page component={PlateLogs} layout={"vertical-default"} />
    }


]

export const platesRoutes = routes;