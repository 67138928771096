import React from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { Form, Formik } from "formik";

import * as Yup from "yup";

import ListOrganisers from "./ListOrganiser";
function AddOrganiser({ addedOrganiserList, setAddedOrganiserList }) {
  const initialValues = {
    item_sr_no: addedOrganiserList.length + 1,
    organiser_image: "",
    organiser_name: "",
    designation: "",
  };
  const validationSchema = Yup.object({
    organiser_image: Yup.string("Add Image")
      .required("Organiser image is required")
      .nullable(),
    organiser_name: Yup.string("Add Organiser Name").required(
      "Organiser name is required"
    ),
    designation: Yup.string("Add Designation").required(
      "Designation is required"
    ),
  });

  const handleSubmit = (values, actions) => {
    setAddedOrganiserList([...addedOrganiserList, values]);
    actions.resetForm();
  };
  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("organiser_image", file);
    // if (file) {
    //     setImagePreview(URL.createObjectURL(file));
    // }
  };

  return (
    <React.Fragment>
      <Typography variant="h3" mb={3} mt={3}>
        Official Organisers
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid xs={4} item>
                <TextField
                  fullWidth
                  size="small"
                  id="organiser_image"
                  name="organiser_image"
                  label="Image*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button size="small" component="label">
                        <FileUploadOutlined />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(event) => {
                            handleImageChange(event, setFieldValue);
                            // const file = event.target.files[0];
                            // if (file) {
                            //     setFieldValue("organiser_image", file);
                            // }
                          }}
                        />
                      </Button>
                    ),
                    readOnly: true,
                  }}
                  value={
                    values.organiser_image
                      ? values?.organiser_image?.name
                      : null || ""
                  }
                  error={
                    touched.organiser_image && Boolean(errors.organiser_image)
                  }
                  helperText={touched.organiser_image && errors.organiser_image}
                />
              </Grid>
              <Grid xs={4} item>
                <JumboTextField
                  fullWidth
                  id="organiser_name"
                  name="organiser_name"
                  label="Organiser Name*"
                  value={values?.organiser_name}
                  // onChange={(e) => {
                  //     setFieldValue("organiser_name", e.target.value);
                  //     // handleValuesChange({ ...values, organiser_name: e.target.value });
                  // }}
                />
              </Grid>
              <Grid xs={4} item>
                <JumboTextField
                  fullWidth
                  id="designation"
                  name="designation"
                  label="Designation*"
                  value={values?.designation}
                  // onChange={(e) => {
                  //     setFieldValue("designation", e.target.value);
                  //     // handleValuesChange({ ...values, designation: e.target.value });
                  // }}
                />
              </Grid>
              <Grid xs={12} item display="flex" justifyContent="flex-end">
                <Button size="small" type="submit" variant="contained">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ListOrganisers
        setAddedOrganiserList={setAddedOrganiserList}
        addedOrganiserList={addedOrganiserList}
      />
    </React.Fragment>
  );
}

export default AddOrganiser;
