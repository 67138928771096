import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useSelector } from "react-redux";
import ToastAlerts from "app/components/Toast";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";
import { fetchGiveawayList } from "app/redux/actions/Giveaway";
import { Axios } from "app/services/config";
export default function ListGiveAwayItems() {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [query, setQuery] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { deletgateKitList, totalPage, error, loading } = useSelector(
    (state) => state.giveawayReducer
  );
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  const columns = [
    {
      field: "item_name",
      headerName: "Item Name",
      sortable: true,
    },
    {
      field: "event_name",
      headerName: "Event Name",
      sortable: true,
    },
    {
      field: "total_quantity",
      headerName: "Total Quantity",
      sortable: true,
      // isImage: true
    },
    {
      field: "available_quantity",
      headerName: "Available Quantity",
      sortable: true,
      // isImage: true
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      sortable: true,
      isDate: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      sortable: true,
      isDate: true,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      render: (value, elm) =>
        value ? (
          <Button size="small" variant="outlined" color="success">
            Active
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error">
            Inactive
          </Button>
        ),
      onClick: async (elm) => {
        try {
          let status = elm.status;
          const newData = { ...elm, status: !status };
          const result = await Swal.fire({
            title: `Change  status to ${status ? "inactive" : "active"} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (result.isConfirmed) {
            await Axios.post(`/giveaway/edit/${elm._id}`, newData);
            showAlert("success", "Status updated successfully.");
            navigate("/giveaway");
            dispatch(fetchGiveawayList(query));
          }
        } catch (error) {
          console.error("Error updating guest:", error);
          showAlert("error", "Failed to Update Status.");
        }
      },
    },
  ];
  const actions = [
    ...(permissions?.delegate_kit?.view
      ? [
          {
            label: "Edit",
            color: "secondary",
            onClick: (row) => navigate(`/giveaway/edit`, { state: row }),
            icon: <ModeEditOutlinedIcon />,
          },
        ]
      : []),
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    // GetAttendedCount();
    dispatch(fetchGiveawayList(query));
  }, [query]);

  const DeletgateKitExportCsv = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/report/delegateKitListCsv`;
      if (query) {
        const queryParams = new URLSearchParams(query);
        apiUrl =
          apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
      }
      const response = await Axios.get(apiUrl);
      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(loading || loader || isLoading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Typography variant="h1">Delegate Kit </Typography>

          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "35px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {/* <Div
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "50%",
                                }}
                            >
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="tags-standard"
                                    options={statusList}
                                    getOptionLabel={(option) => option}
                                    value={selectedStatus}
                                    onChange={(e, newValue) => {
                                        setSelectedStatus(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Attendance Status" />
                                    )}
                                />

                                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ height: "35px" }}
                                        onClick={handleFilter}
                                    >
                                        Apply
                                    </Button>

                                    <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{ height: "35px" }}
                                        onClick={handleClearFilter}
                                    >
                                        Clear
                                    </Button>
                                </Div>
                            </Div> */}
            </Div>

            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              {permissions?.delegate_kit?.view && (
                <Button
                  size="small"
                  // disabled={guestList?.length === 0}
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={async () => {
                    DeletgateKitExportCsv();
                  }}
                >
                  export csv
                </Button>
              )}
              {permissions?.delegate_kit?.create && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => navigate("/giveaway/add")}
                >
                  Add Delegate Kit
                </Button>
              )}
            </Div>
          </Div>
        </Div>

        <Div>
          <CustomTable
            data={deletgateKitList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPage}
          />
        </Div>
        {/* {openView && guestDetails && (
                    <ViewGuest
                        openView={openView}
                        setOpenView={setOpenView}
                        data={guestDetails}
                    />
                )} */}
      </Div>
    </>
  );
}
