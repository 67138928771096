import { Grid, Typography } from "@mui/material";
import Documents1 from "app/components/Documents1";
import React from "react";
import { useLocation } from "react-router-dom";
import ViewComponent from "./ViewComponent";

function ViewFeedBackPage() {
  const { state } = useLocation();

  return (
    <>
      <Typography variant="h1">View Guest Feedback</Typography>
      <Grid item marginBottom={2} display={"flex"} gap={4} marginTop={3}>
        <Documents1
          // icon={<MeetingRoomIcon sx={{ fontSize: 36 }} />}
          field="Guest Name"
          data={
            state?.guestDetails?.first_name +
            " " +
            state?.guestDetails?.last_name
          }
        />
        <Documents1
          // icon={<MeetingRoomIcon sx={{ fontSize: 36 }} />}
          field="Survey Name"
          data={state?.surveyDetails?.survey_name}
        />
      </Grid>
      {state?.questionAndAnswers?.map((item) => (
        <div className="mb-4 mt-2">
          <ViewComponent data={item} />
        </div>
      ))}
    </>
  );
}

export default ViewFeedBackPage;
