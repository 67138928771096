// import { ALL_FEEDBACKS, FEEDBACK_ERROR } from './feedbackConstants'

// import { Axios } from 'app/services/config';

// const setError = (type, error) => ({ type, payload: error });
// const clearError = (type) => ({ type, payload: null });

// export const onFeedbackList = ({ query, page, limit, sortField, sortOrder, eventId }) => async (dispatch) => {
//     try {
//         dispatch(clearError(FEEDBACK_ERROR));
//         dispatch({ type: ALL_FEEDBACKS });
//         const params = { query, page, limit, sortField, sortOrder, eventId }
//         const { data } = await Axios.get("/feedback/feedbacks", { params })
//         dispatch({ type: ALL_FEEDBACKS, payload: data })
//     } catch (error) {
//         dispatch(setError(FEEDBACK_ERROR, error.message))
//     }
// };

import { Axios } from "app/services/config";
import {
  ALL_FEEDBACKS_FAILED,
  ALL_FEEDBACKS_REQUEST,
  ALL_FEEDBACKS_SUCCESS,
} from "./feedbackConstants.js";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchAllQuestionsList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(ALL_FEEDBACKS_FAILED));
    dispatch({ type: ALL_FEEDBACKS_REQUEST });

    let apiUrl = `/feedback/all-questions`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: ALL_FEEDBACKS_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(ALL_FEEDBACKS_FAILED, error?.response?.data?.message));
  }
};
