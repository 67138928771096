import React, { useState } from "react";
// import AddFAQ from "./AddFAQ";
import { Button } from "@mui/material";
import FAQModal from "./Modal/FAQModal";

function AddFAQ() {
  const [open, setOpen] = useState(false);
  const [currentFAQ, setCurrentFAQ] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentFAQ(null);
  };

  const handleSave = (data) => {
    if (currentFAQ) {
      // Update existing FAQ logic
    } else {
      // Add new FAQ logic
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Add FAQ
      </Button>

      <FAQModal
        open={open}
        handleClose={handleClose}
        initialValues={currentFAQ}
        onSubmit={handleSave}
      />
    </>
  );
}

export default AddFAQ;

// export default AddFAQ;
