import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  TablePagination,
  Typography,
  MenuItem,
  Button,
  TableSortLabel,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Axios } from "app/services/config";
import Documents1 from "app/components/Documents1";
import GiftsCreationPage from "app/pages/Gifts/GiftCreation/GiftsCreationPage";
import { useNavigate } from "react-router-dom";

function PlateLogs() {
  const [plateLogs, setPlateLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [toogle, setToogle] = useState(false);
  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState("asec");
  const [sortField, setSortField] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("/plates/logs", {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            query: debouncedQuery,
            filter: filter,
            sortOrder,
            sortField,
          },
        });
        setPlateLogs(res.data?.data?.allPlateLogs);
        setTotalCount(res.data?.data?.totalCount);
      } catch (error) {
        console.error("Error fetching plate logs: ", error);
      }
    };

    fetchData();
    fetchAllEvents();
  }, [page, rowsPerPage, debouncedQuery, filter, sortField, sortOrder]);

  const fetchAllEvents = async () => {
    try {
      const res = await Axios.get("room/events");
      setEvents(res.data?.data?.allRooms);
    } catch (error) {
      console.log("err fetching events => ", error.message);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = () => {
    setFilter(selectedEvent);
  };

  const clearFilter = () => {
    setSelectedEvent("");
    setFilter("");
  };

  const handleSortRequest = (field) => {
    // const isAsc = field === sortField && sortOrder === "desc";
    sortField === field
      ? setSortOrder(sortOrder === "asec" ? "desc" : "asec")
      : setSortOrder("asec");
    setSortField(field);
  };
  return (
    <>
      <p className="mb-5">
        <Typography variant="h1">Plates Log</Typography>
      </p>
      <div className="flex flex-col items-center gap-8">
        <div className="self-start">
          <Documents1 field={"Total Plate Count"} data={totalCount} />
        </div>
        <div className="flex md:flex-row flex-col items-center  w-full  gap-5">
          <TextField
            size="small"
            label="Search Event"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
            // className="py-3 w-1/3 self-start"
          />
          <div className="flex flex-row items-center gap-5">
            <TextField
              size="small"
              label="Select Event"
              variant="outlined"
              select
              value={selectedEvent}
              onChange={(e) => {
                setSelectedEvent(e.target.value);
              }}
              className="lg:w-[14rem] w-[8rem]"
            >
              <MenuItem value="" disabled>
                Select Event
              </MenuItem>
              {events.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.room_no}
                </MenuItem>
              ))}
            </TextField>
            <Button
              size="small"
              sx={{ padding: 1 }}
              variant="contained"
              onClick={handleFilter}
            >
              Apply
            </Button>
            <Button
              size="small"
              sx={{ padding: 1 }}
              variant="outlined"
              onClick={clearFilter}
            >
              Clear
            </Button>
          </div>
        </div>
        <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="plate logs table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "plate_no"}
                      direction={sortOrder}
                      onClick={() => handleSortRequest("plate_no")}
                    >
                      Plate No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "eventDetails.room_no"}
                      direction={sortOrder}
                      onClick={() => handleSortRequest("eventDetails.room_no")}
                    >
                      Room No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortField === "guestDetails.first_name"}
                      direction={sortOrder}
                      onClick={() =>
                        handleSortRequest("guestDetails.first_name")
                      }
                    >
                      Guest Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>In Time</TableCell>
                  <TableCell>Attended Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plateLogs?.length > 0 ? (
                  plateLogs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.plate_no}</TableCell>
                      <TableCell>{log.eventDetails?.room_no}</TableCell>
                      <TableCell>
                        {log.guestDetails?.first_name}{" "}
                        {log.guestDetails?.last_name}
                      </TableCell>

                      <TableCell>
                        {log.guestDetails?.in_time !== null
                          ? log.guestDetails?.in_time
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {log.guestDetails?.attendance_status}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data Found...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalCount > 0 && (
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={""}
            />
          )}
        </Paper>
      </div>
    </>
  );
}

export default PlateLogs;
