// import React, { useEffect, useState } from "react";
// import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
// import { totalEmails } from "./data";
// import Div from "@jumbo/shared/Div";
// import { Axios } from "app/services/config";

// const itemLabels = {
//   sent: "Sent",
//   bounced: "Bounced",
// };

// const EmailCampaignChart = ({ color, shadowColor }) => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     (async function () {
//       try {
//         const { data } = await Axios.get("/metrics/month");
//         setData(
//           data?.data?.map((e) => ({
//             month: e?.month,
//             count: e?.count,
//             year: e?.year,
//           }))
//         );
//       } catch (error) {
//         console.error(error);
//       }
//     })();
//   }, []);
//   return (
//     <ResponsiveContainer height={80}>
//       <LineChart data={totalEmails} className={"mx-auto"}>
//         <defs>
//           <filter id="shadowMailSent" height="300%">
//             <feDropShadow
//               dx="0"
//               dy="3"
//               stdDeviation="8"
//               floodColor={shadowColor ? shadowColor : "#666666"}
//             />
//           </filter>
//         </defs>
//         <Tooltip
//           labelStyle={{ color: "black" }}
//           cursor={false}
//           content={({ active, label, payload }) => {
//             return active ? (
//               <Div sx={{ color: "common.white" }}>
//                 <div>Month: {label}</div>
//                 {payload.map((row, index) => (
//                   <div key={index} mt={1}>
//                     {itemLabels[row.name]} - {row.value}
//                   </div>
//                 ))}
//               </Div>
//             ) : null;
//           }}
//           wrapperStyle={{
//             background: color ? color : "rgba(0,0,0,.8)",
//             padding: "5px 8px",
//             borderRadius: 4,
//             overflow: "hidden",
//             boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
//           }}
//         />
//         <XAxis dataKey="month" hide />
//         <Line
//           dataKey="sent"
//           filter={"url(#shadowMailSent)"}
//           type="monotone"
//           dot={null}
//           strokeWidth={3}
//           data={data}
//           stackId="2"
//           stroke={color ? color : "#FFFFFF"}
//         />
//       </LineChart>
//     </ResponsiveContainer>
//   );
// };
// /* Todo color, shadowColor prop define */
// export default EmailCampaignChart;
import React, { useEffect, useState } from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import Div from "@jumbo/shared/Div";
import { Axios } from "app/services/config";

const itemLabels = {
  count: "Count",
  //   bounced: "Bounced",
};

const EmailCampaignChart = ({ color = "#FFFFFF", shadowColor = "#666666" }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const { data: response } = await Axios.get("/metrics/month");
        setData(
          response?.data?.map((e) => ({
            month: e.month,
            count: e.count, // Assuming 'count' represents the number of emails sent
            year: e.year,
          }))
        );
      } catch (error) {
        console.error("Error fetching email metrics:", error);
      }
    })();
  }, []);

  return (
    <ResponsiveContainer height={80}>
      <LineChart data={data} className="mx-auto">
        <defs>
          <filter id="shadowMailSent" height="300%">
            <feDropShadow
              dx="0"
              dy="3"
              stdDeviation="8"
              floodColor={shadowColor}
            />
          </filter>
        </defs>
        <Tooltip
          labelStyle={{ color: "black" }}
          cursor={false}
          content={({ active, label, payload }) =>
            active ? (
              <Div sx={{ color: "common.white" }}>
                {/* <div>Month: {label}</div> */}
                Month: {label} {payload[0]?.payload?.year}
                {payload?.map((row, index) => (
                  <div key={index} mt={1}>
                    {/* {itemLabels[row?.dataKey]} - {row?.value} */}
                    {itemLabels[row.name] || row.name} - {row.value}
                  </div>
                ))}
              </Div>
            ) : null
          }
          wrapperStyle={{
            background: "rgba(0,0,0,.8)",
            padding: "5px 8px",
            borderRadius: 4,
            overflow: "hidden",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            // color: "black",
          }}
        />
        <XAxis dataKey="month" hide />
        <Line
          dataKey="count"
          filter="url(#shadowMailSent)"
          type="monotone"
          dot={null}
          strokeWidth={3}
          stroke={color}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default EmailCampaignChart;
