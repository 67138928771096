import {
  USER_ADDED,
  USER_DELETE,
  USER_EDIT,
  USER_LIST,
  USER_ERROR,
  USER_REQUEST,
  LOAD_USER_SUCCESS,
} from "../../actions/User/userConstant";

const initialState = {
  userList: [],
  userInfo: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPages: 0,
};

const reducerFunc = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST:
      return {
        ...state,
        userList: action.payload?.data || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPages: action.payload?.totalPages,
      };

    case USER_ADDED:
    case USER_EDIT:
    case USER_DELETE:
      return {
        ...state,
        loading: false,
        successMessage: action.payload?.message,
        error: null,
      };

    case USER_ERROR:
      return {
        ...state,
        loading: false,
        successMessage: null,
        error: action.payload,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload?.data?.data,
      };

    default:
      return state;
  }
};

export default reducerFunc;
