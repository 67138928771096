import { Axios } from "app/services/config";
import {
  ALL_SURVEY_FAILED,
  ALL_SURVEY_REQUEST,
  ALL_SURVEY_SUCCESS,
} from "./constants.js";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchAllSurveyList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(ALL_SURVEY_FAILED));
    dispatch({ type: ALL_SURVEY_REQUEST });

    let apiUrl = `/feedback/list-survey`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: ALL_SURVEY_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(ALL_SURVEY_FAILED, error?.response?.data?.message));
  }
};
