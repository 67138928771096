import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Div from "@jumbo/shared/Div";

import { Axios } from "app/services/config";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Typography } from "@mui/material";

const LineChartIIT = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await Axios.get("/metrics/iit");
        setData(
          data?.data?.map((e) => ({
            iit_name: e?.iit_name,
            count: e?.count,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <JumboCardQuick
      title={
        <Typography variant={"h3"}>
          {"Total Registration - IIT Wise"}
        </Typography>
      }
      wrapperSx={{ pt: 0 }}
    >
      <ResponsiveContainer height={250}>
        <LineChart
          width={480}
          height={250}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <defs>
            <filter id="shadow" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="8"
                floodColor={"#82ca9d"}
              />
            </filter>
          </defs>
          <CartesianGrid
            strokeDasharray="6 1 2"
            horizontal={false}
            strokeOpacity={0.3}
          />
          <XAxis dataKey="iit_name" axisLine={false} tickLine={false} />
          <YAxis dataKey="count" axisLine={false} tickLine={false} />
          <Tooltip
            content={({ active, label, payload }) => {
              return active ? (
                <Div sx={{ color: "common.white" }}>
                  {payload.map((row, index) => {
                    return (
                      <div
                        key={index}
                        className={index !== payload.length - 1 ? "mb-1" : ""}
                      >
                        <div
                          style={{
                            color: row.color,
                            fontSize: 8,
                            letterSpacing: 2,
                            textTransform: "uppercase",
                          }}
                        >
                          {/* {capitalizeFLetter(row.name)} */}
                        </div>
                        <div
                          style={{
                            color: row.color,
                          }}
                        >
                          {row.value} Guests
                        </div>
                      </div>
                    );
                  })}
                </Div>
              ) : null;
            }}
            wrapperStyle={{
              background: "rgba(0,0,0,0.9)",
              borderRadius: 4,
              padding: "5px 8px",
              fontWeight: 500,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
          />
          <Line
            type="linear"
            strokeWidth={2}
            dataKey="count"
            stroke="#82ca9d"
            filter="url(#shadow)"
          />
        </LineChart>
      </ResponsiveContainer>
    </JumboCardQuick>
  );
};

export default LineChartIIT;
