import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import contactsApp from "./contactsApp";
import UserReducer from "./User";
import RoomReducer from "./Room";
import GuestReducer from "./Guest";
import LogsReducer from "./Logs";
import FeedbackQuestionReducer from "./Feedback";
import SurveyReducer from "./Feedback/Survey";
import RatingAndReviewReducer from "./Feedback/RatingAndRevies";
import BannerReducer from "./Banner";
import SponsorReducer from "./Sponsor";
import PartnerReducer from "./Partners";
import SpeakersReducer from "./Speakers";
import RoadShowReducer from "./Roadshow";
import FloorPlanReducer from "./FloorPlan";
import AgendaReducer from "./Agenda";
import AboutUsReducer from "./AboutUs";
import GiveawayReducer from "./Giveaway";
import GiveawayTrackerReducer from "./Giveaway/GiveawayTracker";
import OtherLinksReducer from "./OtherLinks";
import LiveEventReducer from "./LiveEvent";
import FaqReducer from "./FAQ";
import RolesAndPermissionsReducer from "./RolesAndPermissions";
import StallReducer from "./Stall";
const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    userReducer: UserReducer,
    roomReducer: RoomReducer,
    guestReducer: GuestReducer,
    logsReducer: LogsReducer,
    contactsApp: contactsApp,
    feedBackQuestionReducer: FeedbackQuestionReducer,
    bannerReducer: BannerReducer,
    sponsorReducer: SponsorReducer,
    partnerReducer: PartnerReducer,
    speakersReducer: SpeakersReducer,
    roadShowReducer: RoadShowReducer,
    floorPlanReducer: FloorPlanReducer,
    agendaReducer: AgendaReducer,
    aboutUsReducer: AboutUsReducer,
    giveawayReducer: GiveawayReducer,
    giveawayTrackerReducer: GiveawayTrackerReducer,
    otherLinksReducer: OtherLinksReducer,
    liveEventReducer: LiveEventReducer,
    faqReducer: FaqReducer,
    surveyReducer: SurveyReducer,
    ratingAndReviewReducer: RatingAndReviewReducer,
    rolesAndPermissionsReducer: RolesAndPermissionsReducer,
    stallReducer: StallReducer,
  });
};

export default exportReducers;
