import Div from "@jumbo/shared/Div/Div";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { Button, Grid } from "@mui/material";
import AllApis from "app/Apis/apis";
import Documents1 from "app/components/Documents1";
import FullScreenLoader from "app/components/ListingPageLoader";
import CustomTable from "app/components/Table";
import ToastAlerts from "app/components/Toast";
import { onRoomViewList } from "app/redux/actions/Room";
import { Axios } from "app/services/config";
import SearchGlobal from "app/shared/SearchGlobal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddSession from "../Session/AddSession";

export default function ListRoom() {
  const showAlert = ToastAlerts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [sesstionList, setSessionList] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [query, setQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  const { roomViewList, totalPages, error, loading } = useSelector(
    (state) => state.roomReducer
  );
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  const roomViewExportCsv = async () => {
    setIsLoading(true);
    try {
      const filterValue = selectedStatus?.session_name || "";
      const apiUrl = `/report/roomViewListCsv?id=${state?._id}&filter=${filterValue}`;
      const response = await Axios.get(apiUrl);
      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const actions = [
    ...(permissions?.session?.view
      ? [
          {
            label: "View Session",
            color: "secondary",
            onClick: (row) =>
              navigate(`/rooms/view/${row.room_id}/${row._id}`, { state: row }),
            icon: <PreviewOutlinedIcon />,
          },
        ]
      : []),

    ...(permissions?.session?.edit
      ? [
          {
            label: "Edit Session",
            color: "secondary",
            onClick: (row) => {
              setSelectedSessionId(row?._id);
              setOpenView(true);
            },
            icon: <ModeEditOutlinedIcon />,
          },
        ]
      : []),
  ];

  const columns = [
    {
      field: "session_image",
      headerName: "Session Image",
      sortable: true,
      isImage: true,
      // width: 400,
      render: (_, elm) => elm?.session_image || "-",
    },
    {
      field: "session_name",
      headerName: "Session Name",
      sortable: true,
      width: 200,
      render: (_, elm) => elm?.session_name,
    },
    {
      field: "session_description",
      headerName: "Session Description",
      width: 300,
      sortable: false,
      render: (_, elm) => elm?.session_description,
    },

    {
      field: "category",
      headerName: "Category",
      sortable: true,
      render: (_, elm) => elm?.category,
    },
    {
      field: "speaker",
      headerName: "Speakers",
      sortable: true,
      width: 250,
      render: (_, elm) => {
        return elm?.speakerDetails?.map((s) => s?.speaker_name).join(", ");
      },
    },
    {
      field: "time",
      headerName: "Time",
      sortable: true,
      render: (_, elm) => elm?.time,
    },
    {
      field: "date",
      headerName: "Date",
      isDate: true,
      sortable: true,
      render: (_, elm) => elm?.date,
    },

    {
      field: "guest_count",
      headerName: "Total Guest",
      // width: 100,
      sortable: false,
    },
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  const handleFilter = () => {
    setQuery({
      ...query,
      filter: selectedStatus?.session_name
        ? selectedStatus?.session_name
        : null,
    });
  };

  const handleClearFilter = () => {
    setSelectedStatus(null);
    setQuery({ ...query, filter: "" });
  };

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    if (!openView) {
      dispatch(onRoomViewList(query, state?._id));
    }
  }, [query, openView]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(
          `${AllApis.session.listsessionBasedonRoomId(state?._id)}`
        );

        setSessionList(response?.data?.data);
      } catch (error) {
        console.log(error);
        // showAlert("error", error.response.data.message);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {(isLoading || loading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10rem",
              mb: 3,
            }}
          >
            {" "}
            <Grid container spacing={3.75}>
              <Grid item>
                <Documents1
                  icone={<MeetingRoomIcon sx={{ fontSize: 36 }} />}
                  field="Room Name"
                  data={state?.room_no}
                />
              </Grid>
              {/* <Grid item>
                <Documents1
                  icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                  field="Total Guest"
                  data={guestCount?.guest_count ? guestCount?.guest_count : 0}
                />
              </Grid> */}
              {/* <Grid item>
                <Documents1
                  icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                  field="Total Active Guest"
                  data={
                    guestCount?.active_user_count
                      ? guestCount?.active_user_count
                      : 0
                  }
                />
              </Grid> */}
            </Grid>
          </Div>
          {/* <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
           >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "40px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
               >
                <Autocomplete
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={sesstionList}
                  getOptionLabel={(option) => option?.session_name}
                  value={selectedStatus}
                  onChange={(e, newValue) => {
                    setSelectedStatus(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Session" />
                  )}
                />
                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ height: "35px" }}
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ height: "35px" }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                </Div>
              </Div>
            </Div>
            <Div>
              <Button
                size="small"
                disabled={roomViewList?.length === 0}
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={async () => {
                  roomViewExportCsv();
                }}
              >
                export csv
              </Button>
            </Div>
          </Div> */}
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchGlobal
              sx={{
                mb: 5,
                mt: 4,
                maxWidth: { xs: 240, md: 320 },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              {permissions?.session?.view && (
                <Button
                  size="small"
                  disabled={roomViewList?.length === 0}
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={async () => {
                    roomViewExportCsv();
                  }}
                >
                  export csv
                </Button>
              )}

              {permissions?.session?.create && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => {
                    setSelectedSessionId(null);
                    setOpenView(true);
                  }}
                >
                  Add session
                </Button>
              )}
            </Div>
          </Div>
        </Div>
        <Div>
          <CustomTable
            data={roomViewList}
            actions={actions}
            columns={columns}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
        {openView && (
          <AddSession
            openView={openView}
            setOpenView={setOpenView}
            sessionId={selectedSessionId}
            roomId={state?._id}
          />
        )}
      </Div>
    </>
  );
}
