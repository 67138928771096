import MAppedQuestionsTable from "app/pages/Feedback/MappedQuestions/MAppedQuestionsTable";
import GiftsCreationPage from "app/pages/Gifts/GiftCreation/GiftsCreationPage";
import Tracker from "app/pages/Gifts/GiveAwayTracker/Tracker";
import MappedEventsGiftsPage from "app/pages/Gifts/MappedEventsWithGifts/MappedEventsGiftsPage";

const { default: Page } = require("@jumbo/shared/Page");
// const { default: CreateQuestions } = require("app/pages/Feedback/Questions/CreateQuestions")

const routesName = "/gifts";

const routes = [
  {
    path: `${routesName}`,
    element: <Page component={GiftsCreationPage} layout={"vertical-default"} />,
  },
  // {
  //     path: `/giveaway-tracker`,
  //     element: <Page component={Tracker} layout={"vertical-default"} />
  // },
  {
    path: `/gift-event-mapping/:id`,
    element: (
      <Page component={MappedEventsGiftsPage} layout={"vertical-default"} />
    ),
  },
];

export const giftsRoutes = routes;
