import { Axios } from "app/services/config";
import { LIST_AGENDA_FAILED, LIST_AGENDA_REQUEST, LIST_AGENDA_SUCCESS } from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchAgendaList = (query) => async (dispatch) => {
    try {
        dispatch(clearError(LIST_AGENDA_FAILED));
        dispatch({ type: LIST_AGENDA_REQUEST })

        let apiUrl = `/agenda/list-agenda`;
        if (query) {
            const queryParams = new URLSearchParams(query);
            apiUrl =
                apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
        }
        const { data } = await Axios.get(apiUrl);

        dispatch({ type: LIST_AGENDA_SUCCESS, payload: data });
    } catch (error) {
        // setLoading(false);
        dispatch(setError(LIST_AGENDA_FAILED, error?.response?.data?.message));
    }
};
