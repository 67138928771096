// import React from "react";
// import CityWiseChart from "./CityWise";

// import { Grid } from "@mui/material";
// import DesignationWiseChart from "./DesignationWise";

// function AllAnalyticsPage() {
//   return (
//     // <Div>
//     <Grid spacing={2}>
//       <Grid item xs={12} sm={6} lg={4}>
//         <CityWiseChart />
//       </Grid>
//       <Grid item xs={12} sm={6} lg={4}>
//         <DesignationWiseChart />
//       </Grid>
//     </Grid>
//     // </Div>
//   );
// }

// export default AllAnalyticsPage;
import React from "react";
import CityWiseChart from "./CityWise";

import DesignationWiseChart from "./DesignationWise";
import GenderWiseChart from "./GenderWise";

import LineChartIIT from "./IITWise/LinearChartIIT";
import SessionWiseFeedbackChart from "./SessionWiseFeedback";
import NewVisitorsThisMonth from "app/shared/metrics/NewVisitorsThisMonth";

function AllAnalyticsPage() {
  return (
    // <Div>
    <div className="grid grid-flow-row grid-cols-12 gap-6">
      <div className="grid grid-flow-row col-span-12">
        <CityWiseChart />
      </div>
      <div className="grid grid-flow-row col-span-12">
        <DesignationWiseChart />
      </div>
      <div className="grid grid-flow-row col-span-12">
        <LineChartIIT />
      </div>
      <div className="grid grid-flow-row col-span-6">
        <GenderWiseChart />
      </div>
      <div className="grid grid-flow-row col-span-6">
        <NewVisitorsThisMonth />
      </div>
    </div>
    // </Div>
  );
}

export default AllAnalyticsPage;
