// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import React from "react";
// import List from "@mui/material/List";
// import { ListItem, ListItemText, Typography } from "@mui/material";
// import { getCustomDateTime } from "@jumbo/utils";

// const ViewProgamme = ({ openView, setOpenView, state }) => {
//   return (
//     <Dialog
//       open={openView}
//       onClose={() => setOpenView(false)}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//       maxWidth="md"
//     >
//       <DialogTitle
//         style={{ backgroundColor: "#7352C7", color: "white" }}
//         id="alert-dialog-title"
//       >
//         Program Details
//       </DialogTitle>
//       <DialogContent
//         headerSx={{
//           borderBottom: 1,
//           borderColor: "divider",
//         }}
//         sx={{ mb: 3.75 }}
//       >
//         <List
//           disablePadding
//           sx={{
//             display: "flex",
//             flexWrap: "wrap",
//             margin: (theme) => theme.spacing(0, -2),
//           }}
//         >
//           <ListItem
//             sx={{
//               width: { xs: "100%", sm: "50%", xl: "33.33%" },
//               textAlign: "center",
//             }}
//           >
//             <ListItemText
//               primary={
//                 <Typography
//                   fontSize={"12px"}
//                   variant="h6"
//                   color="text.secondary"
//                   mb={0.5}
//                 >
//                   Date
//                 </Typography>
//               }
//               secondary={
//                 <Typography variant="body1" color="text.primary">
//                   {new Date(state?.agenda_date).toLocaleDateString() || "--"}
//                 </Typography>
//               }
//             />
//           </ListItem>
//           <ListItem
//             sx={{
//               width: { xs: "100%", sm: "50%", xl: "33.33%" },
//               textAlign: "center",
//             }}
//           >
//             <ListItemText
//               primary={
//                 <Typography
//                   fontSize={"12px"}
//                   variant="h6"
//                   color="text.secondary"
//                   mb={0.5}
//                 >
//                   Time
//                 </Typography>
//               }
//               secondary={
//                 <Typography variant="body1" color="text.primary">
//                   {state?.time || "--"}
//                 </Typography>
//               }
//             />
//           </ListItem>
//           <ListItem
//             sx={{
//               width: { xs: "100%", sm: "50%", xl: "33.33%" },
//               textAlign: "center",
//             }}
//           >
//             <ListItemText
//               primary={
//                 <Typography
//                   fontSize={"12px"}
//                   variant="h6"
//                   color="text.secondary"
//                   mb={0.5}
//                 >
//                   Title
//                 </Typography>
//               }
//               secondary={
//                 <Typography variant="body1" color="text.primary">
//                   {state?.title || "--"}
//                 </Typography>
//               }
//             />
//           </ListItem>
//           <ListItem
//             sx={{
//               width: { xs: "100%", sm: "50%", xl: "33.33%" },
//               textAlign: "center",
//             }}
//           >
//             <ListItemText
//               primary={
//                 <Typography
//                   fontSize={"12px"}
//                   variant="h6"
//                   color="text.secondary"
//                   mb={0.5}
//                 >
//                   Activities
//                 </Typography>
//               }
//               secondary={
//                 <Typography variant="body1" color="text.primary">
//                   {/* {state?.email_id || "--"} */}
//                   {state?.activities?.map((item, index) => (
//                     <ul className="flex flex-col items-center " key={index}>
//                       <li className="py-1">{item}</li>
//                     </ul>
//                   ))}
//                 </Typography>
//               }
//             />
//           </ListItem>

//           <ListItem
//             sx={{
//               width: { xs: "100%", sm: "50%", xl: "33.33%" },
//               textAlign: "center",
//             }}
//           >
//             <ListItemText
//               primary={
//                 <Typography
//                   fontSize={"12px"}
//                   variant="h6"
//                   color="text.secondary"
//                   mb={0.5}
//                 >
//                   Status
//                 </Typography>
//               }
//               secondary={
//                 <Typography variant="body1" color="text.primary">
//                   {" "}
//                   {state?.status ? "Active" : "Inactive"}
//                 </Typography>
//               }
//             />
//           </ListItem>
//         </List>
//       </DialogContent>
//       <DialogActions>
//         <Button
//           size="small"
//           variant="outlined"
//           onClick={() => setOpenView(false)}
//         >
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default ViewProgamme;

import { Card, CardContent, Grid, Rating, Typography } from "@mui/material";
import Documents1 from "app/components/Documents1";
import React from "react";
import { useLocation } from "react-router-dom";

function ViewProgamme() {
  const { state } = useLocation();

  return (
    <>
      <Typography variant="h1">View Agenda</Typography>
      <Grid item marginBottom={2} display={"flex"} gap={4} marginTop={3}>
        <Documents1
          // icon={<MeetingRoomIcon sx={{ fontSize: 36 }} />}
          field="Room"
          data={state?.roomDetails?.room_no}
        />
      </Grid>
      <Grid container spacing={3}>
        {state?.agendas?.map((agenda, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent>
                <div className="flex flex-col items-start gap-4">
                  <p className="flex flex-col items-start gap-2">
                    <p>
                      <strong>Time :</strong> {agenda?.time}
                    </p>
                    <p>
                      <strong>Title :</strong> {agenda?.title}
                    </p>
                    <p>
                      <strong>Activities :</strong>{" "}
                      {agenda?.activities?.join(", ")}
                    </p>
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ViewProgamme;
