import { Button, TextField, Typography } from '@mui/material';
import Documents1 from 'app/components/Documents1';
import ToastAlerts from 'app/components/Toast';
import { Axios } from 'app/services/config';
import React from 'react'
import { useForm } from 'react-hook-form';
import List from '../GiftsList/List';

function GiftsCreationPage() {


    const { register, handleSubmit } = useForm();
    const showAlerts = ToastAlerts()


    const submitHandler = async (values) => {
        try {
            const res = await Axios.post('gift/add', values);
            if (res.data.statusCode === 200) {
                showAlerts("success", res.data.message)
            } else {
                showAlerts("error", res.data.message)
            }
        } catch (error) {
            showAlerts("error", error.message)
        }
    }
    return (
        <>

            <div className='flex flex-col items-start gap-6'>
                <Typography variant="h1">Create Gifts</Typography>
                <form
                    onClick={handleSubmit(submitHandler)}
                    className='flex flex-row items-center  gap-6 w-1/4 py-2'
                >
                    <TextField
                        size='small'
                        fullWidth
                        label="Enter giftname"
                        variant='outlined'
                        {...register('name', { required: true })}
                    />
                    <Button type='submit' size='small' variant='contained' sx={{ padding: 1 }} >Create</Button>
                </form>

            </div>
            <List />
        </>
    )
}

export default GiftsCreationPage