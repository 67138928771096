import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useSelector } from "react-redux";

import ToastAlerts from "app/components/Toast";

import Swal from "sweetalert2";

// import ViewGuest from "../ViewGuest";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";
import { fetchStallList } from "app/redux/actions/Stall";
import { Axios } from "app/services/config";
export default function ListStalls() {
    const navigate = useNavigate();
    const showAlert = ToastAlerts();
    const dispatch = useDispatch();

    const [query, setQuery] = useState({});
    const [searchTerm, setSearchTerm] = useState("");

    const [loader, setLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { stallList, totalPage, error, loading } = useSelector(
        (state) => state.stallReducer
    );
    const permissions = useSelector(
        (state) => state?.userReducer?.userInfo?.role_id?.permissions
    );

    const columns = [
        {
            field: "stall_no",
            headerName: "Stall Number",
            sortable: true,
        },
        {
            field: "stall_name",
            headerName: "Stall Name",
            sortable: true,
        },
        {
            field: "remarks",
            headerName: "Remarks",
            sortable: true,
        },
        {
            field: "createdAt",
            headerName: "Created Date",
            sortable: true,
            isDate: true,
        },
        {
            field: "updatedAt",
            headerName: "Updated Date",
            sortable: true,
            isDate: true,
        },

        {
            field: "status",
            headerName: "Status",
            sortable: true,
            render: (value, elm) =>
                value ? (
                    <Button size="small" variant="outlined" color="success">
                        Active
                    </Button>
                ) : (
                    <Button size="small" variant="outlined" color="error">
                        Inactive
                    </Button>
                ),
            onClick: async (elm) => {
                try {
                    let status = elm.status;
                    const newData = { ...elm, status: !status };
                    const result = await Swal.fire({
                        title: `Change  status to ${status ? "inactive" : "active"} ?`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                    });
                    if (result.isConfirmed) {
                        const res = await Axios.post(
                            `/stall/edit/${elm?._id}`,
                            newData
                        );
                        if (res?.data?.success) {
                            showAlert("success", "status updated successfully.");
                            navigate("/stall");
                            dispatch(fetchStallList(query));
                        }
                    }
                } catch (error) {
                    console.error("Error updating guest:", error);
                    showAlert("error", "Failed to Update Status.");
                }
            },
        },
    ];
    const actions = [
        ...(permissions?.stall?.edit
            ? [
                {
                    label: "Edit",
                    color: "secondary",
                    onClick: (row) => navigate(`/stall/edit`, { state: row }),
                    icon: <ModeEditOutlinedIcon />,
                },
            ]
            : []),
    ];

    const fetchData = (props) => {
        setQuery({ ...query, ...props });
    };

    useEffect(() => {
        setQuery({ ...query, search: searchTerm });
    }, [searchTerm]);

    if (error) {
        showAlert("error", error);
    }

    useEffect(() => {
        // GetAttendedCount();
        dispatch(fetchStallList(query));
    }, [query]);

    return (
        <>
            {(loading || loader || isLoading) && <FullScreenLoader />}
            <Div
                sx={{
                    mt: -4,
                    maxHeight: "89vh",
                    overflowY: "scroll",
                    paddingRight: "10px",
                }}
            >
                <Div
                    sx={{
                        position: "sticky",
                        top: 0,
                        background: "#F5F7FA",
                        zIndex: 10,
                    }}
                >
                    <Typography variant="h1">All Stalls </Typography>

                    {/* <Grid container spacing={3.75} mt={2}>
                        <Grid item>
                            <Documents1
                                icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
                                field="Total Register Guest"
                                data={count[0]?.registered ? count[0]?.registered : 0}
                            />
                        </Grid>
                    </Grid> */}

                    <Div
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Div
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                gap: "35px",
                                width: "80%",
                            }}
                        >
                            <SearchGlobal
                                sx={{
                                    mb: 5,
                                    mt: 4,
                                    maxWidth: { xs: 240, md: 320 },
                                }}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            {/* <Div
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "50%",
                                }}
                            >
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    id="tags-standard"
                                    options={statusList}
                                    getOptionLabel={(option) => option}
                                    value={selectedStatus}
                                    onChange={(e, newValue) => {
                                        setSelectedStatus(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Attendance Status" />
                                    )}
                                />

                                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ height: "35px" }}
                                        onClick={handleFilter}
                                    >
                                        Apply
                                    </Button>

                                    <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{ height: "35px" }}
                                        onClick={handleClearFilter}
                                    >
                                        Clear
                                    </Button>
                                </Div>
                            </Div> */}
                        </Div>

                        <Div
                            sx={{
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                gap: "15px",
                                width: "40%",
                            }}
                        >
                            {/* <Button
                                size="small"
                                disabled={guestList?.length === 0}
                                variant="contained"
                                sx={{ p: 1, pl: 4, pr: 4 }}
                                onClick={async () => {
                                    GuestExportCsv();
                                }}
                            >
                                export csv
                            </Button> */}
                            {permissions?.sponsors?.create && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ p: 1, pl: 4, pr: 4 }}
                                    onClick={() => navigate("/stall/add")}
                                >
                                    Add Stall
                                </Button>
                            )}
                        </Div>
                    </Div>
                </Div>

                <Div>
                    <CustomTable
                        data={stallList}
                        columns={columns}
                        actions={actions}
                        fetchData={fetchData}
                        totalCount={totalPage}
                    />
                </Div>
                {/* {openView && guestDetails && (
                    <ViewGuest
                        openView={openView}
                        setOpenView={setOpenView}
                        data={guestDetails}
                    />
                )} */}
            </Div>
        </>
    );
}
