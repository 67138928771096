import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";

import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
// import ActivityList from './ActivityList';

import AddItemPage from "./AddBearer/addItem";
import AddOrganiser from "./AddOrganiserTeam/AddOragniser";
import { Axios } from "app/services/config";

function AddAboutUs() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const showAlert = ToastAlerts();
  const [imagePreview, setImagePreview] = useState(
    state?.logo ? `${process.env.REACT_APP_BE_IMG_URL}${state?.logo}` : null
  );
  const formikRef = useRef();
  const [addedBearerList, setAddedBearerList] = useState(
    state?.official_bearers
      ? state?.official_bearers?.map((bearer, index) => ({
          ...bearer,
          item_sr_no: index + 1,
        }))
      : []
  );
  const [addedOrganiserList, setAddedOrganiserList] = useState(
    state?.organising_team
      ? state?.organising_team?.map((bearer, index) => ({
          ...bearer,
          item_sr_no: index + 1,
        }))
      : []
  );
  const [submitting, setSubmitting] = useState(false);
  const [aboutInfo, setAboutInfo] = useState({
    about_event: state?.about_event || "",
    about_pan_iit: state?.about_pan_iit || "",
    logo: null,
    status: state?.status ?? true,
  });
  const initialValues = {
    about_event: state?.about_event || "",
    about_pan_iit: state?.about_pan_iit || "",
    logo_name: "",
    logo: state?.logo || null,
    status: state?.status ?? true,
  };
  const validationSchema = Yup.object({
    about_event: Yup.string("Add About Event").required(
      "About event is required"
    ),
    about_pan_iit: Yup.string("Add About Pan IIT").required(
      "About pan IIT is required"
    ),
    logo: Yup.string("Add Logo").required("Logo is required").nullable(),
  });

  const handleSubmit = async () => {
    setSubmitting(true);
    if (addedBearerList?.length === 0 || addedOrganiserList?.length === 0) {
      Swal.fire({
        text: "Add At least one bearer and one oragniser",
        icon: "warning",
        timer: 2000,
      });
    }
    // const newArr = { ...aboutInfo, official_bearers: addedBearerList, organising_team: addedOrganiserList };
    formikRef.current.setTouched({
      about_event: true,
      about_pan_iit: true,
      logo: true,
    });

    const errors = await formikRef.current.validateForm();

    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("about_event", aboutInfo?.about_event);
    formData.append("about_pan_iit", aboutInfo?.about_pan_iit);
    formData.append("logo", aboutInfo?.logo);
    addedBearerList.forEach((bearer) => {
      formData.append(
        "official_bearers[]",
        JSON.stringify({
          bearer_name: bearer.bearer_name,
          designation: bearer.designation,
        })
      );
      if (bearer.bearer_image) {
        formData.append("bearer_image", bearer.bearer_image);
      }
    });

    // Append organizing team members
    addedOrganiserList?.forEach((organiser) => {
      formData.append(
        "organising_team[]",
        JSON.stringify({
          organiser_name: organiser?.organiser_name,
          designation: organiser.designation,
        })
      );
      if (organiser.organiser_image) {
        formData.append("organiser_image", organiser.organiser_image);
      }
    });
    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/about-us/edit") {
        response = await Axios.post(`/about-us/edit/${state?._id}`, formData);
      } else {
        response = await Axios.post("/about-us/add", formData);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/about-us");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };
  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("logo", file);

      setFieldValue("logo_name", file.name);
      setAboutInfo({ ...aboutInfo, logo: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    let resizeObserverEntries = [];

    const observer = new ResizeObserver((entries) => {
      resizeObserverEntries = entries;
    });

    if (inputRef.current) observer.observe(inputRef.current);

    return () => {
      resizeObserverEntries.forEach((entry) => entry.target.remove());
      observer.disconnect();
    };
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/about-us/add" ? "Add About Us" : "Edit About Us"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formikRef}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={
                        touched?.about_event && Boolean(errors?.about_event)
                      }
                    >
                      <TextareaAutosize
                        // minRows={3}
                        rows={3}
                        style={{
                          width: "100%",
                          padding: "16px",
                          borderRadius: "4px",
                          fontFamily: "inherit",
                          resize: "vertical",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          outline: "none",
                        }}
                        sx={{
                          "& textarea": {
                            minHeight: "10px",
                          },
                        }}
                        value={values?.about_event}
                        // onChange={(e) =>
                        //   setFieldValue("description", e.target.value)
                        // }
                        onChange={(e) => {
                          setAboutInfo({
                            ...aboutInfo,
                            about_event: e.target.value,
                          });
                          setFieldValue("about_event", e.target.value);
                        }}
                        placeholder="About Event*"
                      />

                      {errors?.about_event && errors?.about_event && (
                        <FormHelperText>{errors?.about_event}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={
                        touched?.about_pan_iit && Boolean(errors?.about_pan_iit)
                      }
                    >
                      <TextareaAutosize
                        // minRows={3}
                        rows={3}
                        style={{
                          width: "100%",
                          padding: "16px",
                          borderRadius: "4px",
                          fontFamily: "inherit",
                          resize: "vertical",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          outline: "none",
                        }}
                        value={values?.about_pan_iit}
                        onChange={(e) => {
                          setAboutInfo({
                            ...aboutInfo,
                            about_pan_iit: e.target.value,
                          });
                          setFieldValue("about_pan_iit", e.target.value);
                        }}
                        sx={{
                          "& textarea": {
                            minHeight: "10px",
                          },
                        }}
                        placeholder="About Pan IIT*"
                      />

                      {errors?.about_pan_iit && errors?.about_pan_iit && (
                        <FormHelperText>{errors?.about_pan_iit}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="logo"
                      name="logo"
                      label="Logo*"
                      value={values?.logo_name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button size="small" component="label">
                            <FileUploadOutlined />
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(event) => {
                                handleImageChange(event, setFieldValue);
                              }}
                            />
                          </Button>
                        ),
                        readOnly: true,
                      }}
                      error={touched.logo && Boolean(errors.logo)}
                      helperText={touched.logo && errors.logo}
                    />
                    {imagePreview && (
                      <div className="flex flex-col gap-2 items-start ml-2">
                        <p className="mt-1">Uploaded Logo</p>
                        <img
                          src={imagePreview}
                          alt="Logo Preview"
                          // style={{ width: "150px", height: "150px", objectFit: "contain" }}
                          className="object-contain w-20 "
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <AddItemPage
            addedBearerList={addedBearerList}
            setAddedBearerList={setAddedBearerList}
          />
          <AddOrganiser
            addedOrganiserList={addedOrganiserList}
            setAddedOrganiserList={setAddedOrganiserList}
          />
          <Grid item xs={12} alignContent="center">
            <FormControlLabel
              style={{ padding: "0px", margin: "0px", height: "100%" }}
              control={
                <Switch
                  onChange={(e) => {
                    let status = aboutInfo?.status;
                    setAboutInfo({
                      ...aboutInfo,
                      status: !status,
                    });
                    // setFieldValue("about_pan_iit", e.target.value);
                  }}
                  color="primary"
                  defaultChecked={aboutInfo?.status ? true : false}
                  // color="primary"
                />
              }
              label="Status"
              name="status"
              labelPlacement="start"
            />
          </Grid>

          <Grid container columnSpacing={3} mt={5}>
            <Grid item xs={6} textAlign="right">
              <LoadingButton
                variant="contained"
                size="medium"
                onClick={handleSubmit}
                loading={submitting}
              >
                {pathname === "/about-us/add" ? "Save" : "Update"}
              </LoadingButton>
            </Grid>
            <Grid item xs={6} textAlign="left">
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result?.isConfirmed) {
                      navigate("/about-us");
                    }
                  });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddAboutUs;
