import { Axios } from "app/services/config";
import {
  LIST_LIVE_EVENT_FAILED,
  LIST_LIVE_EVENT_REQUEST,
  LIST_LIVE_EVENT_SUCCESS,
} from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchLiveEventList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LIST_LIVE_EVENT_FAILED));
    dispatch({ type: LIST_LIVE_EVENT_REQUEST });
    let apiUrl = `/live-event/list`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: LIST_LIVE_EVENT_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(LIST_LIVE_EVENT_FAILED, error.message));
  } finally {
    // setLoading(false);
  }
};
