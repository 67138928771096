import Page from "@jumbo/shared/Page";
import AddLinks from "app/pages/Others/Add";
import AddFAQ from "app/pages/Others/FAQ/Add";
import ListFAQ from "app/pages/Others/FAQ/List";
import ListAllLinks from "app/pages/Others/List";
import {
  FAQMiddleware,
  LinksMiddleware,
} from "../Middleware/others/others.middleware";

export const otherLinksRoutes = [
  {
    middleware: [
      {
        element: LinksMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: "/other-links/add",
        element: <Page component={AddLinks} layout={"vertical-default"} />,
      },
      {
        path: "/other-links/edit",
        element: <Page component={AddLinks} layout={"vertical-default"} />,
      },
      {
        path: "/other-links",
        element: <Page component={ListAllLinks} layout={"vertical-default"} />,
      },
    ],
  },
];

export const faqRoutes = [
  {
    middleware: [
      {
        element: FAQMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: "/faq/add",
        element: <Page component={AddFAQ} layout={"vertical-default"} />,
      },
      {
        path: "/faq/edit",
        element: <Page component={AddFAQ} layout={"vertical-default"} />,
      },
      {
        path: "/faq",
        element: <Page component={ListFAQ} layout={"vertical-default"} />,
      },
    ],
  },
];

//not used for now..
// export const TermsAndConditionRoutes = [
//   {
//     path: "/terms-conditions/add",
//     element: (
//       <Page component={AddTermsAndConditions} layout={"vertical-default"} />
//     ),
//   },
//   {
//     path: "/terms-conditions/edit",
//     element: (
//       <Page component={AddTermsAndConditions} layout={"vertical-default"} />
//     ),
//   },
//   {
//     path: "/terms-conditions",
//     element: (
//       <Page component={ListTermsAndConditons} layout={"vertical-default"} />
//     ),
//   },
// ];
// export const socialMediaRoutes = [
//   {
//     path: "/social-media/add",
//     element: <Page component={AddSocialMedia} layout={"vertical-default"} />,
//   },
//   {
//     path: "/social-media/edit",
//     element: <Page component={AddSocialMedia} layout={"vertical-default"} />,
//   },
//   {
//     path: "/social-media",
//     element: <Page component={ListSocialMedia} layout={"vertical-default"} />,
//   },
// ];
