import { Axios } from "app/services/config";
import {
  LIST_GIVEAWAY_TRACKER_FAILED,
  LIST_GIVEAWAY_TRACKER_REQUEST,
  LIST_GIVEAWAY_TRACKER_SUCCESS,
} from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchGiveawayTrackerLogs = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LIST_GIVEAWAY_TRACKER_FAILED));
    dispatch({ type: LIST_GIVEAWAY_TRACKER_REQUEST });

    let apiUrl = `/giveaway/logs`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: LIST_GIVEAWAY_TRACKER_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(
      setError(LIST_GIVEAWAY_TRACKER_FAILED, error?.response?.data?.message)
    );
  }
};
