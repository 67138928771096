import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddLinks() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const showAlert = ToastAlerts();
  const initialValues = {
    privacy_policy_link: state?.privacy_policy_link
      ? state?.privacy_policy_link
      : "",
    terms_and_conditions_link: state?.terms_and_conditions_link
      ? state?.terms_and_conditions_link
      : "",
    instagram_link: state?.instagram_link ? state?.instagram_link : "",
    linkedin_link: state?.linkedin_link ? state?.linkedin_link : "",
    youtube_link: state?.youtube_link ? state?.youtube_link : "",
    twitter_link: state?.twitter_link ? state?.twitter_link : "",
    facebook_link: state?.facebook_link ? state?.facebook_link : "",
    status: state?.status ?? true,
  };
  const validationSchema = yup.object({
    privacy_policy_link: yup
      .string("Enter Privacy Policy Link")
      .required("Privacy Policy Link is required"),
    terms_and_conditions_link: yup
      .string("Enter Terms and Conditions Link")
      .required("Terms and Conditions link is required"),
    instagram_link: yup
      .string("Enter  instagram link")
      .required("Instagram Link is required"),
    linkedin_link: yup
      .string("Enter linkedin link")
      .required("linkedin link is required"),
    youtube_link: yup
      .string("Enter Youtube Link")
      .required("Youtube Link is required"),
    facebook_link: yup
      .string("Enter Facebook Link")
      .required("Facebook Link is required"),
    twitter_link: yup
      .string("Enter Twitter Link")
      .required("Twitter Link is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/other-links/edit") {
        response = await Axios.post(`/others/edit/${state?._id}`, values);
      } else {
        response = await Axios.post("/others/add", values);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/other-links");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/other-links/add" ? "Add Links" : "Edit Links"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="privacy_policy_link"
                      name="privacy_policy_link"
                      label="Privacy Policy Link*"
                      value={values?.privacy_policy_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="terms_and_conditions_link"
                      name="terms_and_conditions_link"
                      label="Terms and Conditions Link*"
                      value={values?.terms_and_conditions_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="instagram_link"
                      name="instagram_link"
                      label="Instagram Link*"
                      value={values?.instagram_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="youtube_link"
                      name="youtube_link"
                      label="Youtube Link*"
                      value={values?.youtube_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="linkedin_link"
                      name="linkedin_link"
                      label="Linkedin Link*"
                      value={values?.linkedin_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="facebook_link"
                      name="facebook_link"
                      label="Facebook Link*"
                      value={values?.facebook_link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="twitter_link"
                      name="twitter_link"
                      label="Twitter Link*"
                      value={values?.twitter_link}
                    />
                  </Grid>

                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {pathname === "/other-links/add" ? "Save" : "Update"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/other-links");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddLinks;
