import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomTable from "app/components/Table";
import { useSelector } from "react-redux";
import ToastAlerts from "app/components/Toast";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";
import SearchGlobal from "app/shared/SearchGlobal";

import { RemoveRedEye } from "@mui/icons-material";

import { fetchAllFeedbackList } from "app/redux/actions/Feedback/RatingReview";
import Documents1 from "app/components/Documents1";
import { Axios } from "app/services/config";
export default function ListAllFeedbacks() {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [query, setQuery] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [surveyList, setSurveyList] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { feedbackList, totalPage, error, loading } = useSelector(
    (state) => state.ratingAndReviewReducer
  );
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  const columns = [
    {
      field: "guestDetails.first_name",
      headerName: "First Name",
      sortable: true,
      render: (_, elm) => {
        return elm?.guestDetails?.first_name;
      },
    },
    {
      field: "guestDetails.last_name",
      headerName: "Last Name",
      sortable: true,
      render: (_, elm) => {
        return elm?.guestDetails?.last_name;
      },
    },
    {
      field: "surveyDetails.survey_name",
      headerName: "Survey Name",
      sortable: true,
      // isImage: true
      render: (_, elm) => {
        return elm?.surveyDetails?.survey_name;
      },
    },
    {
      field: "surveyDetails.event_name",
      headerName: "Event Name",
      sortable: true,
      render: (_, elm) => {
        return elm?.surveyDetails?.event_name;
      },
      // isImage: true
    },
    {
      field: "roomDetails.room_no",
      headerName: "Room",
      sortable: true,
      render: (_, elm) => {
        return elm?.roomDetails?.room_no || "-";
      },
    },
    {
      field: "sessionDetails.room_no",
      headerName: "Session",
      sortable: true,
      render: (_, elm) => {
        return elm?.sessionDetails?.session_name || "-";
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      sortable: true,
      isDate: true,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated Date",
    //   sortable: true,
    //   isDate: true,
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   sortable: true,
    //   render: (value, elm) =>
    //     value ? (
    //       <Button size="small" variant="outlined" color="success">
    //         Active
    //       </Button>
    //     ) : (
    //       <Button size="small" variant="outlined" color="error">
    //         Inactive
    //       </Button>
    //     ),
    //   onClick: async (elm) => {
    //     try {
    //       let status = elm.status;
    //       const result = await Swal.fire({
    //         title: `Change  status to ${status ? "inactive" : "active"} ?`,
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "Yes",
    //         cancelButtonText: "No",
    //       });
    //       // if (result.isConfirmed) {
    //       //     await Axios.patch(`/guest/edit/${elm._id}`, { status: !status });
    //       //     showAlert("success", "Guest status updated successfully.");
    //       //     navigate("/guest");
    //       //     dispatch(onGuestList(query));
    //       // }
    //     } catch (error) {
    //       console.error("Error updating guest:", error);
    //       showAlert("error", "Failed to Update Status.");
    //     }
    //   },
    // },
  ];
  const actions = [
    {
      label: "View Feedback",
      color: "secondary",
      icon: <RemoveRedEye />,
      onClick: (row) => {
        navigate("/view-feedback", { state: row });
      },
    },
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    // GetAttendedCount();
    dispatch(fetchAllFeedbackList(query));
  }, [query]);

  const FeedbackExportCsv = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/report/feedbackCsv`;
      if (query) {
        const queryParams = new URLSearchParams(query);
        apiUrl =
          apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
      }
      const response = await Axios.get(apiUrl);
      if (response?.data?.status) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async function () {
      try {
        const res = await Axios.get("/feedback/dropdown-survey");
        setSurveyList(res?.data?.data);
      } catch (error) {
        console.log("err fetching dropdown survey name => ", error);
      }
    })();
  }, []);
  const handleFilter = () => {
    setQuery({ ...query, survey_name: selectedSurvey });
  };

  const handleClearFilter = () => {
    setSelectedSurvey("");
    setQuery({ ...query, surver_name: "" });
  };
  return (
    <>
      {(loading || loader || isLoading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Typography variant="h1">Feedback Logs </Typography>
          <Grid item maxWidth={250} mt={2}>
            <Documents1
              // icone={<PeopleAltIcon sx={{ fontSize: 36 }} />}
              field="Total Feedbacks"
              data={totalPage}
            />
          </Grid>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "35px",
                width: "80%",
              }}
            >
              <SearchGlobal
                sx={{
                  mb: 5,
                  mt: 4,
                  maxWidth: { xs: 240, md: 320 },
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <Autocomplete
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={surveyList}
                  getOptionLabel={(option) => option}
                  value={selectedSurvey}
                  onChange={(e, newValue) => {
                    setSelectedSurvey(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Survey" />
                  )}
                />

                <Div sx={{ display: "flex", gap: 1, flex: "1", ml: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ height: "35px" }}
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ height: "35px" }}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                </Div>
              </Div>
            </Div>

            <Div
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: "15px",
                width: "40%",
              }}
            >
              {permissions?.feedback?.view && (
                <Button
                  size="small"
                  disabled={feedbackList?.length === 0}
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={async () => {
                    FeedbackExportCsv();
                  }}
                >
                  export csv
                </Button>
              )}
              {/* <Button
                size="small"
                variant="contained"
                sx={{ p: 1, pl: 4, pr: 4 }}
                onClick={() => navigate("//add")}
              >
                Add Survey
              </Button> */}
            </Div>
          </Div>
        </Div>

        <Div>
          <CustomTable
            data={feedbackList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPage}
          />
        </Div>
        {/* {openView && surveyDetails && (
          <ViewQuestions
            openView={openView}
            setOpenView={setOpenView}
            data={surveyDetails}
          />
        )} */}
      </Div>
    </>
  );
}
