import Div from "@jumbo/shared/Div/Div";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Axios } from "app/services/config";

// import { addRole } from "app/services/apis/roleApis";

function AddRoles() {
  const { state, pathname } = useLocation();

  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const headingStyle = {
    minWidth: "300px",
    fontSize: "1rem",
    fontWeight: "500",
  };
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 4,
    width: "100px",
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectAll, setSelectAll] = useState({
    edit: false,
    view: false,
    create: false,
  });

  const [check, setCheck] = useState({
    user: {
      view: false,
      edit: false,
      create: false,
    },
    role: {
      view: false,
      edit: false,
      create: false,
    },
    guest: {
      view: false,
      edit: false,
      create: false,
    },
    room: {
      view: false,
      edit: false,
      create: false,
    },
    session: {
      view: false,
      edit: false,
      create: false,
    },
    banner: {
      view: false,
      edit: false,
      create: false,
    },
    sponsors: {
      view: false,
      edit: false,
      create: false,
    },
    partners: {
      view: false,
      edit: false,
      create: false,
    },
    speakers: {
      view: false,
      edit: false,
      create: false,
    },
    about_us: {
      view: false,
      edit: false,
      create: false,
    },
    roadshow: {
      view: false,
      edit: false,
      create: false,
    },
    floor_plan: {
      view: false,
      edit: false,
      create: false,
    },
    agenda: {
      view: false,
      edit: false,
      create: false,
    },
    live_event: {
      view: false,
      edit: false,
      create: false,
    },
    delegate_kit: {
      view: false,
      edit: false,
      create: false,
    },
    giveaway_tracker: {
      view: false,
      edit: false,
      create: false,
    },
    question: {
      view: false,
      edit: false,
      create: false,
    },
    survey: {
      view: false,
      edit: false,
      create: false,
    },
    feedback: {
      view: false,
      edit: false,
      create: false,
    },
    links: {
      view: false,
      edit: false,
      create: false,
    },
    faq: {
      view: false,
      edit: false,
      create: false,
    },
    logs: {
      view: false,
      edit: false,
      create: false,
    },
    stall: {
      view: false,
      edit: false,
      create: false,
    },
    // face_inventory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
    // core_inventory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
    // fleece_paper_inventory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
    // other_goods_inventory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
    // crosscut_factory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
    // flitching_factory: {
    //   view: false,
    //   edit: false,
    //   create: false,
    // },
  });

  const initialValues = {
    role_name: state?.role_name || "",
    remarks: state?.remarks || "",
    status: state?.status ?? true,
  };

  const validationSchema = yup.object({
    role_name: yup.string().required("Role Name is required"),
    remarks: yup.string("Enter Remarks"),
  });

  const handleAllView = (e) => {
    const isChecked = e.target.checked;
    setSelectAll((prev) => ({ ...prev, view: isChecked }));

    setCheck((prevCheck) => {
      const updatedCheck = {};
      Object.entries(prevCheck).forEach(([category, permissions]) => {
        updatedCheck[category] = { ...permissions, view: isChecked };
      });
      return updatedCheck;
    });
  };

  const handleAllEdit = (e) => {
    const isChecked = e.target.checked;
    setSelectAll((prev) => ({ ...prev, edit: isChecked }));

    setCheck((prevCheck) => {
      const updatedCheck = {};
      Object.entries(prevCheck).forEach(([category, permissions]) => {
        updatedCheck[category] = { ...permissions, edit: isChecked };
      });
      return updatedCheck;
    });
  };

  const handleAllCreate = (e) => {
    const isChecked = e.target.checked;
    setSelectAll((prev) => ({ ...prev, create: isChecked }));

    setCheck((prevCheck) => {
      const updatedCheck = {};
      Object.entries(prevCheck).forEach(([category, permissions]) => {
        updatedCheck[category] = { ...permissions, create: isChecked };
      });
      return updatedCheck;
    });
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);

    const details = {
      ...values,
      permissions: check,
    };

    try {
      let response;

      if (pathname === "/roles/add") {
        response = await Axios.post("/roles/add", details);
      } else {
        response = await Axios.post(`/roles/edit/${state?._id}`, details);
      }

      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: response?.data?.message,
          timer: 1000,
          showConfirmButton: false,
        });
        setSubmitting(false);
        navigate("/roles");
      }
    } catch (error) {
      setSubmitting(false);
      Swal.fire({
        icon: "error",
        // title: data.message,
        text: error?.response?.data?.message,
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    // Function to check permissions only for roles where 'view' is true
    const checkAllPermissions = (permissionType) => {
      return Object.entries(check).every(
        ([_, permissions]) => permissions[permissionType] === true
      );
    };

    // Update selectAll for 'edit' and 'create' only if 'view' is true
    setSelectAll((prev) => ({
      ...prev,
      view: checkAllPermissions("view"),
      edit: checkAllPermissions("edit"),
      create: checkAllPermissions("create"),
    }));
  }, [check]);

  useEffect(() => {
    if (state?.permissions) {
      setCheck((prevCheck) => {
        const updatedCheck = { ...prevCheck };
        Object.keys(state.permissions).forEach((module) => {
          if (updatedCheck[module]) {
            updatedCheck[module] = { ...state.permissions[module] };
          }
        });
        return updatedCheck;
      });
    }
  }, [state]);

  const handlePermissionChange = (module, permissionType) => (e) => {
    setCheck((prevCheck) => ({
      ...prevCheck,
      [module]: { ...prevCheck[module], [permissionType]: e.target.checked },
    }));
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h2">
        {pathname === "/roles/add" ? "Add Roles" : "Edit Roles"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleBlur,
            setFieldTouched,
            isSubmitting,
          }) => (
            <Form noValidate>
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6} md={4} lg={3} xl={6}>
                    <Typography variant="h6" fontSize="14px">
                      Role Name*
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.role_name}
                      sx={{ width: "100%" }}
                      name="role_name"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) =>
                        setFieldValue("role_name", e.target.value)
                      }
                      error={touched?.role_name && errors?.role_name}
                      helperText={touched?.role_name && errors?.role_name}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={6}>
                    <Typography variant="h6" fontSize="14px">
                      Remark
                    </Typography>
                    <TextField
                      size="small"
                      value={values?.remarks}
                      sx={{ width: "100%" }}
                      name="remarks"
                      onBlur={async (e) => {
                        handleBlur(e);
                      }}
                      onChange={(e) => setFieldValue("remarks", e.target.value)}
                      error={touched?.remarks && errors?.remarks}
                      helperText={touched?.remarks && errors?.remarks}
                    />
                  </Grid>
                </Grid>
              </Div>
              <Grid
                item
                xs={12}
                justifyContent="flex-end" // Aligns content to the right
                display="flex" //
              >
                <FormControlLabel
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "100%",
                    marginTop: 2,
                  }}
                  control={
                    <Switch
                      onChange={(e) => {
                        setFieldValue("status", values?.status ? false : true);
                      }}
                      defaultChecked={values?.status ? true : false}
                      color="primary"
                      sx={{ float: "right" }}
                    />
                  }
                  label="Status"
                  name="status"
                  labelPlacement="start"
                />
              </Grid>

              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                <Typography sx={headingStyle}>Select</Typography>
                <Div sx={checkboxStyle}>
                  <GreenCheckbox
                    checked={selectAll.view}
                    onChange={handleAllView}
                  />
                  <Typography>View</Typography>
                </Div>
                <Div sx={checkboxStyle}>
                  <GreenCheckbox
                    checked={selectAll.edit}
                    onChange={handleAllEdit}
                  />
                  <Typography>Edit</Typography>
                </Div>
                <Div sx={checkboxStyle}>
                  <GreenCheckbox
                    checked={selectAll.create}
                    onChange={handleAllCreate}
                  />
                  <Typography>Create</Typography>
                </Div>
              </Div>

              {Object.entries(check)?.map(([module, permissions]) => (
                <Div
                  key={module}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={headingStyle}>
                    {module?.replace("_", " ")?.toUpperCase()}
                  </Typography>
                  {Object.entries(permissions)?.map(([perm, value]) => (
                    <Div key={perm} sx={checkboxStyle}>
                      <GreenCheckbox
                        checked={value}
                        onChange={handlePermissionChange(module, perm)}
                      />
                      <Typography>
                        {perm.charAt(0).toUpperCase() + perm.slice(1)}
                      </Typography>
                    </Div>
                  ))}
                </Div>
              ))}

              {/* 
              <Div style={{ backgroundColor: "#EAE5F7", textAlign: "center" }}>
                <Typography
                  sx={{ color: "#7352C7", padding: "5px 0", fontWeight: "600" }}
                  variant="h3"
                >
                  MASTER
                </Typography>
              </Div> */}

              {/* )} */}
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 3,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/roles");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ width: "100px" }}
                  loading={isSubmitting}
                >
                  {pathname === "/roles/add" ? "Add" : "Update"}
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

export default AddRoles;
