import AddGiveAwayItems from "app/pages/Giveaway/Add";
import GiveAwayTrackerLogs from "app/pages/Giveaway/GiveAwayTracker";
import ListGiveAwayItems from "app/pages/Giveaway/List";
import {
  DelegateKitMiddleware,
  GiveAwayTrackerMiddleware,
} from "../Middleware/delegateKit/delegate.kit.middleware";

const { default: Page } = require("@jumbo/shared/Page");

const routesName = "/giveaway";
export const giveawayRoutes = [
  {
    middleware: [{ element: DelegateKitMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: `${routesName}`,
        element: (
          <Page component={ListGiveAwayItems} layout={"vertical-default"} />
        ),
      },
      {
        path: `${routesName}/add`,
        element: (
          <Page component={AddGiveAwayItems} layout={"vertical-default"} />
        ),
      },
      {
        path: `${routesName}/edit`,
        element: (
          <Page component={AddGiveAwayItems} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
// const routes = [
//   {
//     path: `${routesName}`,
//     element: <Page component={ListGiveAwayItems} layout={"vertical-default"} />,
//   },
//   {
//     path: `${routesName}/add`,
//     element: <Page component={AddGiveAwayItems} layout={"vertical-default"} />,
//   },
//   {
//     path: `${routesName}/edit`,
//     element: <Page component={AddGiveAwayItems} layout={"vertical-default"} />,
//   },
// ];

// export const giveawayRoutes = routes;

export const giveAwayTrackerRoute = [
  {
    middleware: [{ element: GiveAwayTrackerMiddleware, fallbackPath: "/" }],
    routes: [
      {
        path: `/giveaway-tracker`,
        element: (
          <Page component={GiveAwayTrackerLogs} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
