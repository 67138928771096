import { Axios } from "app/services/config";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
} from "@mui/material";
import Documents1 from "app/components/Documents1";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import ToastAlerts from "app/components/Toast";
function MappedEventsGiftsPage() {
    const { id } = useParams();

    const showAlerts = ToastAlerts();
    const [questionByEvent, setQuestionByEvent] = useState([]);
    const [toggle, setToggle] = useState(false);
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);
    const [count, setCount] = useState("");
    const [sortOrder, setSortOrder] = useState("asec");
    const [sortField, setSortField] = useState("");

    useEffect(() => {
        const fetchQuestionsByEvent = async () => {
            try {
                const res = await Axios.get(`gift/gifts-by-event/${id}`, {
                    params: {
                        page: page + 1,
                        limit: rowsPerPage,
                        sortOrder,
                        sortField,
                    },
                });

                if (res.data.statusCode === 200) {
                    setQuestionByEvent(res.data?.data?.allEvents);
                    setCount(res.data?.data?.totalCount);
                } else {
                    showAlerts("error", res.data.message);
                }
            } catch (error) {
                showAlerts("error", error.message);
            }
        };
        fetchQuestionsByEvent();
    }, [id, toggle, page, sortField, sortOrder]);

    const handleDeleteQuestions = async (eventId) => {
        try {
            const res = await Axios.post(`gift/remove-event/${id}?event=${eventId}`);
            if (res.data.statusCode === 200) {
                setToggle(true);
                showAlerts("success", res.data.message);
            }
        } catch (error) {
            showAlerts("error", error.message);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleSortRequest = (field) => {
        const isAsc = sortField === field && sortOrder === "asec";
        setSortOrder(isAsc ? "desc" : "asec");
        setSortField(field);
    };

    return (
        <div className="flex flex-col items-center gap-8">
            <div className="self-start">
                <Documents1 field="Gift Name" data={questionByEvent[0]?.giftName} />
            </div>
            <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell> Sr.No</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === "giftName"}
                                        direction={sortOrder}
                                        onClick={() => handleSortRequest("giftName")}
                                    >
                                        Gift Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === "allEvents.room_no"}
                                        direction={sortOrder}
                                        onClick={() => handleSortRequest("allEvents.room_no")}
                                    >
                                        Event Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell> Remove Event</TableCell>
                            </TableRow>
                        </TableHead>

                        {questionByEvent?.length > 0 ? (
                            <TableBody>
                                {questionByEvent?.map((row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{row.giftName}</TableCell>
                                        <TableCell> {row.allEvents?.room_no}</TableCell>

                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    handleDeleteQuestions(row?.allEvents?._id)
                                                }
                                            >
                                                <DeleteOutlineOutlined className="text-lg font-semibold text-red-500 hover:scale-105 transition-all cursor-pointer" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        className="text-center font-semibold "
                                        variant="head"
                                        colSpan={4}
                                    >
                                        No Data Found....
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {count && (
                    <TablePagination
                        rowsPerPageOptions={""}
                        component={"div"}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                    />
                )}
            </Paper>
        </div>
    );
}

export default MappedEventsGiftsPage;
