import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
// import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { getCurrentDate } from "app/utils/constants/constants";
import { Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddRoadhows() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const showAlert = ToastAlerts();
  const initialValues = {
    topic_name: state?.topic_name ? state?.topic_name : "",
    link: state?.link ? state?.link : "",
    description: state?.description ? state?.description : "",
    location: state?.location ? state?.location : "",
    roadshow_date: state?.roadshow_date
      ? new Date(state?.roadshow_date).toISOString().split("T")[0]
      : getCurrentDate(),
    end_time: state?.end_time ? state?.end_time : "",
    start_time: state?.start_time ? state?.start_time : "",
    status: state?.status ?? true,
  };
  const validationSchema = yup.object({
    topic_name: yup
      .string("Topic Name is required")
      .required("Topic name is required"),
    link: yup
      .string("Enter Roadshow Link")
      .required("Roadshow link is required"),
    description: yup
      .string("Enter your Description")
      .required("Description is required"),
    location: yup.string("Enter Location").required("Location is required"),
    roadshow_date: yup
      .string("Enter Roadshow Date")
      // .matches(/^\d{4}$/, "Roadshow Year must be a 4-digit year (e.g., 2024)")
      .required("Roadshow Date is required"),
    start_time: yup
      .string("Enter Start Time")
      .required("Start Time is required"),
    end_time: yup.string("Enter End Time").required("End Time is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/roadshow/edit") {
        response = await Axios.post(
          `/roadshow/edit-roadshow/${state?._id}`,
          values
        );
      } else {
        response = await Axios.post("/roadshow/add-roadshow", values);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/roadshow");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/roadshow/add" ? "Add Roadshow" : "Edit Roadshow"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="topic_name"
                      name="topic_name"
                      label="Topic Name*"
                      value={values?.topic_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="link"
                      name="link"
                      label="Link*"
                      value={values?.link}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JumboTextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Description*"
                      value={values?.description}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="roadshow_date"
                      name="roadshow_date"
                      label="Roadshow Date*"
                      value={values?.roadshow_date}
                      type="Date"
                      onKeyDown={(e) => e.preventDefault()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="location"
                      name="location"
                      label="Location*"
                      value={values?.location}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="start_time"
                      name="start_time"
                      label="Start Time*"
                      value={values?.start_time}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="end_time"
                      name="end_time"
                      label="End Time*"
                      value={values?.end_time}
                    />
                  </Grid>

                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {pathname === "/roadshow/add" ? "Save" : "Update"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/roadshow");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddRoadhows;
