import Div from "@jumbo/shared/Div/Div";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { Button, Typography } from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import CustomTable from "app/components/Table";
import ToastAlerts from "app/components/Toast";
import { onRoomList } from "app/redux/actions/Room";
import { Axios } from "app/services/config";
import SearchGlobal from "app/shared/SearchGlobal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AddRoom from "../AddRoom";
import { Today } from "@mui/icons-material";

export default function ListRoom() {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const [openView, setOpenView] = useState(false);
  const [query, setQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { roomList, totalPages, error, loading } = useSelector(
    (state) => state.roomReducer
  );
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );
  const roomExportCsv = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/report/roomListCsv`;
      const response = await Axios.get(apiUrl);

      if (response?.data?.status === true) {
        showAlert("success", "Downloaded successfully.");
        window.open(response?.data?.data);
      }
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const columns = [
    { field: "room_no", headerName: "Room Name", sortable: true },
    { field: "floorDetails.floor_name", headerName: "Floor Name", sortable: true, render: (_, elm) => elm?.floorDetails?.floor_name || "-" },
    {
      field: "assigned_user.first_name",
      headerName: "Assigned User",
      sortable: false,
      width: 300,
      render: (_, elm) =>
        elm?.assigned_user.length
          ? elm?.assigned_user?.map(
            (item) => `${item.first_name} ${item.last_name}, `
          )
          : "--",
    },

    // { field: "room_no", headerName: "", sortable: true },
    { field: "session_count", headerName: "Total Session", sortable: false },
    // { field: "active_user_count", headerName: "Active Guest", sortable: false },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      render: (value, elm) =>
        value ? (
          <Button size="small" variant="outlined" color="success">
            Active
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error">
            Inactive
          </Button>
        ),
      onClick: async (elm) => {
        try {
          let status = elm.status;
          const result = await Swal.fire({
            title: `Change room status to ${status ? "inactive" : "active"} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          });
          if (result.isConfirmed) {
            await Axios.patch(`/room/edit?id=${elm._id}`, { status: !status });
            showAlert("success", "Room status updated successfully.");
            navigate("/rooms");
            dispatch(onRoomList(query));
          }
        } catch (error) {
          console.error("Error updating room:", error);
          showAlert("error", "Failed to update room.");
        }
      },
    },
  ];

  const actions = [
    ...(permissions?.session?.view
      ? [
        {
          label: "View Room",
          color: "secondary",
          onClick: (row) =>
            navigate(`/rooms/view/${row._id}`, { state: row }),
          icon: <PreviewOutlinedIcon />,
        },
      ]
      : []),

    ...(permissions?.room?.edit
      ? [
        {
          label: "Edit Room",
          color: "secondary",
          onClick: (row) => {
            setSelectedRoomId(row?._id);
            setOpenView(true);
          },
          icon: <ModeEditOutlinedIcon />,
        },
      ]
      : []),

    {
      label: "Add Agenda",
      color: "secondary",
      onClick: (row) => {
        navigate(`/agenda/add`);
      },
      icon: <Today />,
    },
  ];

  const fetchData = (props) => {
    setQuery({ ...query, ...props });
  };

  useEffect(() => {
    setQuery({ ...query, search: searchTerm });
  }, [searchTerm]);

  if (error) {
    showAlert("error", error);
  }

  useEffect(() => {
    if (!openView) {
      dispatch(onRoomList(query));
    }
  }, [query, openView]);

  return (
    <>
      {(isLoading || loading) && <FullScreenLoader />}
      <Div
        sx={{
          mt: -4,
          maxHeight: "89vh",
          overflowY: "scroll",
          paddingRight: "10px",
        }}
      >
        <Div
          sx={{
            position: "sticky",
            top: 0,
            background: "#F5F7FA",
            zIndex: 10,
          }}
        >
          <Typography variant="h1">Rooms Master</Typography>
          <Div
            sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
          ></Div>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <TextField
            id="search"
            label="Search"
            value={searchTerm}
            size="small"
            autoComplete="off"
            type="search"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          /> */}
            <SearchGlobal
              sx={{
                mb: 5,
                mt: 4,
                maxWidth: { xs: 240, md: 320 },
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Div sx={{ display: "flex", columnGap: 2 }}>
              {permissions?.room?.view && (
                <Button
                  size="small"
                  variant="contained"
                  disabled={roomList?.length === 0}
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={async () => {
                    roomExportCsv();
                  }}
                >
                  export csv
                </Button>
              )}
              {permissions?.room?.create && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ p: 1, pl: 4, pr: 4 }}
                  onClick={() => {
                    setSelectedRoomId(null);
                    setOpenView(true);
                  }}
                >
                  Add Room
                </Button>
              )}
            </Div>
          </Div>
        </Div>
        <Div>
          <CustomTable
            data={roomList}
            columns={columns}
            actions={actions}
            fetchData={fetchData}
            totalCount={totalPages}
          />
        </Div>
        {openView && (
          <AddRoom
            openView={openView}
            setOpenView={setOpenView}
            roomId={selectedRoomId}
          />
        )}
      </Div>
    </>
  );
}
