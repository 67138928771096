import React from "react";
import Page from "@jumbo/shared/Page";
import { usersRoute } from "./User";
import NotFound from "app/pages/NotFound";
import Login from "app/pages/Auth/Login";
import Dashboard from "app/pages/Dashboard";
import EmailVerify from "app/pages/Auth/EmailVerify";
import UserProfile from "app/pages/Auth/Profile";
import EditProfile from "app/pages/Auth/EditProfile";
import ChangePassword from "app/pages/Auth/ChangePassword";
import AuthMiddleware from "./Middleware/auth";
import { guestRoute } from "./Guest";
import { roomsRoute } from "./Rooms";
import { logsRoute } from "./Logs";
import ResetPassword from "app/pages/Auth/OtpVerify";
import { feedbackLogRoutes, feedBackRoutes, surveyRoutes } from "./Feedback";
import { platesRoutes } from "./Plates";
import { giftsRoutes } from "./Gifts";
import { giveawayRoutes, giveAwayTrackerRoute } from "./Giveaway";
import {
  aboutUsRoutes,
  agendaRoutes,
  eventManagementRoutes,
  floorPlanRoutes,
  homeBannerRoutes,
  liveEventRoutes,
  partnerRoutes,
  roadshowRoutes,
  speakerRoutes,
  sponsorRoutes,
  stallRoutes,
} from "./EventManagement";
import ListHomeBanner from "app/pages/EventManagement/HomeBanner/List";
import { faqRoutes, otherLinksRoutes } from "./Others";
import { rolesAndPermissionsRoute } from "./RolesAndPermissions";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "*",
    element: <Page component={NotFound} layout="solo-page" />,
  },
  {
    path: "/",
    element: <Page component={Dashboard} layout={"vertical-default"} />,
  },
  {
    path: "/profile",
    element: <Page component={UserProfile} layout={"vertical-default"} />,
  },
  {
    path: "/profile/edit",
    element: <Page component={EditProfile} layout={"vertical-default"} />,
  },
  {
    path: "/profile/changePassword",
    element: <Page component={ChangePassword} layout={"vertical-default"} />,
  },
  ...usersRoute,
  ...guestRoute,
  ...roomsRoute,
  ...logsRoute,
  ...feedBackRoutes,
  ...feedbackLogRoutes,
  ...surveyRoutes,
  ...platesRoutes,
  ...giftsRoutes,
  // {
  //   path: "/event/banner",
  //   element: <Page component={ListHomeBanner} layout={"vertical-default"} />,
  // },
  // ...eventManagementRoutes
  ...homeBannerRoutes,
  ...sponsorRoutes,
  ...partnerRoutes,
  ...speakerRoutes,
  ...roadshowRoutes,
  ...floorPlanRoutes,
  ...agendaRoutes,
  ...aboutUsRoutes,
  ...liveEventRoutes,
  //giveaway routes
  ...giveawayRoutes,
  ...giveAwayTrackerRoute,
  //other links routes
  ...otherLinksRoutes,
  ...faqRoutes,
  ...stallRoutes
];

const routesForAuthenticatedOnly = [
  ...rolesAndPermissionsRoute,
  // {
  //   middleware: [
  //     {
  //       element: AuthMiddleware,
  //       fallbackPath: "/login",
  //     },
  //   ],
  //   routes: [
  //     {
  //       path: "/",
  //       element: <Page component={Dashboard} layout={"vertical-default"} />,
  //     },
  //     {
  //       path: "/profile",
  //       element: <Page component={UserProfile} layout={"vertical-default"} />,
  //     },
  //     {
  //       path: "/profile/edit",
  //       element: <Page component={EditProfile} layout={"vertical-default"} />,
  //     },
  //     {
  //       path: "/profile/changePassword",
  //       element: <Page component={ChangePassword} layout={"vertical-default"} />,
  //     },
  //     ...usersRoute,
  //     ...guestRoute,
  //     ...roomsRoute,
  //     ...logsRoute
  //   ],
  // },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/login",
    element: <Page component={Login} layout="solo-page" />,
  },
  {
    path: "/email-verify",
    element: <Page component={EmailVerify} layout="solo-page" />,
  },
  {
    path: "/reset-password",
    element: <Page component={ResetPassword} layout="solo-page" />,
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
