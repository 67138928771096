export const iitList = [
  "IIT BOMBAY",
  "IIT DELHI",
  "IIT MADRAS",
  "IIT KANPUR",
  "IIT KHARAGPUR",
  "IIT ROORKEE",
  "IIT GUWAHATI",
  "IIT BHUBANESWAR",
  "IIT GANDHINAGAR",
  "IIT HYDERABAD",
  "IIT JODHPUR",
  "IIT PATNA",
  "IIT ROPAR",
  "IIT INDORE",
  "IIT (ISM) DHANBAD",
  "IIT BHILAI",
  "IIT GOA",
  "IIT JAMMU",
  "IIT TIRUPATI",
  "IIT PALAKKAD",
  "IIT VARANASI (BHU)",
  "IIT MANDI",
  "IIT DHARWAD",
];
