import ListAllFeedbacks from "app/pages/Feedback/FeedbackList";
import ViewFeedBackPage from "app/pages/Feedback/FeedbackList/ViewFeedBack/ViewFeedBackPage";
import ListAllQuestion from "app/pages/Feedback/Questions/List";
import AddSurvey from "app/pages/Feedback/Survey/Add";
import ListSurvey from "app/pages/Feedback/Survey/List";
import {
  FeedbackMiddleware,
  QuestionMiddleware,
  SurveyMiddleware,
} from "../Middleware/survey/survey.middleware";

const { default: Page } = require("@jumbo/shared/Page");
const {
  default: CreateQuestions,
} = require("app/pages/Feedback/Questions/Add/CreateQuestions");

const questionRouterName = "/questions";
const surveyRouterName = "/survey";

export const feedBackRoutes = [
  {
    middleware: [
      {
        element: QuestionMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: `${questionRouterName}`,
        element: (
          <Page component={ListAllQuestion} layout={"vertical-default"} />
        ),
      },
      {
        path: `${questionRouterName}/add`,
        element: (
          <Page component={CreateQuestions} layout={"vertical-default"} />
        ),
      },
      {
        path: `/${questionRouterName}/edit`,
        element: (
          <Page component={CreateQuestions} layout={"vertical-default"} />
        ),
      },
    ],
  },
];

export const surveyRoutes = [
  {
    middleware: [
      {
        element: SurveyMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: `${surveyRouterName}`,
        element: <Page component={ListSurvey} layout={"vertical-default"} />,
      },
      {
        path: `${surveyRouterName}/add`,
        element: <Page component={AddSurvey} layout={"vertical-default"} />,
      },
      {
        path: `/${surveyRouterName}/edit`,
        element: <Page component={AddSurvey} layout={"vertical-default"} />,
      },
    ],
  },
];

export const feedbackLogRoutes = [
  {
    middleware: [
      {
        element: FeedbackMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: `/feedback`,
        element: (
          <Page component={ListAllFeedbacks} layout={"vertical-default"} />
        ),
      },
      {
        path: `/view-feedback`,
        element: (
          <Page component={ViewFeedBackPage} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
// export const feedBackRoutes = routes;
// export const surveyRoutes = surveyRoute;
// export const feedbackLogRoutes = feedBackLogRoute;
