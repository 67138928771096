import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

// Modal style
const modalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const FAQModal = ({
  open,
  handleClose,
  initialValues,
  onSubmit,
  submitting,
}) => {
  const validationSchema = Yup.object({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: initialValues ? initialValues : { question: "", answer: "" },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
      handleClose();
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <div className="flex flex-row items-center justify-between mb-4">
          <Typography variant="h4" component="h2">
            {initialValues ? "Edit FAQ" : "Add FAQ"}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={3} spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Question*"
                name="question"
                value={formik?.values?.question}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.question && Boolean(formik?.errors?.question)
                }
                helperText={
                  formik?.touched?.question && formik?.errors?.question
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Answer*"
                name="answer"
                value={formik?.values?.answer}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.answer && Boolean(formik?.errors?.answer)
                }
                helperText={formik?.touched?.answer && formik?.errors?.answer}
                // multiline
                // rows={2}
              />
            </Grid>
          </Grid>

          <div className="flex items-center justify-center mt-4">
            <LoadingButton
              loading={submitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              {initialValues ? "Update" : "Add"}
            </LoadingButton>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default FAQModal;
