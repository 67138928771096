import { Axios } from "app/services/config";
import { LIST_PARTNERS_FAILED, LIST_PARTNERS_REQUEST, LIST_PARTNERS_SUCCESS } from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchPartnersList = (query) => async (dispatch) => {
    try {
        dispatch(clearError(LIST_PARTNERS_FAILED));

        let apiUrl = `/partners/list-partners`;
        if (query) {
            const queryParams = new URLSearchParams(query);
            apiUrl =
                apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
        }
        const { data } = await Axios.get(apiUrl);

        dispatch({ type: LIST_PARTNERS_SUCCESS, payload: data });
    } catch (error) {
        // setLoading(false);
        dispatch(setError(LIST_PARTNERS_FAILED, error.message));
    } finally {
        // setLoading(false);
    }
};
