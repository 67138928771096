import { Card, CardContent, Grid, Rating } from "@mui/material";
import React from "react";

function ViewComponent({ data }) {
  return (
    <>
      <Card>
        <CardContent>
          <Grid item xs={12} md={6}>
            <div className="flex flex-col items-start gap-4">
              <p className="flex flex-row items-center gap-2">
                <span className="font-semibold">Question :</span>
                <span> {data?.question}</span>
              </p>
              <p className="flex flex-row items-center gap-2">
                <span className="font-semibold">TypeOf :</span>
                <span>{data?.typeOf}</span>
              </p>
              <p className="flex flex-col items-start gap-2">
                <span className="font-semibold">Answer : </span>
                <span>
                  {data?.typeOf === "stars" ? (
                    <Rating
                      name="read-only"
                      value={Number(data?.answer)}
                      readOnly
                    />
                  ) : data?.typeOf === "multipleChoice" &&
                    Array.isArray(data?.answer) ? (
                    data?.answer.join(", ")
                  ) : (
                    data?.answer
                  )}
                </span>
              </p>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ViewComponent;
