import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const QuestionMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.question?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export const SurveyMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.survey?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const FeedbackMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.feedback?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
