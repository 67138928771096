import React from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { Form, Formik } from "formik";

import * as Yup from "yup";
import ListBearers from "./ListBearers";
function AddItemPage({ addedBearerList, setAddedBearerList }) {
  const initialValues = {
    item_sr_no: addedBearerList.length + 1,
    bearer_image: "",
    bearer_name: "",
    designation: "",
  };
  const validationSchema = Yup.object({
    bearer_image: Yup.string("Add Image")
      .required("Bearer image is required")
      .nullable(),
    bearer_name: Yup.string("Add Bearer Name").required(
      "Bearer name is required"
    ),
    designation: Yup.string("Add Designation").required(
      "Designation is required"
    ),
  });
  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("bearer_image", file);
    // if (file) {
    //     setImagePreview(URL.createObjectURL(file));
    // }
  };

  const handleSubmit = (values, actions) => {
    setAddedBearerList([...addedBearerList, values]);
    actions.resetForm();
  };

  return (
    <React.Fragment>
      <Typography variant="h3" mb={3} mt={3}>
        Official Bearers
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid xs={4} item>
                <TextField
                  size="small"
                  fullWidth
                  id="bearer_image"
                  name="bearer_image"
                  label="Image*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button size="small" component="label">
                        <FileUploadOutlined />
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(event) => {
                            handleImageChange(event, setFieldValue);
                          }}
                        />
                      </Button>
                    ),
                    readOnly: true,
                  }}
                  // value={values?.bearer_image?.name || ""}
                  value={
                    values?.bearer_image
                      ? values?.bearer_image?.name
                      : null || ""
                  }
                  error={touched.bearer_image && Boolean(errors.bearer_image)}
                  helperText={touched.bearer_image && errors.bearer_image}
                  // value={values?.bearer_image instanceof File ? values?.bearer_image?.name : ""}
                />
              </Grid>
              <Grid xs={4} item>
                <JumboTextField
                  fullWidth
                  id="bearer_name"
                  name="bearer_name"
                  label="Bearer Name*"
                  value={values?.bearer_name}
                  // onChange={(e) => {
                  //     setFieldValue("bearer_name", e.target.value);
                  //     // handleValuesChange({ ...values, bearer_name: e.target.value });
                  // }}
                />
              </Grid>
              <Grid xs={4} item>
                <JumboTextField
                  fullWidth
                  id="designation"
                  name="designation"
                  label="Designation*"
                  value={values?.designation}
                  // onChange={(e) => {
                  //     setFieldValue("designation", e.target.value);
                  //     // handleValuesChange({ ...values, designation: e.target.value });
                  // }}
                />
              </Grid>
              <Grid xs={12} item display="flex" justifyContent="flex-end">
                <Button size="small" type="submit" variant="contained">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ListBearers
        list={addedBearerList}
        setAddedBearerList={setAddedBearerList}
        addedBearerList={addedBearerList}
      />
    </React.Fragment>
  );
}

export default AddItemPage;
