export const filterObjectWithValues = (obj = {}) => {
    if (!obj) return {};
    return Object.entries(obj).reduce((acc, [key, value]) => {
        // Check if value is an object, and recursively filter it
        if (typeof value === "object" && value !== null) {
            const filteredNestedObject = filterObjectWithValues(value);
            if (Object.keys(filteredNestedObject).length > 0) {
                acc[key] = filteredNestedObject;
            }
        }
        // Add key-value pair if the value is non-empty (for strings or other primitives)
        else if (value !== null && value !== undefined && value !== "") {
            acc[key] = value;
        }
        return acc;
    }, {});
};
