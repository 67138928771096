import Page from "@jumbo/shared/Page";
import AddUser from "app/pages/UserManagement/AddUser";
import ListUser from "app/pages/UserManagement/ListUser";
import EditUser from "app/pages/UserManagement/EditUser";
import UserChangePassword from "app/pages/UserManagement/UserChangePassword";

import { patch } from "@mui/material";
import { UserManagementMiddleware } from "../Middleware/auth/authMiddleware.js";

const routesName = "/user";

export const usersRoute = [
  {
    middleware: [
      {
        element: UserManagementMiddleware,
        fallbackPath: "/",
      },
    ],
    routes: [
      {
        path: `${routesName}`,
        element: <Page component={ListUser} layout={"vertical-default"} />,
        // permission: "view",
      },
      {
        path: `${routesName}/add`,
        element: <Page component={AddUser} layout={"vertical-default"} />,
        // permission: "add",
      },
      {
        path: `${routesName}/edit/:id`,
        element: <Page component={EditUser} layout={"vertical-default"} />,
        // permission: "edit",
      },
      {
        path: `${routesName}/change-password/:id`,
        element: (
          <Page component={UserChangePassword} layout={"vertical-default"} />
        ),
        // permission: "edit",
      },
    ],
  },
];

// export const usersRoute = routes;
