import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const DelegateKitMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.delegate_kit?.view === true) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export const GiveAwayTrackerMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.giveaway_tracker?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
