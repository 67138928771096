import { Axios } from "app/services/config";
import {
  LIST_ROLES_AND_PERMISSIONS_FAILED,
  LIST_ROLES_AND_PERMISSIONS_REQUEST,
  LIST_ROLES_AND_PERMISSIONS_SUCCESS,
} from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchRolesList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LIST_ROLES_AND_PERMISSIONS_FAILED));
    dispatch({ type: LIST_ROLES_AND_PERMISSIONS_REQUEST });

    let apiUrl = `/roles/list`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: LIST_ROLES_AND_PERMISSIONS_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(
      setError(
        LIST_ROLES_AND_PERMISSIONS_FAILED,
        error?.response?.data?.message
      )
    );
  }
};
