import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddSponsors() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const showAlert = ToastAlerts();
  const [imagePreview, setImagePreview] = useState(
    state?.sponsor_image
      ? `${process.env.REACT_APP_BE_IMG_URL}${state.sponsor_image}`
      : null
  );

  const initialValues = {
    sponsor_name: state?.sponsor_name ? state?.sponsor_name : "",
    sponsor_image: state?.sponsor_image ? state?.sponsor_image : null,
    status: state?.status ?? true,
  };
  const validationSchema = yup.object({
    sponsor_image: yup
      .string("Add Sponsor Image")
      .required("Sponsor Image is required")
      .nullable(),
    sponsor_name: yup
      .string("Sponsor name is required")
      .required("Sponsor name is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("sponsor_name", values?.sponsor_name);
      formData.append("sponsor_image", values?.sponsor_image);
      formData.append("status", values?.status);
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/sponsors/edit") {
        response = await Axios.post(
          `/sponsors/edit-sponsors/${state?._id}`,
          formData
        );
      } else {
        response = await Axios.post("/sponsors/add-sponsors", formData);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/sponsors");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("sponsor_image", file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/sponsors/add" ? "Add Sponsor" : "Edit Sponsor"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="sponsor_name"
                      name="sponsor_name"
                      label="Sponsor Name*"
                      value={values?.sponsor_name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* <JumboTextField
                                            fullWidth
                                            id="banner_image"
                                            name="banner_image"
                                            label="Banner Image*"
                                        /> */}
                    <TextField
                      size="small"
                      fullWidth
                      id="sponsor_image"
                      name="sponsor_image"
                      label="Upload Sponsor Logo*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            size="small"
                            // variant="outlined"
                            component="label"
                          >
                            <FileUploadOutlined />
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(event) =>
                                handleImageChange(event, setFieldValue)
                              }
                            />
                          </Button>
                        ),
                        readOnly: true,
                      }}
                      value={
                        values?.sponsor_image
                          ? values?.sponsor_image?.name
                          : null || ""
                      }
                      // value={
                      //   typeof values.sponsor_image === "string"
                      //     ? values.sponsor_image
                      //     : values.sponsor_image?.name || ""
                      // }
                      error={
                        touched.sponsor_image && Boolean(errors.sponsor_image)
                      }
                      helperText={touched.sponsor_image && errors.sponsor_image}
                    />
                    {imagePreview && (
                      <div className="flex flex-col items-start mt-5">
                        <p>
                          {pathname === "/sponsor/edit"
                            ? "Previous Uploaded Image"
                            : "Uploaded Image"}
                        </p>
                        <img
                          // src={`localhost:3001/${state?.banner_image}`}
                          src={imagePreview}
                          alt="Sponsor Preview"
                          style={{
                            marginTop: "10px",
                            maxWidth: "8rem",
                            height: "auto",
                          }}
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {pathname === "/sponsors/add" ? "Save" : "Update"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/sponsors");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddSponsors;
