import CryptoJS from "crypto-js";


const secret = process.env.REACT_APP_SECRET

export const encryptValues = (data, secret) => {
    const stringifiedData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(stringifiedData, secret).toString();
    return encryptedData
}

export const decryptValues = (data, secret) => {
    try {
        const bytes = CryptoJS.AES.decrypt(data, secret);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        if (decryptedData) {
            return JSON.parse(decryptedData);
        } else {
            return null;
        }
    } catch (error) {
        console.error("Decryption error:", error.message);
        return null;
    }
}

export const setData = (key, data) => {
    const encryptedData = encryptValues(data, secret);
    localStorage.setItem(key, encryptedData)
}

export const getData = (key) => {
    const data = localStorage.getItem(key);
    if (!data) return null;
    return decryptValues(data, secret)
}