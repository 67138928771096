import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const GuestMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.guest?.view === true) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export const RoomMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.room?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const LogsMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.logs?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const BannerMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.banner?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const SponsorMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.sponsors?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const PartnerMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.partners?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const SpeakersMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.speakers?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const RoadshowMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.roadshow?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const FloorPlanMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.floor_plan?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const AgendaMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.agenda?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const LiveEventMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.live_event?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const AboutUsMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.about_us?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
export const StallMiddleware = ({ fallbackPath }) => {
  const permissions = useSelector(
    (state) => state?.userReducer?.userInfo?.role_id?.permissions
  );

  if (permissions?.stall?.view) {
    return <Outlet />;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};
