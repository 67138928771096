import React, { useEffect } from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { batch, useDispatch } from "react-redux";
import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";
import { isValidEmail } from "@jumbo/utils";
import { onRoomList } from "app/redux/actions/Room";
import { useState } from "react";
import { iitList } from "app/utils/constants/iitList";
import { City, Country, State } from "country-state-city";
const EditGuest = () => {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [cityList, setCityList] = useState([]);
  const [StateList, setStateList] = useState([]);
  // const [gender, SetGender] = useState(["Male", "Female"]);

  var initialValues = {
    first_name: state?.first_name,
    last_name: state?.last_name,
    email_id: state?.email_id,
    gender: state?.gender,
    // category: state?.category,
    mobile_number: state?.mobile_number,
    company_name: state?.company_name,
    designation: state?.designation,
    city: state?.city,
    country: state?.country,
    state: state?.state,
    alumni_of_iit: state?.alumni_of_iit,
    iit_name: state?.iit_name,
    batch: state?.batch,
    stream: state?.stream,
    status: state?.status,
  };

  const validationSchema = yup.object({
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required"),
    // .matches(
    //   /^[A-Za-z ]+$/,
    //   "First Name must contain only alphabetic characters"
    // ),
    last_name: yup.string("Enter Last Name").required("Last Name is required"),
    // .matches(
    //   /^[A-Za-z ]+$/,
    //   "Last Name must contain only alphabetic characters"
    // ),
    email_id: yup
      .string("Enter your Email ID")
      .required("Email is required")
      .test(
        "isValidEmail",
        "Email should contain valid characters, an '@' symbol",
        (value) => isValidEmail(value)
      ),
    // category: yup
    //   .string("Enter your Category")
    //   .required("Category is required"),
    gender: yup
      .string("Enter your Gender")
      .required("Gender is required")
      .nullable(),
    city: yup.string("Enter your City").required("City is required"),
    country: yup.string("Enter your Country").required("Country is required"),
    state: yup.string("Enter your State").required("State is required"),

    // registration_date: yup
    //   .string("Enter your Registration Date")
    //   .required("Registration Date is required"),
    // industry: yup
    //   .string("Enter your Industry")
    //   .required("Industry is required"),
    company_name: yup
      .string("Enter your Company Name")
      .required("Company Name is required"),
    designation: yup
      .string("Enter your Designation")
      .required("Designation is required"),
    mobile_number: yup
      .string()
      .typeError("Mobile number must be a number")
      .required("Mobile Number is Required")
      .matches(/^\d{10}$/, "Number should be 10 digits."),
    alumni_of_iit: yup.boolean().required("Required").nullable(),
    iit_name: yup.string().when("alumni_of_iit", {
      is: true, // Apply when alumni_of_iit is true
      then: yup.string().required("IIT Name is required").nullable(),
      otherwise: yup.string().nullable(),
    }),
    batch: yup.string().when("alumni_of_iit", {
      is: true,
      then: yup.string().required("Batch is required"),
      otherwise: yup.string().nullable(),
    }),
    stream: yup.string().when("alumni_of_iit", {
      is: true,
      then: yup.string().required("Stream is required"),
      otherwise: yup.string().nullable(),
    }),
  });

  const handleGuestEdit = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      await Axios.patch(`/guest/edit/${id}`, data);
      showAlert("success", "Guest update successfully.");
      navigate("/guest");
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(onRoomList());
  }, []);

  const gender = ["Male", "Female", "Others"];

  const iit_alumnii = ["YES", "NO"];
  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state.country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state.state
      );
      filterStates(country[0]?.isoCode);
      filterCities(country[0]?.isoCode, stateList[0]?.isoCode);
    }
  }, []);
  const filterStates = (countryIsoCode) => {
    const filteredStates = State.getStatesOfCountry(countryIsoCode);
    setStateList(filteredStates);
  };
  const filterCities = (countryCode, stateIsoCode) => {
    const filteredCities = City.getCitiesOfState(countryCode, stateIsoCode);
    setCityList(filteredCities);
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        Edit Guest
      </Typography>
      {/* <Card>
        <CardContent> */}
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleGuestEdit(data, setSubmitting);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="first_name"
                  name="first_name"
                  label="First Name *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="email_id"
                  name="email_id"
                  label="Email *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="mobile_number"
                  name="mobile_number"
                  label="Mobile Number *"
                />
              </Grid>
              <Grid id item xs={6}>
                <FormControl fullWidth error={errors.gender && touched.gender}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    value={values?.gender || null}
                    getOptionLabel={(option) => option}
                    options={gender}
                    name="gender"
                    onChange={(event, val) => {
                      setFieldValue("gender", val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.gender && touched.gender}
                        {...params}
                        label="Gender *"
                      />
                    )}
                  />
                  {errors && errors.gender && touched.gender && (
                    <FormHelperText>{errors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="company_name"
                  name="company_name"
                  label="Company Name *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  required
                  id="designation"
                  name="designation"
                  label="Designation"
                />
              </Grid>
              {/* <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.category && touched.category}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        getOptionLabel={(option) => option}
                        options={category}
                        value={values?.category || null}
                        name="category"
                        onChange={(event, val) => {
                          setFieldValue("category", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.category && touched.category}
                            {...params}
                            label="Category"
                          />
                        )}
                      />
                      {errors && errors.category && touched.category && (
                        <FormHelperText>{errors.category}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}

              {/* <Grid id item xs={6}>
                <FormControl fullWidth error={errors.city && touched.city}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    value={values?.city || null}
                    getOptionLabel={(option) => option}
                    options={cityList}
                    name="city"
                    onChange={(event, val) => {
                      setFieldValue("city", val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.city && touched.city}
                        {...params}
                        label="City *"
                      />
                    )}
                  />
                  {errors && errors.city && touched.city && (
                    <FormHelperText>{errors.city}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid id item xs={6}>
                <FormControl
                  fullWidth
                  error={errors.country && touched.country}
                >
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    value={values?.country || null}
                    getOptionLabel={(option) => option}
                    options={countryList}
                    name="country"
                    onChange={(event, val) => {
                      setFieldValue("country", val);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.country && touched.country}
                        {...params}
                        label="Country *"
                      />
                    )}
                  />
                  {errors && errors.country && touched.country && (
                    <FormHelperText>{errors.country}</FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              <Grid id item xs={6}>
                <FormControl
                  fullWidth
                  error={errors.country && touched.country}
                >
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    value={values?.country}
                    options={countryList}
                    name="country"
                    onChange={(event, val) => {
                      setFieldValue("country", val?.name);
                      filterStates(val?.isoCode);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.country && touched.country}
                        {...params}
                        label="Country *"
                      />
                    )}
                  />
                  {errors && errors.country && touched.country && (
                    <FormHelperText>{errors.country}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid id item xs={6}>
                <FormControl fullWidth error={errors.state && touched.state}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    value={values?.state}
                    options={StateList}
                    name="state"
                    onChange={(event, val) => {
                      setFieldValue("state", val?.name);
                      setFieldValue("city", "");
                      filterCities(val?.countryCode, val?.isoCode);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.state && touched.state}
                        {...params}
                        label="State *"
                      />
                    )}
                  />
                  {errors && errors.state && touched.state && (
                    <FormHelperText>{errors.state}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid id item xs={6}>
                <FormControl fullWidth error={errors.city && touched.city}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.name;
                      } else {
                        return option;
                      }
                    }}
                    options={cityList}
                    value={values?.city}
                    name="city"
                    onChange={(event, val) => {
                      setFieldValue("city", val?.name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.city && touched.city}
                        {...params}
                        label="City *"
                      />
                    )}
                  />
                  {errors && errors.city && touched.city && (
                    <FormHelperText>{errors.city}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid id item xs={6}>
                <FormControl
                  fullWidth
                  error={errors.alumni_of_iit && touched.alumni_of_iit}
                >
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    getOptionLabel={(option) => option}
                    options={iit_alumnii}
                    value={values?.alumni_of_iit ? "YES" : "NO"}
                    name="alumni_of_iit"
                    onChange={(event, val) => {
                      if (val === "YES") {
                        setFieldValue("alumni_of_iit", true);
                      } else {
                        setFieldValue("alumni_of_iit", false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.alumni_of_iit && touched.alumni_of_iit}
                        {...params}
                        label="Are You an almunii of any iit? *"
                      />
                    )}
                  />
                  {errors && errors.alumni_of_iit && touched.alumni_of_iit && (
                    <FormHelperText>{errors.alumni_of_iit}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {values?.alumni_of_iit === true ? (
                <>
                  <Grid id item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.iit_name && touched.iit_name}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        value={values?.iit_name || null}
                        getOptionLabel={(option) => option}
                        options={iitList}
                        name="iit_name"
                        onChange={(event, val) => {
                          setFieldValue("iit_name", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={errors.iit_name && touched.iit_name}
                            {...params}
                            label="Select IIT *"
                          />
                        )}
                      />
                      {errors && errors.iit_name && touched.iit_name && (
                        <FormHelperText>{errors.iit_name}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* <Grid id item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.batch && touched.batch}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        value={values?.batch || null}
                        getOptionLabel={(option) => option}
                        options={dummy_batch}
                        name="batch"
                        onChange={(event, val) => {
                          setFieldValue("batch", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={errors.batch && touched.batch}
                            {...params}
                            label="Batch *"
                          />
                        )}
                      />
                      {errors && errors.batch && touched.batch && (
                        <FormHelperText>{errors.batch}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="batch"
                      name="batch"
                      label="Batch"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="stream"
                      name="stream"
                      label="Stream"
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} alignContent="center">
                <FormControlLabel
                  style={{ padding: "0px", margin: "0px", height: "100%" }}
                  control={
                    <Switch
                      onChange={(e) => {
                        setFieldValue("status", values?.status ? false : true);
                      }}
                      defaultChecked={values?.status ? true : false}
                      color="primary"
                    />
                  }
                  label="Status"
                  name="status"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt={5}>
              <Grid item xs={6} textAlign="right">
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result?.isConfirmed) {
                        navigate("/guest");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {/* </CardContent>
      </Card> */}
    </React.Fragment>
  );
};

export default EditGuest;
