
import { filterObjectWithValues } from "app/utils/constants/filterObjectWithValues";
import { LIST_BANNERS_FAILED, LIST_BANNERS_REQUEST, LIST_BANNERS_SUCCESS } from "./constants"
import { Axios } from "app/services/config";



// export const fetchBannerList = function ({

//     searchTerm = "",
//     sort = "desc",
//     sortBy = "updatedAt",
//     page = 1,
//     limit = 10,
//     filter = {},
// }) {
//     return async function (dispatch) {
//         try {
//             dispatch({ type: LIST_BANNERS_REQUEST });

//             const body = {
//                 filter: {
//                     ...filterObjectWithValues(filter)
//                 },
//                 searchFields: {
//                     string: [
//                         "banner_name",
//                         "inserted_by.first_name",
//                         "inserted_by.last_name",
//                     ],
//                     numbers: [
//                         "item_sr_no",
//                         // "invoice_length",
//                         // "invoice_diameter",
//                         // "invoice_cmt",
//                         // "indian_cmt",
//                         // "physical_length",
//                         // "physical_diameter",
//                         // "physical_cmt",
//                         // "exchange_rate",
//                         // "rate_in_currency",
//                         // "rate_in_inr",
//                         // "amount",
//                     ],
//                     arrayField: [],
//                 },
//             };
//             if (!searchTerm) {
//                 searchTerm = "";
//             }

//             const config = {
//                 withCredentials: true,
//                 headers: {
//                     withCredentials: true,
//                 },
//             };
//             console.log("search => ", searchTerm)
//             const urlParams = new URLSearchParams({
//                 search: searchTerm.trim(),
//                 page: page,
//                 sortField: sortBy,
//                 sortOrder: sort,
//                 limit: limit,
//             });

//             const response = await Axios.post(
//                 `/banner/list-banners?${urlParams.toString()}`,
//                 body,
//                 config
//             );
//             console.log("response in action banner => ", response)
//             dispatch({
//                 type: LIST_BANNERS_SUCCESS,
//                 payload: {
//                     data: response?.data?.data?.bannerList,
//                     totalPage: response?.data?.data?.totalPage,
//                 },
//             });
//         } catch (error) {
//             console.error(error)
//             dispatch({
//                 type: LIST_BANNERS_FAILED,
//                 payload: {
//                     isError: true,
//                     message: error.message,
//                 },
//             });
//         }
//     };
// };

const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchBannerList = (query) => async (dispatch) => {
    try {
        dispatch(clearError(LIST_BANNERS_FAILED));

        let apiUrl = `/banner/list-banners`;
        if (query) {
            const queryParams = new URLSearchParams(query);
            apiUrl =
                apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
        }
        const { data } = await Axios.get(apiUrl);

        dispatch({ type: LIST_BANNERS_SUCCESS, payload: data });
    } catch (error) {
        // setLoading(false);
        dispatch(setError(LIST_BANNERS_FAILED, error.message));
    } finally {
        // setLoading(false);
    }
};
