import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined, ViewAgenda } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import ActivityList from "./ActivityList";
import { getCurrentDate } from "app/utils/constants/constants";
import AgendaList from "./AddAgenda";

function AddAgenda() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [roomList, setRoomList] = useState([]);
  const [agendas, setAgendas] = useState(state?.agendas ? state?.agendas : []);

  const [activities, setActivities] = useState(
    state?.activities ? state?.activities : []
  );
  const [activityInput, setActivityInput] = useState("");

  const [agendaData, setAgendaData] = useState({
    title: state?.title ? state?.title : "",
    time: state?.time ? state?.time : "",
    activities: activities || [],
  });
  const showAlert = ToastAlerts();

  const initialValues = {
    // title: state?.title ? state?.title : "",
    activities: activities || [],
    agenda_date: state?.agenda_date
      ? new Date(state?.agenda_date).toISOString().split("T")[0]
      : getCurrentDate(),
    // time: state?.time ? state?.time : "",
    status: state?.status ?? true,
    // state: state?.room_id ? state?.room_id : "",
    room_id: state?.room_id ? state?.room_id : "",
    room_name: "",
    agendas: [
      {
        time: "",
        title: "",
        activities: [],
      },
    ],
  };

  const validationSchema = yup.object({
    // title: yup.string("Title is required").required("Title is required"),
    // activities: yup
    //   .array()
    //   .of(yup.string().required("Activity is required"))
    //   .min(1, "At least one activity is required"),
    agenda_date: yup.date().required("Agenda Date is required"),

    // time: yup.string("Enter  Time").required("Time is required"),
    room_id: yup.string("Select Room ").nullable().required("Room is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    if (agendas?.length === 0) {
      showAlert("error", "Please add at least one agenda.");
    }
    const newData = { ...values, agendas: agendas };

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/agenda/edit") {
        response = await Axios.post(`/agenda/edit/${state?._id}`, newData);
      } else {
        response = await Axios.post(`/agenda/add`, newData);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/agenda");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get("/room/dropdown-room-list");
        setRoomList(response?.data?.data);
      } catch (error) {
        console.log("err fetching rrom list => ", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    setAgendaData((prevAgendaData) => ({
      ...prevAgendaData,
      activities,
    }));
  }, [activities]);

  const handleAddActivity = (setFieldValue) => {
    if (activityInput) {
      const updatedActivities = [...activities, activityInput];
      setActivities(updatedActivities);
      setFieldValue("activities", updatedActivities);

      setActivityInput("");
    }
  };

  const handleRemoveActivity = (index, setFieldValue) => {
    const updatedActivities = activities?.filter((_, i) => i !== index);
    setActivities(updatedActivities);
    setFieldValue("activities", updatedActivities);
  };

  const handleAddAgenda = () => {
    if (
      agendaData?.title &&
      agendaData?.time &&
      agendaData?.activities.length > 0
    ) {
      setAgendas([...agendas, agendaData]);

      setAgendaData({
        title: "",
        time: "",
        activities: [],
      });
      setActivities([]);
      setActivityInput("");
    } else {
      // showAlert(
      //   "error",
      //   "Please fill all agenda fields and add at least one activity."
      // );
      Swal.fire({
        text: "Please fill all agenda fields and add at least one activity.",
        icon: "warning",
        timer: 3000,
      });
    }
  };

  const handleSaveAgenda = (updatedAgenda, index) => {
    setAgendas((prevAgendas) =>
      prevAgendas?.map((agenda, i) => (i === index ? updatedAgenda : agenda))
    );
  };

  const handleDeleteAgenda = (index) => {
    setAgendas((prevAgendas) => prevAgendas.filter((_, i) => i !== index));
  };

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/agenda/add" ? "Add Programme" : "Edit Programme"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="agenda_date"
                      name="agenda_date"
                      label="Agenda Date*"
                      // inputProps={{
                      //   max: today,
                      // }}
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      variant="outlined"
                      value={values?.agenda_date}
                      onChange={(e) =>
                        setFieldValue("agenda_date", e.target.value)
                      }
                      error={
                        touched?.agenda_date && Boolean(errors.agenda_date)
                      }
                      helperText={touched.agenda_date && errors.agenda_date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.room_id && touched.room_id}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        value={
                          roomList?.find(
                            (room) => room?._id === values?.room_id
                          ) || null
                        }
                        getOptionLabel={(option) => {
                          if (
                            option instanceof Object &&
                            !Array.isArray(option)
                          ) {
                            return option?.room_no;
                          } else {
                            return option;
                          }
                        }}
                        options={roomList}
                        name="room_id"
                        onChange={(event, val) => {
                          // setFieldValue("speaker", val?._id);
                          setFieldValue("room_name", val?.room_no);
                          setFieldValue("room_id", val?._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.room_id && touched.room_id}
                            {...params}
                            label="Room"
                          />
                        )}
                      />
                      {errors && errors.room_id && touched.room_id && (
                        <FormHelperText>{errors.room_id}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="time"
                      name="time"
                      size="small"
                      label="Time*"
                      value={agendaData?.time}
                      onChange={(e) => {
                        setAgendaData({ ...agendaData, time: e.target.value });
                      }}
                      error={touched?.time && errors?.time}
                      helperText={touched?.time && errors?.time}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="title"
                      size="small"
                      name="title"
                      label="Title*"
                      value={agendaData?.title}
                      onChange={(e) => {
                        setAgendaData({ ...agendaData, title: e.target.value });
                      }}
                      error={touched?.title && errors?.title}
                      helperText={touched?.title && errors?.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex flex-row gap-4 items-center justify-center">
                      <TextField
                        size="small"
                        fullWidth
                        id="activities"
                        name="activities"
                        label="Activities*"
                        value={activityInput}
                        onChange={(e) => setActivityInput(e.target.value)}
                        error={touched.activities && Boolean(errors.activities)}
                        helperText={touched.activities && errors.activities}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleAddActivity(setFieldValue);
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <div className="ml-8">
                      <ActivityList
                        list={activities || []}
                        handleRemoveActivity={(index) =>
                          handleRemoveActivity(index, setFieldValue)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleAddAgenda}
                    >
                      Add Agenda
                    </Button>
                  </Grid>
                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
                {agendas?.map((i, index) => (
                  <Grid item key={index} xs={12}>
                    <div className="mb-4 mt-2">
                      <AgendaList
                        index={index}
                        data={i}
                        onDelete={handleDeleteAgenda}
                        // onSave={handleSaveAgenda}
                        onSave={(updatedData) =>
                          handleSaveAgenda(updatedData, index)
                        }
                      />
                    </div>
                  </Grid>
                ))}

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <Tooltip
                      title={
                        agendas?.length === 0
                          ? "You need to add at leat one agenda"
                          : null
                      }
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      placement="top"
                    >
                      <span>
                        <LoadingButton
                          variant="contained"
                          size="medium"
                          disabled={agendas?.length === 0}
                          type="submit"
                          loading={isSubmitting}
                        >
                          {pathname === "/agenda/add" ? "Save" : "Update"}
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/agenda");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddAgenda;
