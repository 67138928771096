// import { Button, MenuItem, TextField } from "@mui/material";
// import { Axios } from "app/services/config";
// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import MapQuestionWithEvents from "../MapQuestions/MapQuestionWithEvents";

// function CreateQuestions() {
//   // const [events,setEvents] = useState([]);
//   const { register, handleSubmit, watch } = useForm();
//   const [options, setOptions] = useState([]);
//   const [currentOption, setCurrentOption] = useState("");
//   const selectedType = watch("typeOf");
//   const typeOptions = [
//     { type: "stars" },
//     { type: "singleChoice" },
//     { type: "multiLine" },
//     { type: "multipleChoice" },
//   ];
//   const handleAddOption = () => {
//     if (currentOption) {
//       setOptions([...options, currentOption]);
//       setCurrentOption("");
//     }
//   };
//   const handleCreation = async (values) => {
//     const allData = {
//       ...values,
//       options,
//     };
//     console.log("values => ", allData);

//     const res = await Axios.post("feedback/add", allData);
//     console.log("question res => ", res.data);
//   };
//   return (
//     <div className="flex flex-col gap-8">
//       <span className="text-xl ">Create Questions</span>
//       <form
//         className="flex flex-col items-start gap-6"
//         onSubmit={handleSubmit(handleCreation)}
//       >
//         <div className="mt-4 flex lg:flex-row flex-col items-center lg:justify-between justify-center gap-5 w-2/4">
//           <TextField
//             label="Create Question"
//             variant="outlined"
//             size="small"
//             fullWidth
//             {...register("question", { required: true })}
//           />
//           <TextField
//             label="Select Answer Type"
//             select
//             size="small"
//             fullWidth
//             {...register("typeOf")}
//           >
//             <MenuItem disabled>Select Answer Type</MenuItem>
//             {typeOptions.map((item) => (
//               <MenuItem value={item.type} key={item.type}>
//                 {item.type}
//               </MenuItem>
//             ))}
//           </TextField>

//           <Button
//             variant="contained"
//             size="small"
//             sx={{ padding: 1 }}
//             type="submit"
//           >
//             Create
//           </Button>
//         </div>
//         {(selectedType === "singleChoice" ||
//           selectedType === "multipleChoice") && (
//           <div className="flex flex-row gap-5 items-center justify-between">
//             <TextField
//               label="Add Options"
//               size="small"
//               fullWidth
//               value={currentOption}
//               onChange={(e) => setCurrentOption(e.target.value)}
//               // {...register('options')}
//             />
//             <Button
//               variant="contained"
//               size="small"
//               sx={{ padding: 1 }}
//               onClick={handleAddOption}
//             >
//               Add
//             </Button>
//             <div>
//               <p>Options</p>
//               {options &&
//                 options.map((opt, index) => (
//                   <p key={index}>
//                     {index} {opt}
//                   </p>
//                 ))}
//             </div>
//           </div>
//         )}
//       </form>
//       {/* <MapQuestionPage /> */}
//       <MapQuestionWithEvents />
//     </div>
//   );
// }

// export default CreateQuestions;
// import { Button, Grid, MenuItem, TextField } from "@mui/material";
// import { Axios } from "app/services/config";
// import React, { useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import MapQuestionWithEvents from "../MapQuestions/MapQuestionWithEvents";
// import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
// import { LoadingButton } from "@mui/lab";

// function CreateQuestions() {
//   const [options, setOptions] = useState([]);
//   const [currentOption, setCurrentOption] = useState("");

//   const typeOptions = [
//     { type: "stars" },
//     { type: "singleChoice" },
//     { type: "multiLine" },
//     { type: "multipleChoice" },
//   ];

//   const validationSchema = Yup.object().shape({
//     question: Yup.string().required("Question is required"),
//     typeOf: Yup.string().required("Answer Type is required"),
//     options: Yup.array()
//       .of(Yup.string().required("Option cannot be empty"))
//       .when("typeOf", {
//         is: (typeOf) =>
//           typeOf === "singleChoice" || typeOf === "multipleChoice",
//         then: (schema) => schema.min(1, "At least one option is required"),
//         otherwise: (schema) => schema.notRequired(),
//       }),
//   });

//   const handleAddOption = () => {
//     if (currentOption) {
//       setOptions([...options, currentOption]);
//       setCurrentOption("");
//     }
//   };

//   const handleCreation = async (values) => {
//     const allData = {
//       ...values,
//       options,
//     };
//     console.log("values => ", allData);

//     // const res = await Axios.post("feedback/add", allData);
//     // console.log("question res => ", res.data);
//   };

//   return (
//     <div className="flex flex-col gap-8">
//       <span className="text-xl ">Create Questions</span>
//       <Formik
//         initialValues={{ question: "", typeOf: "" }}
//         validationSchema={validationSchema}
//         onSubmit={handleCreation}
//       >
//         {({ values, setFieldValue, touched, errors }) => (
//           //   <Form className="flex flex-col items-start gap-6">
//           <Form noValidate autoComplete="off">
//             {/* <div className="mt-4 flex lg:flex-row flex-col items-center lg:justify-between justify-center gap-5 w-2/4"> */}
//             <Grid container rowSpacing={3} columnSpacing={3}>
//               <Grid item xs={3}>
//                 <JumboTextField
//                   name="question"
//                   label="Create Question"
//                   size="small"
//                   fullWidth
//                 />
//               </Grid>
//               <Grid item xs={3}>
//                 <TextField
//                   name="typeOf"
//                   label="Select Answer Type"
//                   select
//                   size="small"
//                   fullWidth
//                   error={touched?.typeOf && errors?.typeOf}
//                   helperText={touched?.typeOf && errors?.typeOf}
//                   onChange={(e) => {
//                     setFieldValue("typeOf", e.target.value);
//                   }}
//                 >
//                   <MenuItem disabled>Select Answer Type</MenuItem>
//                   {typeOptions.map((item) => (
//                     <MenuItem value={item.type} key={item.type}>
//                       {item.type}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//                 <Grid />
//               </Grid>
//               <Grid item xs={3}>
//                 <LoadingButton
//                   variant="contained"
//                   size="small"
//                   sx={{ padding: 1 }}
//                   type="submit"
//                 >
//                   Submit
//                 </LoadingButton>
//               </Grid>
//             </Grid>
//             {/* </div> */}

//             {(values?.typeOf === "singleChoice" ||
//               values?.typeOf === "multipleChoice") && (
//               <>
//                 <div
//                   className="grid grid-cols-12 grid-flow-row mt-4 gap-6"
//                   //   className="flex flex-row gap-5 items-center justify-between"
//                 >
//                   <TextField
//                     label="Add Options"
//                     className="grid col-span-4"
//                     size="small"
//                     fullWidth
//                     value={currentOption}
//                     required
//                     onChange={(e) => setCurrentOption(e.target.value)}
//                   />
//                   <Button
//                     variant="contained"
//                     className="grid col-span-1"
//                     size="small"
//                     sx={{ padding: 1 }}
//                     onClick={handleAddOption}
//                   >
//                     Add
//                   </Button>
//                 </div>
//                 <div>
//                   <div>
//                     <p>Options</p>
//                     {options &&
//                       options.map((opt, index) => (
//                         <p key={index}>
//                           {index + 1} {opt}
//                         </p>
//                       ))}
//                   </div>
//                 </div>
//               </>
//             )}
//           </Form>
//         )}
//       </Formik>
//       <MapQuestionWithEvents />
//     </div>
//   );
// }

// export default CreateQuestions;

import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { Axios } from "app/services/config";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import MapQuestionWithEvents from "../../MapQuestions/MapQuestionWithEvents";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import ToastAlerts from "app/components/Toast";

function CreateQuestions() {
  const { state, pathname } = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const initialValues = {
    question: state?.question || "",
    typeOf: state?.typeOf || "",
    options: state?.options || [],
    status: state?.status ?? true,
  };
  const typeOptions = [
    { type: "stars" },
    { type: "singleChoice" },
    { type: "multiLine" },
    { type: "multipleChoice" },
  ];

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    typeOf: Yup.string().required("Answer Type is required"),
    options: Yup.array()
      .of(Yup.string().required("Option cannot be empty"))
      .when("typeOf", {
        is: (typeOf) =>
          typeOf === "singleChoice" || typeOf === "multipleChoice",
        then: (schema) => schema.min(1, "At least one option is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const handleCreation = async (values) => {
    console.log("Submitted values:", values);
    setSubmitting(true);
    try {
      let response;

      if (pathname === "/questions/add") {
        response = await Axios.post("/feedback/add-question", values);
      } else {
        response = await Axios.post(
          `/feedback/edit-question/${state?._id}`,
          values
        );
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/questions");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }

    // await Axios.post("feedback/add", values);
  };

  return (
    <div className="flex flex-col gap-8">
      <span className="text-xl">
        {pathname === "/questions/add" ? "Create Question" : "Update Question"}
      </span>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreation}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <JumboTextField
                  name="question"
                  label="Question*"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="typeOf"
                  label="Select Answer Type*"
                  select
                  size="small"
                  fullWidth
                  value={values?.typeOf}
                  error={touched.typeOf && Boolean(errors.typeOf)}
                  helperText={touched.typeOf && errors.typeOf}
                  onChange={(e) => {
                    setFieldValue("typeOf", e.target.value);
                    setFieldValue("options", []);
                  }}
                >
                  <MenuItem disabled>Select Answer Type</MenuItem>
                  {typeOptions.map((item) => (
                    <MenuItem
                      value={item?.type}
                      key={item?.type}
                      selected={values?.typeOf === item}
                    >
                      {item?.type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {(values?.typeOf === "singleChoice" ||
              values?.typeOf === "multipleChoice") && (
              <FieldArray name="options">
                {({ remove, push }) => (
                  <div className="mt-4">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          label="Add Option*"
                          size="small"
                          fullWidth
                          value={values?.currentOption || ""}
                          onChange={(e) =>
                            setFieldValue("currentOption", e.target.value)
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              if (values.currentOption) {
                                push(values.currentOption);
                                setFieldValue("currentOption", "");
                              }
                            }
                          }}
                          error={touched?.options && Boolean(errors?.options)}
                          helperText={touched?.options && errors?.options}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            if (values?.currentOption) {
                              push(values.currentOption);
                              setFieldValue("currentOption", "");
                            }
                          }}
                        >
                          Add Option
                        </Button>
                      </Grid>
                    </Grid>

                    <div className="mt-3">
                      {values.options.map((option, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span>
                            {index + 1}. {option}
                          </span>
                          <IconButton onClick={() => remove(index)}>
                            <DeleteOutlineOutlined color="error" />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
            )}
            <Grid item xs={12} alignContent="center">
              <FormControlLabel
                style={{
                  padding: "0px",
                  margin: "0px",
                  height: "100%",
                }}
                control={
                  <Switch
                    onChange={(e) => {
                      setFieldValue("status", values?.status ? false : true);
                    }}
                    defaultChecked={values?.status ? true : false}
                    color="primary"
                  />
                }
                label="Status"
                name="status"
                labelPlacement="start"
              />
            </Grid>
            <div className="mt-10 flex flex-row  gap-6 items-center justify-center">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/questions");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <LoadingButton size="small" variant="contained" type="submit">
                {pathname === "/questions/add" ? "Submit" : "Update "}
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
      {/* <MapQuestionWithEvents /> */}
    </div>
  );
}

export default CreateQuestions;
