// import { Card, CardContent, Grid } from "@mui/material";
// import React from "react";

// function AgendaList({ data }) {
//   console.log("data got in view agenda => ", data);
//   return (
//     <>
//       <Card>
//         <CardContent>
//           <Grid item xs={12} md={6}>
//             <div className="flex flex-col items-start gap-4">
//               <p className="flex flex-row items-center gap-2">
//                 <span className="font-semibold">Time :</span>
//                 <span> {data?.time}</span>
//               </p>
//               <p className="flex flex-row items-center gap-2">
//                 <span className="font-semibold">Title :</span>
//                 <span>{data?.title}</span>
//               </p>
//               <p className="flex flex-row items-center gap-2">
//                 <span className="font-semibold">Activities : </span>
//                 <span>
//                   {data?.activities?.map((i) => (
//                     <p>{i}</p>
//                   ))}
//                 </span>
//               </p>
//             </div>
//           </Grid>
//         </CardContent>
//       </Card>
//     </>
//   );
// }

// export default AgendaList;

// // export default AgendaList;
// import { Card, CardContent, Grid, Button, TextField } from "@mui/material";
// import React, { useState } from "react";

// function AgendaList({ data, onSave, onDelete }) {
//   const [isEditing, setIsEditing] = useState(false);
//   const [editData, setEditData] = useState(data);

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleSaveClick = () => {
//     setIsEditing(false);
//     onSave(editData); // Update the parent component with the new data
//   };

//   const handleDeleteClick = () => {
//     onDelete(data.id); // Trigger delete action in the parent component
//   };

//   const handleInputChange = (field, value) => {
//     setEditData((prevData) => ({
//       ...prevData,
//       [field]: value,
//     }));
//   };

//   const handleActivityChange = (index, value) => {
//     const updatedActivities = [...editData.activities];
//     updatedActivities[index] = value;
//     setEditData((prevData) => ({
//       ...prevData,
//       activities: updatedActivities,
//     }));
//   };

//   return (
//     <Card>
//       <CardContent>
//         <Grid item xs={12} md={6}>
//           <div className="flex flex-col items-start gap-4">
//             <div className="flex flex-row items-center justify-between">
//               <p className="flex flex-row items-center gap-2">
//                 <span className="font-semibold">Time :</span>
//                 {isEditing ? (
//                   <TextField
//                     size="small"
//                     value={editData.time}
//                     onChange={(e) => handleInputChange("time", e.target.value)}
//                   />
//                 ) : (
//                   <span>{data?.time}</span>
//                 )}
//               </p>

//               <p className="flex flex-row items-center gap-2">
//                 <span className="font-semibold">Title :</span>
//                 {isEditing ? (
//                   <TextField
//                     size="small"
//                     value={editData.title}
//                     onChange={(e) => handleInputChange("title", e.target.value)}
//                   />
//                 ) : (
//                   <span>{data?.title}</span>
//                 )}
//               </p>
//             </div>
//             <p className="flex flex-row items-center gap-2">
//               <span className="font-semibold">Activities :</span>
//               {isEditing ? (
//                 <div>
//                   {editData?.activities?.map((activity, index) => (
//                     <TextField
//                       key={index}
//                       size="small"
//                       value={activity}
//                       onChange={(e) =>
//                         handleActivityChange(index, e.target.value)
//                       }
//                       style={{ marginBottom: "8px" }}
//                     />
//                   ))}
//                 </div>
//               ) : (
//                 <span>
//                   {data?.activities?.map((activity, index) => (
//                     <p key={index}>{activity}</p>
//                   ))}
//                 </span>
//               )}
//             </p>

//             <div className="flex gap-2">
//               {isEditing ? (
//                 <Button
//                   size="small"
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSaveClick}
//                 >
//                   Save
//                 </Button>
//               ) : (
//                 <Button
//                   variant="contained"
//                   size="small"
//                   color="primary"
//                   onClick={handleEditClick}
//                 >
//                   Edit
//                 </Button>
//               )}
//               <Button
//                 variant="contained"
//                 size="small"
//                 color="error"
//                 onClick={handleDeleteClick}
//               >
//                 Delete
//               </Button>
//             </div>
//           </div>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// }

// export default AgendaList;
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

function AgendaList({ data, onSave, onDelete, index }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(data);
  const [newActivity, setNewActivity] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(editData);
  };

  const handleDeleteClick = () => {
    onDelete(index);
  };

  const handleInputChange = (field, value) => {
    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleActivityChange = (index, value) => {
    const updatedActivities = [...editData.activities];
    updatedActivities[index] = value;
    setEditData((prevData) => ({
      ...prevData,
      activities: updatedActivities,
    }));
  };

  const handleActivityDelete = (index) => {
    const updatedActivities = editData.activities.filter((_, i) => i !== index);
    setEditData((prevData) => ({
      ...prevData,
      activities: updatedActivities,
    }));
  };

  const handleAddActivity = () => {
    if (newActivity.trim()) {
      setEditData((prevData) => ({
        ...prevData,
        activities: [...prevData.activities, newActivity],
      }));
      setNewActivity(""); // Clear the input field
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold">Time</span>
              {isEditing ? (
                <TextField
                  size="small"
                  value={editData.time}
                  onChange={(e) => handleInputChange("time", e.target.value)}
                  fullWidth
                />
              ) : (
                <span>{data?.time}</span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold">Title</span>
              {isEditing ? (
                <TextField
                  size="small"
                  value={editData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                  fullWidth
                />
              ) : (
                <span>{data?.title}</span>
              )}
            </div>
          </Grid>

          {/* <Grid item xs={12}>
            <span className="font-semibold">Activities</span>
            {isEditing ? (
              <Grid item xs={12} display="flex" gap={2}>
                <Grid item xs={11}>
                  <TextField
                    size="small"
                    value={newActivity}
                    onChange={(e) => setNewActivity(e.target.value)}
                    fullWidth
                    placeholder="Add new activity"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleAddActivity}
                  >
                    Add
                  </Button>
                </Grid>

                {editData?.activities?.map((activity, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <TextField
                      size="small"
                      value={activity}
                      onChange={(e) =>
                        handleActivityChange(index, e.target.value)
                      }
                      fullWidth
                    />
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleActivityDelete(index)}
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </Grid>
            ) : (
              <div>
                {data?.activities?.map((activity, index) => (
                  <p key={index}>{activity}</p>
                ))}
              </div>
            )}
          </Grid> */}
          <Grid item xs={12}>
            <div className="font-semibold mb-2">Activities</div>

            {isEditing ? (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={11}>
                    <TextField
                      size="small"
                      value={newActivity}
                      onChange={(e) => setNewActivity(e.target.value)}
                      fullWidth
                      placeholder="Add new activity"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleAddActivity}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ marginTop: "16px" }}>
                  <Grid container spacing={2}>
                    {editData?.activities?.map((activity, index) => (
                      <React.Fragment key={index}>
                        <Grid display="flex" item xs={5}>
                          <TextField
                            size="small"
                            value={activity}
                            fullWidth
                            onChange={(e) =>
                              handleActivityChange(index, e.target.value)
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleActivityDelete(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </div>
              </>
            ) : (
              <Grid container spacing={2}>
                {data?.activities?.map((activity, index) => (
                  <Grid item xs={6} key={index}>
                    {index + 1}. {activity}
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <div className="flex gap-2">
              {isEditing ? (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              )}
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => handleDeleteClick()}
              >
                Delete
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AgendaList;

// {
//   /* <Grid item xs={11}>
//                     <TextField
//                       size="small"
//                       value={newActivity}
//                       onChange={(e) => setNewActivity(e.target.value)}
//                       fullWidth
//                       placeholder="Add new activity"
//                     />
//                   </Grid>
//                   <Grid item xs={1}>
//                     <Button
//                       size="small"
//                       variant="contained"
//                       color="primary"
//                       onClick={handleAddActivity}
//                       style={{ marginLeft: "8px" }}
//                     >
//                       Add
//                     </Button>
//                   </Grid> */
// }
