import { Add, AddOutlined, CameraswitchOutlined, MoreHoriz, QuestionAnswerOutlined } from "@mui/icons-material";
import Search from "@mui/icons-material/Search";
import {
    Button,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import QuestionsModal from "app/components/Modal/Modal";

import { Axios } from "app/services/config";
import React, { useEffect, useState } from "react";
import { useFetcher, useNavigate } from "react-router-dom";

function MapQuestionWithEvents() {
    const [allEvents, setAllEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [eventId, setEventId] = useState("");
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);
    const [count, setCount] = useState("");
    const [query, setQuery] = useState("");
    const [deboundedQuery, setDebouneedQuery] = useState(query)
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Axios.get("room/events", {
                    params: {
                        page: page + 1,
                        limit: rowsPerPage,
                        query: deboundedQuery
                    }
                })
                setAllEvents(res.data?.data?.allRooms);
                setCount(res.data?.data?.totalCount)
            } catch (error) {
                console.log("err fetching questions => ", error.message);
            }
        };
        fetchData();
    }, [page, rowsPerPage, deboundedQuery]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouneedQuery(query);
            setPage(0)
        }, 500);

        return () => {
            clearTimeout(handler)
        }
    }, [query])

    const [menuState, setMenuState] = useState({
        anchorEl: null,
        currentEventId: null,
    });

    const handleMenuClick = (event, id) => {
        setMenuState({
            anchorEl: event.currentTarget,
            currentEventId: id
        })
        // setEventId(id);
        console.log("Event ID set to: ", id);
    };

    const handleMenuClose = () => {
        setMenuState({
            anchorEl: null,
            currentEventId: null
        })
    };

    const handleOpenModal = (id) => {
        console.log("event id => ", id)
        setEventId(id);
        setOpen(true);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage)
    }
    return (
        <>
            <div className="text-xl mt-10">All Questions</div>
            <div className="flex flex-row items-center gap-5 ">
                <TextField
                    size="small"
                    label="search events.."
                    variant="outlined"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }}
                    InputProps={{
                        startAdornment: (<InputAdornment><Search fontSize="small" className="mr-2" /></InputAdornment>)
                    }}
                    className="self-start w-1/5"
                />
                <Button variant="contained" size="small" sx={{ padding: 1 }} onClick={() => navigate("/feedback-logs")}>View All Feedbacks</Button>
            </div>

            <div>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell> Sr.No</TableCell>
                                    <TableCell> Event</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell> Action</TableCell>
                                </TableRow>
                            </TableHead>

                            {allEvents.length > 0 ? (
                                <TableBody>
                                    {allEvents.map((row, index) => (
                                        <TableRow key={row._id}>
                                            <TableCell sortDirection="desc">{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell >{row.room_no}</TableCell>
                                            <TableCell>
                                                {row.status === true ? "Active" : "Not Active"}
                                            </TableCell>
                                            <TableCell >
                                                <IconButton onClick={(e) => {
                                                    handleMenuClick(e, row._id)
                                                }}>

                                                    <MoreHoriz />
                                                </IconButton>
                                                <Menu open={Boolean(menuState.anchorEl)} anchorEl={menuState.anchorEl} onClose={handleMenuClose}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleMenuClose()
                                                            console.log(menuState.currentEventId)
                                                            handleOpenModal(menuState.currentEventId)
                                                        }}
                                                    >
                                                        <ListItemIcon><AddOutlined /></ListItemIcon>
                                                        <ListItemText>Add Questions</ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={async () => {
                                                            // await fetchQuestionsByEvent(menuState.currentEventId)
                                                            // handleOpenModal(menuState.currentEventId);
                                                            navigate(`/mapped-questions/${menuState.currentEventId}`)
                                                        }}
                                                    >
                                                        <ListItemIcon><QuestionAnswerOutlined /></ListItemIcon>
                                                        <ListItemText>View Mapped Questions</ListItemText>
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            ) : (
                                <TableRow>

                                    <TableCell align="center" colSpan={4} className="text-center font-bold">
                                        No Data Found....
                                    </TableCell>
                                </TableRow>
                            )}
                            <QuestionsModal
                                open={open}
                                handleClose={handleClose}
                                eventId={eventId}
                            // questionsByEvent={questionByEvent}
                            />
                        </Table>
                    </TableContainer>
                    {/* {count && ( */}
                    <TablePagination
                        rowsPerPageOptions={""}
                        component={"div"}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                    />
                    {/* )} */}
                </Paper>
            </div>
        </>
    );
}



export default MapQuestionWithEvents;


