import Page from "@jumbo/shared/Page";
import AddRoles from "app/pages/RolesAndPermissions/Add";
import ListRoles from "app/pages/RolesAndPermissions/List";

import { RolesPermissionsMiddleware } from "../Middleware/auth/authMiddleware";

const routesName = "/roles";

// var routes = [
//   {
//     path: `${routesName}`,
//     element: <Page component={ListRoles} layout={"vertical-default"} />,
//   },
//   {
//     path: `${routesName}/add`,
//     element: <Page component={AddRoles} layout={"vertical-default"} />,
//   },
//   {
//     path: `${routesName}/edit`,
//     element: <Page component={AddRoles} layout={"vertical-default"} />,
//   },
// ];

export const rolesAndPermissionsRoute = [
  {
    middleware: [
      {
        element: RolesPermissionsMiddleware,
        fallbackPath: "/login",
      },
    ],
    routes: [
      {
        path: `${routesName}`,
        element: <Page component={ListRoles} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/add`,
        element: <Page component={AddRoles} layout={"vertical-default"} />,
      },
      {
        path: `${routesName}/edit`,
        element: <Page component={AddRoles} layout={"vertical-default"} />,
      },
    ],
  },
];
