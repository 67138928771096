import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";

const AddRoom = ({ openView, setOpenView, roomId }) => {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();

  const [room, setRoom] = useState();
  const [floorList, setFloorList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (roomId) {
        try {
          const response = await Axios.get(`/room/list?id=${roomId}`);

          setRoom(response.data.data);
        } catch (error) {
          console.error("Error fetching room data:", error);
        }
      }
    };
    fetchData();
  }, [roomId]);

  var initialValues = {
    room_no: room?.room_no ? room?.room_no : "",
    floor_name: room?.floor_name ? room?.floor_name : "",
    status: room?.status !== undefined ? room?.status : true,
  };

  const validationSchema = yup.object({
    room_no: yup
      .string("Enter Room Name.")
      .nullable()
      .required("Room Name is required."),
    floor_name: yup
      .string("Select Floor.")
      .required("Floor name is required."),
  });

  const handleRoomSubmit = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      if (roomId) {
        await Axios.patch(`/room/edit?id=${roomId}`, data);
        showAlert("success", "Room updated successfully.");
      } else {
        await Axios.post("/room/add", data);
        showAlert("success", "Room created successfully.");
      }
      navigate("/rooms");
      setOpenView(false);
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    (async function () {
      const res = await Axios.get("/floor-plan/dropdown-floor");
      setFloorList(res?.data?.data)
    })()
  }, [])
  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        {!room ? "Add Room" : "Edit Room"}
      </DialogTitle>
      <DialogContent>
        <Formik
          validateOnChange={true}
          key={JSON.stringify(initialValues)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            validationSchema
              .validate(data, { abortEarly: false })
              .then(() => {
                handleRoomSubmit(data, setSubmitting);
              })
              .catch((validationErrors) => {
                console.error("Validation Errors:", validationErrors);
              });
          }}
        >
          {({ setFieldValue, isSubmitting, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Grid container rowSpacing={3} mt={1}>
                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    required
                    id="room_no"
                    name="room_no"
                    label="Room Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    // autoSelect
                    // autoHighlight
                    fullWidth
                    size="small"
                    disablePortal
                    // value={values?.speaker_name || []}
                    value={floorList?.find((i) => i?._id === values?.floor_name)}
                    getOptionLabel={(option) => {
                      if (
                        option instanceof Object &&
                        !Array.isArray(option)
                      ) {
                        return option?.floor_name;
                      } else {
                        return option;
                      }
                    }}
                    options={floorList}
                    name="floor_name"
                    onChange={(event, val) => {
                      // setFieldValue("speaker", val?._id);
                      setFieldValue(
                        "floor_name",
                        val?._id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={errors.floor_name && touched.floor_name}
                        {...params}
                        label="Floor"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignContent="center" xs={12}>
                  <FormControlLabel
                    style={{ padding: "0px", margin: "0px", height: "100%" }}
                    control={
                      <Switch
                        onChange={(e) => {
                          setFieldValue("status", values.status ? false : true);
                        }}
                        defaultChecked={values.status ? true : false}
                        color="primary"
                      />
                    }
                    label="Status"
                    name="status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ justifyContent: "flex-end" }}
                columnGap={3}
                mt={5}
              >
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="medium"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item textAlign="left">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenView(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddRoom;
