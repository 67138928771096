import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

import { Axios } from "app/services/config";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

const DesignationWiseChart = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async function () {
      try {
        const { data } = await Axios.get("/metrics/designation");
        let res = data?.data;
        setData(
          res?.map((e) => ({
            designation: e?.designation,
            count: e?.count,
            // index: Number(e._id.star),
            // value:e.totalStar
          }))
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  return (
    <JumboCardQuick
      title={"Total Registration - Designation Wise"}
      wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
    >
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="designation" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            cursor={false}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="count"
            stroke={"#1e88e5"}
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="uv" stroke={"#e91e63"}/> */}
        </LineChart>
      </ResponsiveContainer>
    </JumboCardQuick>
  );
};

export default DesignationWiseChart;
