// import { Grid, Typography } from "@mui/material";
// import React from "react";
// import AllAnalyticsPage from "./Analytics";

// const Dashboard = () => {
//   return (
//     <div className="grid grid-flow-row gap-6 grid-cols-12">
//       <Grid item xs={12}>
//         <Typography variant="h1" sx={{ fontSize: "2rem" }}>
//           Welcome To Piwot Events
//         </Typography>
//       </Grid>
//       <Grid item xs={12}>
//         <AllAnalyticsPage />
//       </Grid>
//     </div>
//   );
// };

// export default Dashboard;
import { Grid, Typography } from "@mui/material";
import React from "react";
import AllAnalyticsPage from "./Analytics";

const Dashboard = () => {
  return (
    <div className="grid grid-flow-row gap-6 grid-cols-12">
      <div className="grid col-span-12 ">
        <Typography variant="h1" sx={{ fontSize: "2rem" }}>
          Welcome To Piwot Events
        </Typography>
      </div>
      <div className="grid col-span-12 ">
        <AllAnalyticsPage />
      </div>
    </div>
  );
};

export default Dashboard;
