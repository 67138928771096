import {
  DeleteOutlineOutlined,
  Edit,
  FileUploadOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ClearIcon } from "@mui/x-date-pickers";
export default function ListBearers({
  list,
  setAddedBearerList,
  addedBearerList,
}) {
  const initialValues = {
    item_sr_no: 0,
    bearer_image: null,
    bearer_name: "",
    designation: "",
  };
  const validationSchema = Yup.object({
    bearer_image: Yup.string("Add Image")
      .required("Bearer image is required")
      .nullable(),
    bearer_name: Yup.string("Add Bearer Name").required(
      "Bearer name is required"
    ),
    designation: Yup.string("Add Designation").required(
      "Designation is required"
    ),
  });
  const [data, setData] = useState(initialValues);

  const updateRow = (values, index) => {
    const updatedList = [...addedBearerList];
    updatedList.splice(index, 1, values);
    setAddedBearerList(updatedList);
    setData(null);
  };
  const removeRow = (index) => {
    const updatedList = [...addedBearerList];
    updatedList.splice(index, 1);
    setAddedBearerList(updatedList);
    setData(null);
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("bearer_image", file);
    // if (file) {
    //     setImagePreview(URL.createObjectURL(file));
    // }
  };

  const getImageSource = (image) => {
    return image instanceof File
      ? URL.createObjectURL(image)
      : `${process.env.REACT_APP_BE_IMG_URL}${image}`;
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={data}
      enableReinitialize={true}
      validationSchema={validationSchema}
      // onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        values,
        setFieldTouched,
        errors,
        initialValues,
        handleBlur,
        touched,
      }) => (
        <TableContainer className="mt-4" component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                // variant="head"
                sx={{
                  padding: "10px 0px 10px 20px",
                  fontSize: "16px",
                  width: "auto",
                }}
                align="left"
              >
                <TableCell>Sr.No</TableCell>
                <TableCell>Bearer Image</TableCell>
                <TableCell>Bearer Name</TableCell>
                <TableCell>Bearer Designation</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {addedBearerList && addedBearerList.length ? (
              <TableBody>
                {addedBearerList &&
                  addedBearerList?.map((item, index) =>
                    values?.item_sr_no !== item?.item_sr_no ? (
                      <TableRow key={index}>
                        <TableCell>{item?.item_sr_no}</TableCell>
                        <TableCell>
                          <img
                            // src={URL.createObjectURL(item?.bearer_image)}
                            src={getImageSource(item?.bearer_image)}
                            alt="bearer profile"
                            style={{
                              height: "60px",
                              width: "60px",
                              objectFit: "cover",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {item?.bearer_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {item?.designation}
                          </Typography>
                        </TableCell>
                        <TableCell
                        // sx={{
                        //     // textAlign: "center",

                        //     display: "flex",
                        // }}
                        >
                          <IconButton
                            color="warning"
                            size="small"
                            onClick={() => setData(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => removeRow(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{values?.item_sr_no}</TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            size="small"
                            id="bearer_image"
                            name="bearer_image"
                            label="Image*"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <Button size="small" component="label">
                                  <FileUploadOutlined />
                                  <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(event) => {
                                      handleImageChange(event, setFieldValue);
                                    }}
                                  />
                                </Button>
                              ),
                              readOnly: true,
                            }}
                            // value={values?.bearer_image instanceof File ? values?.bearer_image?.name : ""}
                            // value={values.bearer_image ? values?.bearer_image.name : ""}
                            value={
                              values?.bearer_image
                                ? values?.bearer_image?.name
                                : null || ""
                            }
                            error={
                              touched.bearer_image &&
                              Boolean(errors.bearer_image)
                            }
                            helperText={
                              touched.bearer_image && errors.bearer_image
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <JumboTextField
                            fullWidth
                            id="bearer_name"
                            name="bearer_name"
                            label="Bearer Name*"
                            value={values?.bearer_name}
                          />
                        </TableCell>
                        <TableCell>
                          <JumboTextField
                            fullWidth
                            id="designation"
                            name="designation"
                            label="Designation*"
                            value={values?.designation}
                          />
                        </TableCell>
                        <TableCell
                        // sx={{
                        //     minWidth: "100%",
                        //     display: "flex",
                        // }}
                        >
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => updateRow(values, index)}
                          >
                            <TaskAltIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setData(null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    Data Not Found !!
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </Formik>
  );
}
