import { Axios } from "app/services/config";
import {
  ALL_RATINGS_FAILED,
  ALL_RATINGS_REQUEST,
  ALL_RATINGS_SUCCESS,
} from "./constants.js";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchAllFeedbackList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(ALL_RATINGS_FAILED));
    dispatch({ type: ALL_RATINGS_REQUEST });

    let apiUrl = `/feedback/feedbacks`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: ALL_RATINGS_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(ALL_RATINGS_FAILED, error?.response?.data?.message));
  }
};
