import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import ToastAlerts from "app/components/Toast";
import { Axios } from "app/services/config";
import { getCurrentDate } from "app/utils/constants/constants";
import { Form, Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

function AddLiveEvents() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const showAlert = ToastAlerts();
  const initialValues = {
    title: state?.title ? state?.title : "",
    link: state?.link ? state?.link : "",
    description: state?.description ? state?.description : "",
    event_date: state?.event_date
      ? new Date(state?.event_date).toISOString().split("T")[0]
      : getCurrentDate(),
    status: state?.status ?? true,
  };
  const validationSchema = yup.object({
    title: yup.string("Title is required").required("Title is required"),
    link: yup
      .string("Enter live event Link")
      .required("Live event link is required"),
    description: yup
      .string("Enter  Description")
      .required("Description is required"),
    event_date: yup
      .string("Select Event Date")
      .required("Event Date is required"),
  });

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);

    try {
      // const response = await Axios.post("/banner/add-banner", formData)
      let response;
      if (pathname === "/live-event/edit") {
        response = await Axios.post(`/live-event/edit/${state?._id}`, values);
      } else {
        response = await Axios.post("/live-event/add", values);
      }
      if (response.data?.success) {
        showAlert("success", response?.data?.message);
        setSubmitting(false);
        navigate("/live-event");
      }
    } catch (error) {
      showAlert("error", error?.response?.data?.message);
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {pathname === "/live-event/add" ? "Add Live Event" : "Edit Live Event"}
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  handleSubmit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="event_date"
                      name="event_date"
                      label="Event Date*"
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      variant="outlined"
                      value={values?.event_date}
                      onChange={(e) =>
                        setFieldValue("event_date", e.target.value)
                      }
                      error={touched.event_date && Boolean(errors.event_date)}
                      helperText={touched.event_date && errors.event_date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="link"
                      name="link"
                      label="Link*"
                      value={values?.link}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="title"
                      name="title"
                      label="Title*"
                      value={values?.title}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {/* <JumboTextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Description*"
                      value={values?.description}
                    /> */}

                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={
                        touched?.description && Boolean(errors?.description)
                      }
                    >
                      <TextareaAutosize
                        // minRows={3}
                        rows={3}
                        style={{
                          width: "100%",
                          padding: "16px",
                          borderRadius: "4px",
                          fontFamily: "inherit",
                          resize: "vertical",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          outline: "none",
                        }}
                        value={values?.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        placeholder="Enter description"
                      />

                      {errors?.description && errors?.description && (
                        <FormHelperText>{errors?.description}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {pathname === "/live-event/add" ? "Save" : "Update"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/live-event");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default AddLiveEvents;
