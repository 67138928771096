import { LOGS_LIST, LOGS_ERROR, GLOBAL_LOGS_LIST, LOGS_REQUEST } from "./logsConstant";
import { Axios } from "app/services/config";

const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const onLogsList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LOGS_ERROR));
    dispatch({ type: LOGS_REQUEST });

    let apiUrl = `/entry/logsList`;
    if (query) {

      const queryParams = new URLSearchParams(query);
      apiUrl = apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: LOGS_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(LOGS_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};

export const GlobalLogsList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LOGS_ERROR));

    let apiUrl = `/logs/logsList`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: GLOBAL_LOGS_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(LOGS_ERROR, error.message));
  } finally {
    // setLoading(false);
  }
};
