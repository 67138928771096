import { Axios } from "app/services/config";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Documents1 from "app/components/Documents1";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import ToastAlerts from "app/components/Toast";
function MAppedQuestionsTable() {
  const { id } = useParams();

  const showAlerts = ToastAlerts();
  const [questionByEvent, setQuestionByEvent] = useState([]);
  const [toggle, setToggle] = useState(false);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");

  useEffect(() => {
    const fetchQuestionsByEvent = async () => {
      try {
        const res = await Axios.get(`feedback/questions-by-event/${id}`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
          },
        });
        if (res.data.statusCode === 200) {
          setQuestionByEvent(res.data?.data?.allQuestions);
          setCount(res.data?.data?.totalCount[0]?.totalDocuments);
        } else {
          showAlerts("error", res.data.message);
        }
      } catch (error) {
        showAlerts("error", error.message);
      }
    };
    fetchQuestionsByEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, toggle, page]);

  const handleDeleteQuestions = async (id) => {
    try {
      const res = await Axios.post(`feedback/remove-question/${id}`);
      if (res.data.statusCode === 200) {
        setToggle(true);
        showAlerts("success", res.data.message);
      }
    } catch (error) {
      showAlerts("error", error.message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="flex flex-col items-center gap-8">
      <div className="self-start">
        <Documents1
          field="Event Name"
          data={questionByEvent[0]?.eventName?.room_no}
        />
      </div>
      <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell> Sr.No</TableCell>
                <TableCell> Question</TableCell>
                <TableCell> Types</TableCell>
                <TableCell> Opt1</TableCell>
                <TableCell> Opt2</TableCell>
                <TableCell> Opt3</TableCell>
                <TableCell> Opt4</TableCell>
                <TableCell> Remove</TableCell>
              </TableRow>
            </TableHead>

            {questionByEvent?.length > 0 ? (
              <TableBody>
                {questionByEvent?.map((row, index) => (
                  <TableRow key={row._id}>
                    {row.allQuestions ? (
                      <>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.allQuestions?.question}</TableCell>
                        <TableCell>{row.allQuestions?.typeOf}</TableCell>
                        {row?.allQuestions?.options.map((opt) => (
                          <TableCell key={opt._id}> {opt}</TableCell>
                        ))}
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleDeleteQuestions(row?.allQuestions?._id)
                            }
                          >
                            <DeleteOutlineOutlined className="text-lg font-semibold text-red-500 hover:scale-105 transition-all cursor-pointer" />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell colSpan={7} align="center">
                        No Data found..
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    className="text-center font-semibold "
                    variant="head"
                    colSpan={4}
                  >
                    No Data Found....
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {count && (
          <TablePagination
            rowsPerPageOptions={""}
            component={"div"}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
          />
        )}
      </Paper>
    </div>
  );
}

export default MAppedQuestionsTable;
