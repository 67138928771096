const AllApis = {
  dropdownList: {
    room_no: "/room/dropdown-room-no",
    room_list: "/room/dropdown-room-list", //with id
    session_name: "/session/list-session-name",
    item_name: "/giveaway/dropdown-item-name",
    all_questions: "/feedback/dropdown-questions",
  },
  session: {
    listsessionBasedonRoomId: function (id) {
      return `/session/list-session?id=${id}`;
    },
  },
};
export default AllApis;
