import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import List from "@mui/material/List";
import { ListItem, ListItemText, Typography } from "@mui/material";
import { getCustomDateTime } from "@jumbo/utils";
import { format } from "date-fns";

const ViewSurvey = ({ openView, setOpenView, data }) => {
  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ backgroundColor: "#7352C7", color: "white" }}
        id="alert-dialog-title"
      >
        Survey Details
      </DialogTitle>
      <DialogContent
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <List
          disablePadding
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: (theme) => theme.spacing(0, -2),
          }}
        >
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Survey Name
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.survey_name || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Event Name
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.event_name || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Room
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.roomDetails?.room_no || "--"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Session
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.sessionDetails?.session_name || "--"}
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Status
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {" "}
                  {data?.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Created Date
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {format(new Date(data?.createdAt), "dd/MM/yyyy") || "-"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            sx={{
              width: { xs: "100%", sm: "50%", xl: "33.33%" },
              textAlign: "center",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  All Questions
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  {data?.questionsDetails?.map((item, index) => (
                    <ul key={item?._id} className="flex flex-col ">
                      <li className="p-1">
                        {index + 1}
                        {""} {item?.question}
                      </li>
                    </ul>
                  ))}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setOpenView(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewSurvey;
