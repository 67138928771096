import { ALL_SURVEY_SUCCESS } from "app/redux/actions/Feedback/Survey/constants";

const initialState = {
  surveyList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPages: null,
};

const reducerFuction = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SURVEY_SUCCESS:
      return {
        ...state,
        surveyList: action.payload?.data?.allSurveys || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPage: action.payload?.data?.totalPage,
      };

    default:
      return state;
  }
};

export default reducerFuction;
