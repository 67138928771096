import {
  USER_LIST,
  USER_ERROR,
  USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
  LOAD_USER_REQUEST,
} from "./userConstant";
import { Axios } from "app/services/config";

const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const onUserList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(USER_ERROR));
    dispatch({ type: USER_REQUEST });

    let apiUrl = `user/list`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: USER_LIST, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(USER_ERROR, error?.response?.data?.message));
  } finally {
    // setLoading(false);
  }
};

// export const onUserAdd = (postData) => async (dispatch) => {
//   try {
//     // dispatch(setLoading(USER_ADDED));
//     dispatch(clearError(USER_ERROR));

//     // Implement logic for adding a new user if needed

//     const { data } = await Axios.post('/user/add', postData);
//     // dispatch({ type: USER_ADDED });
//     console.log(data);
//     dispatch(setSuccess(USER_ADDED, data.message));
//     return data.data;
//   } catch (error) {
//     dispatch(setError(USER_ERROR, error.message));
//     return error.response.data;
//   } finally {
//     // dispatch(clearLoading(USER_ADDED));
//   }
// };

// export const onUserEdit = (contact) => async (dispatch) => {
//   try {
//     dispatch(setLoading(USER_EDIT));
//     dispatch(clearError(USER_ERROR));

//     // Implement logic for editing a user if needed
//     dispatch({ type: USER_EDIT, payload: contact });
//     dispatch(setSuccess(USER_SUCCESS, "User edited successfully"));
//   } catch (error) {
//     dispatch(setError(USER_ERROR, "Error editing user"));
//   } finally {
//     dispatch(clearLoading(USER_EDIT));
//   }
// };

// export const onUserDelete = () => async (dispatch) => {
//   try {
//     dispatch(setLoading(USER_DELETE));
//     dispatch(clearError(USER_ERROR));

//     // Implement logic for deleting a user if needed
//     dispatch({ type: USER_DELETE });
//     dispatch(setSuccess(USER_SUCCESS, "User deleted successfully"));
//   } catch (error) {
//     dispatch(setError(USER_ERROR, "Error deleting user"));
//   } finally {
//     dispatch(clearLoading(USER_DELETE));
//   }
// };

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const data = await Axios.get("/auth/get-user");

    dispatch({ type: LOAD_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAILED,
      payload: error?.response?.data?.description,
    });
  }
};
