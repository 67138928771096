import { Axios } from "app/services/config";
import {
  LIST_OTHER_LINKS_FAILED,
  LIST_OTHER_LINKS_REQUEST,
  LIST_OTHER_LINKS_SUCCESS,
} from "./constants";
const setError = (type, error) => ({ type, payload: error });
const clearError = (type) => ({ type, payload: null });

export const fetchOtherLinksList = (query) => async (dispatch) => {
  try {
    dispatch(clearError(LIST_OTHER_LINKS_FAILED));
    dispatch({ type: LIST_OTHER_LINKS_REQUEST });
    let apiUrl = `/others/list`;
    if (query) {
      const queryParams = new URLSearchParams(query);
      apiUrl =
        apiUrl + (queryParams.toString() ? `?${queryParams.toString()}` : "");
    }
    const { data } = await Axios.get(apiUrl);

    dispatch({ type: LIST_OTHER_LINKS_SUCCESS, payload: data });
  } catch (error) {
    // setLoading(false);
    dispatch(setError(LIST_OTHER_LINKS_FAILED, error.message));
  } finally {
    // setLoading(false);
  }
};
