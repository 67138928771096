import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Button, Grid, IconButton } from '@mui/material'
import React from 'react'

function ActivityList({ list, handleRemoveActivity }) {

    return (
        <Grid xs={12}>
            {list?.length > 0 && list?.map((item, index) => (
                <ul className='mt-4 flex flex-col items-start ' key={index}>
                    <li className='flex flex-row items-center gap-2'>
                        <span>  {item}</span>
                        <IconButton onClick={() => handleRemoveActivity(index)} ><DeleteOutlineOutlined color='error' /></IconButton>
                    </li>
                </ul>
            ))}
        </Grid>

    )
}

export default ActivityList