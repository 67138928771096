import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { useSelector } from "react-redux";

import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";
import { isValidEmail } from "@jumbo/utils";

const EditUser = () => {
  const rolesList = ["Admin", "Mobile"];
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const showAlert = ToastAlerts();
  const { roomList } = useSelector((state) => state.roomReducer);
  const userId = JSON.parse(localStorage.getItem("authUser"));

  var initialValues = {
    user_id: state?.user_id,
    first_name: state?.first_name,
    last_name: state?.last_name,
    email_id: state?.email_id,
    role: state?.role,
    assigned_room: state?.assigned_room?._id ? state?.assigned_room?._id : null,
    mobile_no: state?.mobile_no,
    status: state?.status,
    role_name: "",
    role_id: state?.role_id || null,
  };
  const validationSchema = yup.object({
    user_id: yup
      .string("Enter User ID")
      .required("User ID is required")
      .matches(/^[0-9]+$/, "User ID must be a number"),
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z]+$/,
        "Last Name must contain only alphabetic characters"
      ),
    email_id: yup
      .string("Enter your Email ID")
      .required("Email is required")
      .test(
        "isValidEmail",
        "Email should contain valid characters, an '@' symbol",
        (value) => isValidEmail(value)
      ),
    mobile_no: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : Number(originalValue)
      )
      .test(
        "len",
        "Number should be 10 digits",
        (val) => !val || String(val).length === 10
      )
      .typeError("Mobile number must be a number"),
    // mobile_no: yup
    //   .string()
    //   .typeError("Mobile number must be a number")
    //   .required("Mobile Number is Required")
    //   .matches(/^\d{10}$/, "Number should be 10 digits."),
    role: yup.string().required("Please select role.").nullable(),
    // role_id: yup
    //   .string("Please Select user role")
    //   .required("User role is required .")
    //   .nullable(),
    role_id: yup.string().when("role", {
      is: "Admin",
      then: yup
        .string("Please Select user role")
        .nullable()
        .required("User role is required."),
      otherwise: yup.string().nullable(),
    }),
    assigned_room: yup.string().when("role", {
      is: "Mobile",
      then: yup.string().nullable().required("Please Assign one room."),
      otherwise: yup.string().nullable(),
    }),
  });

  const handleUserAdd = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      await Axios.patch(`/user/edit/${id}`, data);
      showAlert("success", "User added successfully.");
      navigate("/user");
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    (async function () {
      try {
        const res = await Axios.get("/roles/dropdown-roles");
        setRoles(res?.data?.data);
      } catch (error) {
        console.log("err fetching roles => ", error?.response?.data?.message);
      }
    })();
  }, []);

  useEffect(() => { }, []);
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        Edit User
      </Typography>
      {/* <Card>
        <CardContent> */}
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleUserAdd(data, setSubmitting);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="user_id"
                  name="user_id"
                  label="User ID *"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="first_name"
                  name="first_name"
                  label="First Name *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  id="email_id"
                  name="email_id"
                  label="Email *"
                />
              </Grid>
              <Grid item xs={6}>
                <JumboTextField
                  fullWidth
                  type="number"
                  id="mobile_no"
                  name="mobile_no"
                  label="Mobile No."
                />
              </Grid>
              <Grid item xs={values?.role === "Mobile" ? 3 : 6}>
                <FormControl fullWidth error={errors?.role && touched?.role}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    getOptionLabel={(option) => option}
                    options={rolesList}
                    defaultValue={values?.role}
                    name="role"
                    onChange={(event, val) => {
                      setFieldValue("role", val);
                      setFieldValue("assigned_room", null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors?.role && touched?.role}
                        {...params}
                        label="Roles *"
                      />
                    )}
                  />
                  {errors && errors?.role && touched?.role && (
                    <FormHelperText>{errors?.role}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {values?.role === "Mobile" && (
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    error={errors?.assigned_room && touched?.assigned_room}
                  >
                    <Autocomplete
                      fullWidth
                      size="small"
                      // disablePortal
                      getOptionLabel={(option) => option?.room_no}
                      options={roomList}
                      name="assigned_room"
                      // onChange={(event, val) => {
                      //   setFieldValue("assigned_room", val?._id);
                      // }}
                      value={roomList.find(
                        (ele) => ele?._id === values?.assigned_room
                      )}
                      onChange={(event, val) => {
                        // setFieldValue("role_id", JSON.stringify(val));
                        setFieldValue("assigned_room", val?._id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={
                            errors?.assigned_room && touched?.assigned_room
                          }
                          {...params}
                          label="Assign Room *"
                        />
                      )}
                    />
                    {errors &&
                      errors?.assigned_room &&
                      touched?.assigned_room && (
                        <FormHelperText>{errors?.assigned_room}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              {values?.role === "Admin" && (
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={errors?.role_id && touched?.role_id}
                  >
                    <Autocomplete
                      fullWidth
                      size="small"
                      disablePortal
                      getOptionLabel={(option) => option?.role_name || ""}
                      options={roles}
                      value={
                        roles?.find((role) => role?._id === values?.role_id) ||
                        null
                      }
                      name="role_id"
                      onChange={(event, val) => {
                        setFieldValue("role_name", val?.role_name);
                        setFieldValue("role_id", val?._id);

                      }}
                      renderInput={(params) => (
                        <TextField
                          error={errors?.role_id && touched?.role_id}
                          {...params}
                          label="User Role *"
                        />
                      )}
                    />
                    {errors && errors?.role_id && touched?.role_id && (
                      <FormHelperText>{errors?.role_id}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6} alignContent="center">
                <FormControlLabel
                  style={{ padding: "0px", margin: "0px", height: "100%" }}
                  control={
                    <Switch
                      disabled={state?._id === userId?._id}
                      onChange={(e) => {
                        setFieldValue("status", values?.status ? false : true);
                      }}
                      defaultChecked={values?.status ? true : false}
                      color="primary"
                    />
                  }
                  label="Status"
                  name="status"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt={5}>
              <Grid item xs={6} textAlign="right">
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/user");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {/* </CardContent>
      </Card> */}
    </React.Fragment>
  );
};

export default EditUser;
