import Page from "@jumbo/shared/Page";
import LogsList from "app/pages/Logs/LogsList";
import { LogsMiddleware } from "../Middleware/eventManagement/event.management.middleware";

const routesName = "/logs";

export const logsRoute = [
  {
    middleware: [{ element: LogsMiddleware, fallbackPath: "/" }],

    routes: [
      {
        path: `${routesName}`,
        element: <Page component={LogsList} layout={"vertical-default"} />,
      },
    ],
  },
];

// export const logsRoute = routes;
