import React from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  CardGiftcardOutlined,
  InfoOutlined,
  RateReview,
  RedeemOutlined,
  StackedLineChartOutlined,
  SupervisorAccountOutlined,
  Poll,
  Dashboard,
} from "@mui/icons-material";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
const menus = [
  {
    label: " ",
    type: "section",
    children: [
      {
        uri: "/",
        label: "Dashboard",
        isActiveUri: ["/"],
        type: "nav-item",
        icon: <Dashboard sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/user",
        label: "User Management",
        isActiveUri: [
          "/user/add",
          "/user/edit/:id",
          "/user/change-password/:id",
        ],
        type: "nav-item",
        permission: "user",
        icon: <PersonAddIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/roles",
        label: "Roles & Permissions",
        isActiveUri: ["/role/add", "/role/edit", "/role/change-password/:id"],
        type: "nav-item",
        permission: "role",
        icon: <LockPersonOutlinedIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/guest",
        label: "Guests",
        isActiveUri: ["/guest/add", "/guest/edit/:id"],
        type: "nav-item",
        permission: "guest",
        icon: <ContactsOutlinedIcon sx={{ fontSize: 25 }} />,
      },
      {
        uri: "/rooms",
        label: "Rooms",
        isActiveUri: ["/rooms/add", "/rooms/view/:id", "/rooms/view/:id/:id"],
        type: "nav-item",
        permission: "room",
        icon: <MeetingRoomIcon sx={{ fontSize: 25 }} />,
      },
      {
        label: "Event Management",
        type: "collapsible",
        icon: <CalendarTodayOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/banner",
            label: "Home Banner",
            isActiveUri: [
              "/banner/add",
              "/banner/edit",
              // "/banner/list",
            ],
            type: "nav-item",
            permission: "banner",
            // icon: <CategoryIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/sponsors",
            label: "Sponsors",
            isActiveUri: ["/sponsors/add", "/sponsors/edit", "/sponsors"],
            type: "nav-item",
            permission: "sponsors",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/partners",
            label: "Partners",
            isActiveUri: ["/partners/add", "/partners/edit", "/partners/list"],
            permission: "partners",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/speakers",
            label: "Speakers",
            isActiveUri: ["/speakers/add", "/speakers/edit", "/speakers/list"],
            permission: "speakers",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/roadshow",
            label: "Roadshow",

            isActiveUri: ["/roadshow/add", "/roadshow/edit", "/roadshow/list"],
            permission: "roadshow",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/floor-plan",
            label: "Floor Plan",
            isActiveUri: [
              "/floor-plan/add",
              "/floor-plan/edit",
              "/floor-plan/list",
            ],
            permission: "floor_plan",
            // permission: "item_category_master",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/agenda",
            label: "Agenda",
            permission: "agenda",
            isActiveUri: ["/agenda/add", "/agenda/edit", "/agenda/list"],
            // permission: "item_category_master",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/about-us",
            label: "About Us",
            permission: "about_us",
            isActiveUri: ["/about-us/add", "/about-us/edit", "/about-us/list"],
            // permission: "item_category_master",
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/live-event",
            permission: "live_event",
            label: "Live Event",
            isActiveUri: ["/live-event/add", "/live-event/edit", "/live-event"],
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/stall",
            permission: "stall",
            label: "Stall",
            isActiveUri: ["/stall/add", "/stall/edit", "/stall"],
            type: "nav-item",
            // icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },

      // {
      //   uri: "/plates",
      //   label: "Plates",
      //   isActiveUri: ["/plates/custom"],
      //   type: "nav-item",
      //   icon: <SupervisorAccountOutlined sx={{ fontSize: 25 }} />,
      // },
      // {
      //   uri: "/gifts",
      //   label: "Gifts",
      //   isActiveUri: ["/gift-event-mapping/:id"],
      //   type: "nav-item",
      //   icon: <CardGiftcardOutlined sx={{ fontSize: 25 }} />,
      // },
      // {
      //   uri: "/giveaway-tracker",
      //   label: "Give-Away Tracker",
      //   isActiveUri: ["/giveaway-tracker"],
      //   type: "nav-item",
      //   icon: <StackedLineChartOutlined sx={{ fontSize: 25 }} />,
      // },

      {
        label: "Giveaway",
        type: "collapsible",
        icon: <RedeemOutlined sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/giveaway",
            label: "Delegate Kit",
            permission: "delegate_kit",
            isActiveUri: ["/giveaway", "/giveaway/edit", "/giveaway/add"],
            type: "nav-item",
          },
          {
            uri: "/giveaway-tracker",
            label: "Giveaway Tracker",
            type: "nav-item",
            permission: "giveaway_tracker",
            isActiveUri: ["/giveaway-tracker"],
          },
        ],
      },

      {
        label: "Survey & Poll",
        type: "collapsible",
        icon: <Poll sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/questions",
            label: "Questions",
            permission: "question",
            isActiveUri: ["/questions", "/questions/edit", "/questions/add"],
            type: "nav-item",
          },
          {
            uri: "/survey",
            label: "Survey",
            permission: "survey",
            type: "nav-item",
            isActiveUri: ["/survey", "/survey/edit", "/survey/add"],
          },
          {
            uri: "/feedback",
            label: "Feedback",
            permission: "feedback",
            type: "nav-item",
            isActiveUri: ["/feedback", "/view-feedback"],
          },
        ],
      },
      {
        label: "Others",
        type: "collapsible",
        icon: <InfoOutlined sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/other-links",
            label: "Links",
            isActiveUri: [
              "/other-links",
              "/other-links/edit",
              "/other-links/add",
            ],
            permission: `links`,
            type: "nav-item",
          },
          // {
          //   uri: "/terms-conditions",
          //   label: "Terms & Conditions",
          //   type: "nav-item",
          //   isActiveUri: [
          //     "/terms-conditions",
          //     "/terms-conditions/edit",
          //     "/terms-conditions/add",
          //   ],
          // },
          // {
          //   uri: "/social-media",
          //   label: "Social Media",
          //   type: "nav-item",
          //   isActiveUri: [
          //     "/social-media",
          //     "/social-media/edit",
          //     "/social-media/add",
          //   ],
          // },
          {
            uri: "/faq",
            label: "FAQ",
            type: "nav-item",
            permission: `faq`,
            isActiveUri: ["/faq", "/faq/edit", "/faq/add"],
          },
        ],
      },
      {
        uri: "/logs",
        label: "Logs",
        isActiveUri: ["/logs"],
        type: "nav-item",
        permission: `logs`,
        icon: <TransferWithinAStationIcon sx={{ fontSize: 25 }} />,
      },
      // {
      //   uri: "/feedback",
      //   label: "Feedback",
      //   isActiveUri: ["/feedback", "/mapped-questions/:id", "/feedback-logs"],
      //   type: "nav-item",
      //   icon: <RateReview sx={{ fontSize: 25 }} />,
      // },
    ],
  },
];

export default menus;
