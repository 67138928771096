import { LIST_ABOUT_US_SUCCESS } from "app/redux/actions/AboutUs/constants";

const initialState = {
  aboutUsList: [],
  loading: false,
  error: null,
  successMessage: null,
  totalPage: null,
};

const reducerFuction = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ABOUT_US_SUCCESS:
      return {
        ...state,
        aboutUsList: action.payload?.data?.aboutUsList || [],
        loading: false,
        error: null,
        successMessage: action.payload?.message,
        totalPage: action.payload?.data?.totalPage,
      };

    default:
      return state;
  }
};

export default reducerFuction;
